import { Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaTimes,
} from "react-icons/fa";
import { ToastItem, useToasts } from "../../context/ToastProvider";

type ToastItemProps = { toast: ToastItem };

const Toast = ({ toast }: ToastItemProps) => {
  const { deleteToast } = useToasts();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      deleteToast(toast.id);
    }, 3500);

    return () => {
      clearTimeout(timer);
    };
  }, [toast, deleteToast]);

  return (
    <Transition
      appear={true}
      show={true}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="flex flex-1 p-4">
          <div className="flex flex-1 justify-center items-center">
            <div className="flex-shrink-0">
              {toast.type === "message" && (
                <FaCheckCircle
                  className="h-4 w-4 text-green-400"
                  aria-hidden="true"
                />
              )}
              {toast.type === "warning" && (
                <FaExclamationTriangle
                  className="h-4 w-4 text-yellow-500"
                  aria-hidden="true"
                />
              )}
              {toast.type === "error" && (
                <FaExclamationCircle
                  className="h-4 w-4 text-red-500"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">
                {toast.content}
              </p>
            </div>
            <div className="flex-shrink-0">
              <button
                className="text-gray-400 m-0 p-0 flex justify-center items-center"
                tabIndex={-1}
                aria-hidden
              >
                <FaTimes
                  className="h-4 w-4"
                  aria-hidden="true"
                  onClick={() => deleteToast(toast.id)}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export { Toast };
