import React from "react";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import { Switch } from "@headlessui/react";
import { FilterProps } from "react-table";
import { FaFilter } from "react-icons/fa";
import { mergeStyles } from "../../utilities/mergeStyles";

const AssessmentsTableFilterTci: React.FC<
  FilterProps<Record<string, unknown>>
> = ({ column }) => {
  const { isSorted, setFilter } = column;

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const clearColumnSort = React.useCallback(() => {
    if (isSorted) {
      setFilter(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSorted]);

  React.useEffect(() => {
    // We can't sort and filter by TCI date at the same time
    // so clear the filter when the column is sorted
    clearColumnSort();
  }, [clearColumnSort]);

  const onSwitchToggle = (value: boolean) => {
    // We can't sort and filter by TCI date at the same time
    // so clear the sort if filtered
    // This smells and is a leaky abstraction but Firebase imposes
    // this on us.
    column.clearSortBy();
    column.setFilter(value);
  };

  return (
    <Popover as="div" className="relative">
      <div>
        <Popover.Button
          ref={setReferenceElement}
          className={mergeStyles([
            "flex w-10 items-center justify-center pr-1 pt-2 pb-2 pl-1 outline-none focus-visible:ring-2 focus-visible:ring-primary-400 rounded-md mr-1 mt-1 mb-1 hover:text-gray-500",
            column.filterValue !== undefined
              ? "text-gray-500"
              : "text-gray-300",
          ])}
          aria-label={`Filter by ${column.Header?.toString()}`}
        >
          <FaFilter></FaFilter>
        </Popover.Button>
      </div>
      <Popover.Panel
        ref={setPopperElement}
        className="w-60 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 p-3"
        style={styles.popper}
        {...attributes.popper}
      >
        <Switch.Group as="div" className="flex items-center justify-between">
          <Switch.Label as="span">
            <span className="text-sm">No TCI date</span>
          </Switch.Label>
          <Switch
            checked={column.filterValue}
            onChange={onSwitchToggle}
            className={mergeStyles([
              column.filterValue ? "bg-primary-400" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400",
            ])}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={mergeStyles([
                column.filterValue ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
              ])}
            />
          </Switch>
        </Switch.Group>
      </Popover.Panel>
    </Popover>
  );
};

export { AssessmentsTableFilterTci };
