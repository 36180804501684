import { Button } from "../Button";

type AssessmentsTableFooterProps = {
  offset: number;
  limit: number;
  total: number;
  onNextPageClick: () => void;
  onPreviousPageClick: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
};

const AssessmentsTableFooter = ({
  offset,
  limit,
  total,
  onNextPageClick,
  onPreviousPageClick,
  canNextPage,
  canPreviousPage,
}: AssessmentsTableFooterProps): JSX.Element => {
  return (
    <>
      {total > 0 && (
        <nav
          className="bg-white px-4 py-2 flex items-center justify-between border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="text-sm text-gray-700">
            Showing <span className="font-medium">{offset + 1}</span> to{" "}
            <span className="font-medium">
              {offset + limit > total ? total : offset + limit}
            </span>{" "}
            of <span className="font-medium">{total}</span> assessments
          </div>
          <div className="flex-1 flex justify-between sm:justify-end space-x-3">
            <Button
              as="button"
              variant="outlined"
              size="sm"
              onClick={() => onPreviousPageClick()}
              flat
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
            <Button
              as="button"
              size="sm"
              variant="outlined"
              onClick={() => onNextPageClick()}
              flat
              disabled={!canNextPage}
            >
              Next
            </Button>
          </div>
        </nav>
      )}
    </>
  );
};

export { AssessmentsTableFooter };
