import { useQuery } from "react-query";
import { Role } from "../models/role.model";
import { useApi } from "./useApi";

const useUserRoles = ({ userId, orgId }: { userId: string; orgId: string }) => {
  const { fetchWithToken } = useApi();

  const getUserRoles = (id: string) => {
    if (!id) {
      return [];
    }

    return fetchWithToken<Role[]>(`/auth/roles/${orgId}/user/${id}`);
  };

  const userRolesQuery = useQuery(
    ["userRoles", userId],
    () => getUserRoles(userId),
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  return { userRolesQuery };
};

export { useUserRoles };
