import { Service } from "../../models/service.model";
import { UserDetailsDynamicCheckboxFormControl } from "./UserDetailsDynamicCheckboxFormControl";

type ServiceFormControlProps = {
  service: Service;
  disabled?: boolean;
};

const ServiceFormControl = ({ service, disabled }: ServiceFormControlProps) => {
  return (
    <UserDetailsDynamicCheckboxFormControl
      label={service.name}
      id={service.name}
      name={service.name}
      formValueName={"services"}
      dataId={service.id}
      disabled={disabled}
    ></UserDetailsDynamicCheckboxFormControl>
  );
};

export { ServiceFormControl };
