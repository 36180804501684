import { Item, Section } from "@react-stately/collections";
import React from "react";
import { FaTag } from "react-icons/fa";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { useTags } from "../../hooks/useTags";
import { Tag } from "../../models/tag.model";
import { statusMap } from "../../utilities/statusMap";
import { Chip } from "../Chip";
import { FilterBox } from "../FilterBox";
import { Pill } from "../Pill";

type AssignTagProps = {
  tag?: Tag | null;
  type?: "Pill" | "Chip";
  assessmentStatus: string;
  assessmentId: string;
  disabled?: boolean;
};

function AssignTag({
  tag,
  assessmentStatus,
  assessmentId,
  disabled = false,
  type = "Pill",
}: AssignTagProps): JSX.Element {
  const { updateTag } = useAssessmentMutations(assessmentId);
  const {
    tagsQuery: { data: tags, status },
  } = useTags();

  const handleChange = (value: string | number) => {
    const newTag = tags?.find((tag) => tag.tagId === value);
    if (newTag) {
      updateTag(newTag, "");
    }
  };

  const sections = React.useMemo(() => {
    const sections = [
      ...(tags
        ?.filter((tag) => tag.parentStatus)
        .reduce(
          (acc, curr) =>
            acc.add(curr.parentStatus?.replace(/"/g, "") ?? "none"),
          new Set<string>()
        ) ?? []),
    ]
      .sort((a, b) =>
        a?.toLocaleLowerCase() > b?.toLocaleLowerCase() ? 1 : -1
      )
      .map((section) => {
        const status = statusMap.find((status) => status.status === section);
        return {
          name: status?.text ?? section,
          color: `bg-${status?.color}-500` ?? "bg-gray-500",
          items: tags?.filter((tag) => tag.parentStatus === section),
        };
      });

    return sections;
  }, [tags]);

  const handleClear = () => {
    updateTag(null);
  };

  const mappedStatus = statusMap.find(
    (status) => status.status === assessmentStatus
  );

  const button =
    type === "Pill" ? (
      <Pill color={mappedStatus?.color ?? "gray"} disabled={disabled}>
        <FaTag className="mr-2" />
        {tag
          ? tag.text
          : mappedStatus?.status === "draft"
          ? mappedStatus?.text ?? assessmentStatus
          : disabled
          ? mappedStatus?.text ?? assessmentStatus
          : "Add tag"}
      </Pill>
    ) : (
      <Chip color={mappedStatus?.color ?? "gray"} disabled={disabled}>
        {tag
          ? tag.text
          : mappedStatus?.status === "draft"
          ? mappedStatus?.text ?? assessmentStatus
          : disabled
          ? mappedStatus?.text ?? assessmentStatus
          : "Add tag"}
      </Chip>
    );

  return (
    <FilterBox
      loading={status === "loading"}
      button={button}
      label={disabled ? "No tag" : "Assign tag"}
      items={sections}
      onChange={(value) => value && handleChange(value.toString())}
      selectedKey={tag?.tagId}
      disabled={disabled || mappedStatus?.status === "draft"}
      clearable
      onClear={() => handleClear()}
    >
      {(section) => (
        <Section
          key={section.name}
          items={section.items}
          title={
            <>
              {section.name}
              <span
                className={`ml-2 h-2 w-2 ${section.color} rounded-full`}
              ></span>
            </>
          }
        >
          {(item) => <Item key={item.tagId}>{item.text}</Item>}
        </Section>
      )}
    </FilterBox>
  );
}
export { AssignTag };
