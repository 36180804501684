import React from "react";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { AsaIcon } from "../AsaIcon";
import { Pill } from "../Pill";
import { Select } from "../Select";
import { SelectOption } from "../SelectOption";

type AsaGradeProps = {
  asaGrade: number | undefined;
  assessmentId: string;
  disabled?: boolean;
};

function AsaGrade({
  asaGrade,
  assessmentId,
  disabled = false,
}: AsaGradeProps): JSX.Element {
  const { updateAsa } = useAssessmentMutations(assessmentId);

  const priorityColor = React.useMemo(() => {
    switch (asaGrade) {
      case 1:
        return "green";
      case 2:
      case 3:
        return "yellow";
      case 4:
      case 5:
      case 6:
      default:
        return "red";
    }
  }, [asaGrade]);

  return (
    <Select
      value={asaGrade}
      label="ASA grade"
      showLabel={false}
      onChange={(value) => typeof value === "number" && updateAsa(value)}
      button={
        <Pill color={priorityColor} disabled={disabled}>
          <AsaIcon className="mr-2" />
          {asaGrade
            ? `ASA ${asaGrade}`
            : disabled
            ? "No ASA Grade"
            : "Add ASA Grade"}
        </Pill>
      }
    >
      <SelectOption value={1}>ASA 1</SelectOption>
      <SelectOption value={2}>ASA 2</SelectOption>
      <SelectOption value={3}>ASA 3</SelectOption>
      <SelectOption value={4}>ASA 4</SelectOption>
    </Select>
  );
}
export { AsaGrade };
