import * as Yup from "yup";
import { usePatients } from "./usePatients";
import { PatientInput } from "../models/patient.model";
import { validateIdentifier } from "../utilities/patient-identifier";
import { useTenant } from "../context/TenantContext";

const useBuildIdentifierTypesSchema = ({
  patient,
}: {
  patient?: PatientInput;
}) => {
  const { tenant } = useTenant();
  const { patientExists } = usePatients();

  const validateIdentifierDoesNotExist = async (
    idTypeId: string,
    value: string | undefined
  ) => {
    try {
      const exists = value
        ? await patientExists({ id: value, idTypeId })
        : false;
      return !exists;
    } catch {
      return false;
    }
  };

  const removeSpaces = (val: string) => val.split(" ").join("");

  const buildTenantIdentifierTypesSchema = () => {
    return Yup.array().of(
      Yup.object().shape({
        value: Yup.lazy((_, options) => {
          let schema = Yup.string().nullable();
          const { parent } = options;

          const idType = tenant.identifierTypes?.find(
            (idType) => idType.id === parent?.id
          );

          if (!idType) {
            return schema;
          }

          if (idType.required) {
            schema = schema.required(`${idType.name} is required`);
          }

          if (idType.type === "local" && idType.regex) {
            schema = schema.matches(
              new RegExp(idType.regex),
              `${idType.name} is invalid`
            );
          }

          if (idType.type === "national") {
            schema = schema.test(
              `valid${idType.shortName}`,
              `${idType.name} is invalid`,
              (value) =>
                value && value.length > 0
                  ? validateIdentifier(removeSpaces(value ?? ""), idType)
                  : true
            );
          }

          schema = schema.test(
            `existing${idType.shortName}`,
            `Patient with this ${idType.name} already exists`,
            async (value) => {
              return patient?.identifier[parent.id] === value
                ? true
                : await validateIdentifierDoesNotExist(
                    idType.id,
                    removeSpaces(value ?? "")
                  );
            }
          );

          return schema;
        }),
        id: Yup.string(),
      })
    );
  };

  return { buildTenantIdentifierTypesSchema };
};

export { useBuildIdentifierTypesSchema };
