/**
 * @copyright 2021 Ultramed Ltd
 * @author Nicolaas Prinsloo <nick@ultramed.co>
 */
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../config";
import { useQuery } from "../../hooks/useQuery";

function Login(): null {
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();
  const invitation = query.get("invitation") ?? undefined;
  const organization = query.get("organization");
  const organizationName = query.get("organization_name");
  const loginWithRedirectOptions = organization !== null &&
    organizationName !== null && {
      invitation,
      organization,
      organizationName,
      redirectUri: `https://${organizationName}.${config.domain}`,
    };
  if (loginWithRedirectOptions) {
    loginWithRedirect(loginWithRedirectOptions);
    return null;
  }
  loginWithRedirect();
  return null;
}

export default Login;
