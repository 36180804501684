import * as React from "react";

import { useAssignableRoles } from "../../hooks/useAssignableRoles";
import { useServices } from "../../hooks/useServices";
import { useUsers } from "../../hooks/useUsers";
import { makeUserInput } from "../../models/user.model";
import {
  UserDetailsForm,
  UserDetailsFormFields,
} from "../UserDetailsForm/UserDetailsForm";
import { Modal } from "../Modal";
import { Spinner } from "../Spinner";
import { useToken } from "../../context/TokenContext";

type AddUserModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const AddUserModal = ({ isOpen, setIsOpen }: AddUserModalProps) => {
  const { servicesQuery } = useServices();
  const { rolesQuery } = useAssignableRoles();
  const { data: servicesData, status: servicesStatus } = servicesQuery;
  const { data: rolesData, status: rolesStatus } = rolesQuery;
  const {
    createUserMutation: { status: mutationStatus, mutate },
  } = useUsers();
  const { decodedToken } = useToken();

  React.useEffect(() => {
    if (mutationStatus === "success") {
      setIsOpen(false);
    }
  }, [mutationStatus, setIsOpen]);

  const onSubmit = (formValues: UserDetailsFormFields) => {
    mutate({
      user: makeUserInput({
        prefix: formValues.prefix,
        email: formValues.email,
        givenName: formValues.givenName,
        familyName: formValues.familyName,
        services: formValues.services,
        isAssignable: formValues.isAssignable,
        isConsultant: formValues.isConsultant,
        roles: formValues.roles,
        // current org for now, but could be a system wide thing in the future
        orgId: decodedToken.org_id,
      }),
    });
  };

  return (
    <>
      {(servicesStatus === "loading" || rolesStatus === "loading") && (
        <Spinner></Spinner>
      )}
      {servicesStatus === "success" &&
        rolesStatus === "success" &&
        rolesData &&
        servicesData &&
        isOpen && (
          <Modal
            title="Add user"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="lg:w-6/12 flex min-h-0"
          >
            <UserDetailsForm
              services={servicesData}
              roles={rolesData}
              onSubmit={onSubmit}
              onCancel={() => setIsOpen(false)}
              actionButtonText="Add"
              isLoading={mutationStatus === "loading"}
            ></UserDetailsForm>
          </Modal>
        )}
    </>
  );
};

export { AddUserModal };
