import { Role } from "../../models/role.model";
import { UserDetailsDynamicCheckboxFormControl } from "./UserDetailsDynamicCheckboxFormControl";

type RoleFormControlProps = {
  role: Role;
  disabled?: boolean;
};

const RoleFormControl = ({ role, disabled }: RoleFormControlProps) => {
  return (
    <UserDetailsDynamicCheckboxFormControl
      label={role.name}
      id={role.name}
      name={role.name}
      formValueName={"roles"}
      dataId={role.id}
      disabled={disabled}
    ></UserDetailsDynamicCheckboxFormControl>
  );
};

export { RoleFormControl };
