import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import {
  FaArchive,
  FaChevronDown,
  FaEnvelope,
  FaLock,
  FaTrash,
} from "react-icons/fa";
import { AssessmentStatus } from "../../models/assessment.model";
import { Button } from "../Button";
import { AccessCodeDialog } from "../AccessCodeDialog";
import { MenuList } from "../MenuList";
import { MenuListItem } from "../MenuListItem";
import { AssessmentAmendDialog } from "../AssessmentAmendDialog";
import { ConfirmDeleteDialog } from "../ConfirmDeleteDialog";
import { ConfirmArchiveDialog } from "../ConfirmArchiveDialog";
import { PermissionGuard } from "../PermissionGuard";
import { AssessmentResendDialog } from "../AssessmentResendDialog";

type ActionsDropdownProps = {
  assessmentStatus: AssessmentStatus;
  archived: boolean;
  assessmentId: string;
  patientId: string;
  disabled: boolean;
};

export function ActionsDropdown({
  assessmentStatus,
  archived,
  assessmentId,
  patientId,
  disabled,
}: ActionsDropdownProps) {
  const [accessCodeDialogOpen, setAccessCodeDialogOpen] = React.useState(false);
  const [assessmentAmendOpen, setAssessmentAmendOpen] = React.useState(false);
  const [assessmentResendOpen, setAssessmentResendOpen] = React.useState(false);
  const [assessmentDeleteOpen, setAssessmentDeleteOpen] = React.useState(false);
  const [assessmentArchiveOpen, setAssessmentArchiveOpen] =
    React.useState(false);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={React.Fragment}>
          <Button as="button" endIcon={FaChevronDown} color="primary">
            Actions
          </Button>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
            <MenuList>
              {assessmentStatus === "draft" && (
                <Menu.Item>
                  {({ active }) => (
                    <MenuListItem
                      focused={active}
                      icon={FaLock}
                      disabled={disabled}
                      onClick={() => setAccessCodeDialogOpen(true)}
                    >
                      Create clinician code
                    </MenuListItem>
                  )}
                </Menu.Item>
              )}
              {assessmentStatus !== "draft" && (
                <Menu.Item>
                  {({ active }) => (
                    <MenuListItem
                      focused={active}
                      icon={FaEnvelope}
                      disabled={disabled}
                      onClick={() => setAssessmentAmendOpen(true)}
                    >
                      Send back to patient
                    </MenuListItem>
                  )}
                </Menu.Item>
              )}
              {assessmentStatus === "draft" && (
                <PermissionGuard requiredPermissions={["resend:assessment"]}>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuListItem
                        focused={active}
                        icon={FaEnvelope}
                        disabled={disabled}
                        onClick={() => setAssessmentResendOpen(true)}
                      >
                        Resend to patient
                      </MenuListItem>
                    )}
                  </Menu.Item>
                </PermissionGuard>
              )}
              {assessmentStatus === "completed" && (
                <PermissionGuard requiredPermissions={["archive:assessment"]}>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuListItem
                        focused={active}
                        icon={FaArchive}
                        disabled={disabled}
                        onClick={() => setAssessmentArchiveOpen(true)}
                      >
                        {archived ? "Unarchive" : "Archive"} assessment
                      </MenuListItem>
                    )}
                  </Menu.Item>
                </PermissionGuard>
              )}
              <PermissionGuard requiredPermissions={["delete:assessment"]}>
                <Menu.Item>
                  {({ active }) => (
                    <MenuListItem
                      focused={active}
                      icon={FaTrash}
                      disabled={disabled}
                      onClick={() => setAssessmentDeleteOpen(true)}
                      variant="secondary"
                    >
                      Move assessment to bin
                    </MenuListItem>
                  )}
                </Menu.Item>
              </PermissionGuard>
            </MenuList>
          </Menu.Items>
        </Transition>
      </Menu>
      <AccessCodeDialog
        assessmentId={assessmentId}
        open={accessCodeDialogOpen}
        onClose={() => setAccessCodeDialogOpen(false)}
      />
      <ConfirmDeleteDialog
        assessmentId={assessmentId}
        open={assessmentDeleteOpen}
        onClose={() => setAssessmentDeleteOpen(false)}
      />
      <ConfirmArchiveDialog
        assessmentId={assessmentId}
        open={assessmentArchiveOpen}
        onClose={() => setAssessmentArchiveOpen(false)}
        archived={archived}
      />
      <AssessmentAmendDialog
        patientId={patientId}
        assessmentId={assessmentId}
        open={assessmentAmendOpen}
        onClose={() => setAssessmentAmendOpen(false)}
      />
      <AssessmentResendDialog
        patientId={patientId}
        assessmentId={assessmentId}
        open={assessmentResendOpen}
        onClose={() => setAssessmentResendOpen(false)}
      />
    </>
  );
}
