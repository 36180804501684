import countries from "./countries.json";
import { Select } from "../Select";
import { SelectOption } from "../SelectOption";

type Country = {
  name: string;
  code: string;
};

interface CountrySelectProps {
  value: string | undefined;
  onChange: (value: string | number) => void;
}

const CountrySelect = ({
  value = "GB",
  onChange,
}: CountrySelectProps): JSX.Element => {
  const handleChange = (value: string | number) => {
    onChange(value);
  };

  return (
    <Select
      onChange={handleChange}
      value={value}
      label="Country"
      currentName={
        countries.find((country) => country.code === value)?.name ?? ""
      }
    >
      {countries.map((country) => (
        <SelectOption key={country.code} value={country.code}>
          {country.name}
        </SelectOption>
      ))}
    </Select>
  );
};

export { CountrySelect };
export type { Country };
