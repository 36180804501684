import React from "react";
import { IconType } from "react-icons";
import { FaCheck } from "react-icons/fa";
import { classNames } from "../../utilities/mergeStyles";

type ListItemProps = {
  focused?: boolean;
  selected?: boolean;
  disabled?: boolean;
  icon?: IconType;
  variant?: "primary" | "secondary";
} & Omit<React.LiHTMLAttributes<HTMLLIElement>, "disabled">;

const MenuListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      focused,
      selected,
      disabled,
      icon,
      className,
      variant = "primary",
      ...props
    },
    ref
  ) => {
    const Icon = icon;
    const textColor =
      variant === "secondary"
        ? "text-red-500"
        : variant === "primary"
        ? "text-primary-500"
        : "text-primary-500";

    const bgColor =
      variant === "secondary"
        ? "bg-red-500"
        : variant === "primary"
        ? "bg-primary-500"
        : "bg-primary-500";

    return (
      <li
        {...props}
        ref={ref}
        className={classNames(
          "py-2 px-2 text-sm outline-none cursor-default justify-between",
          focused && !disabled && `text-white ${bgColor}`,
          selected && !focused && textColor,
          selected && "font-semibold",
          disabled && "text-gray-400",
          props.onClick && !disabled && "cursor-pointer"
        )}
        onClick={!disabled ? props.onClick : undefined}
      >
        <div className="flex items-center">
          {Icon && (
            <Icon
              className={classNames(
                "h-3 w-3 mr-2",
                !focused && !disabled && textColor,
                className
              )}
            />
          )}
          <span className="flex-1">{props.children}</span>
          {selected && <FaCheck aria-hidden="true" className="w-3 h-3" />}
        </div>
      </li>
    );
  }
);

export { MenuListItem };
