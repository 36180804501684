import { FaMapSigns } from "react-icons/fa";

function NotFound(): JSX.Element {
  return (
    <div className="flex justify-center items-center flex-1 flex-col">
      <FaMapSigns className="h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        Error 404 - Page not found
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        This page can't be found. Please try again.
      </p>
    </div>
  );
}

export { NotFound };
