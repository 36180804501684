import { ComponentMap } from "../../models/form.model";
import { TextField } from "./TextField";
import { TextArea } from "./TextArea";
import { Radio } from "./Radio";
import { BmiCalculator } from "./BmiCalculator";
import { ReportSection } from "./ReportSection";
import { ReportTableDynamicRow } from "./ReportTableDynamicRow";
import { Chip } from "./Chip";
import { Span } from "./Span";
import { MedicationList } from "./MedicationList";
import { Checkbox } from "./Checkbox";
import { AllergyList } from "./AllergyList";
import { IcuList } from "./IcuList";
import { OperationsList } from "./OperationsList";
import { TextHeading } from "./TextHeading";
import { RecommendationsTable } from "./RecommendationsTable";
import { TextParagraph } from "./TextParagraph";

const componentMap: ComponentMap = {
  "text-field": TextField,
  "text-area": TextArea,
  "text-heading": TextHeading,
  "text-paragraph": TextParagraph,
  checkbox: Checkbox,
  radio: Radio,
  "bmi-calculator": BmiCalculator,
  "report-section": ReportSection,
  "report-table-dynamic-row": ReportTableDynamicRow,
  "allergy-list": AllergyList,
  span: Span,
  chip: Chip,
  "medication-list": MedicationList,
  "i-c-u-list": IcuList,
  "previous-operation-list": OperationsList,
  "characteristic-calculator": () => null, // We don't use this component so make it a NOOP.
  "recommendations-table": RecommendationsTable,
};

export { componentMap };
