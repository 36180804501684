// Setup React
import React from "react";
import ReactDOM from "react-dom";
// Third party libraries
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { OverlayProvider } from "react-aria";
import posthog from "posthog-js";
// Polyfills
// focus-visible is not supported by Safari so we need to polyfill it
import "focus-visible";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en"; // locale-data for en
import "@formatjs/intl-locale/polyfill";
// Local imports
import { config } from "./config";
import { Routes } from "./Routes";
import "./index.css";
import { AuthProvider } from "./context/AuthContext";
import { QueryProvider } from "./context/QueryContext";
import { ToastProvider } from "./context/ToastProvider";

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.environment,
});

posthog.init(config.posthog.id, {
  api_host: config.posthog.apiHost,
  mask_all_text: true,
  disable_cookie: true,
  capture_pageview: false,
  autocapture: false,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <OverlayProvider>
        <ToastProvider>
          <QueryProvider>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </QueryProvider>
        </ToastProvider>
      </OverlayProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
