import { useQuery } from "react-query";
import { FetchError, useApi } from "./useApi";
import { Response } from "../models/response.model";
import { Patient } from "../models/patient.model";
import { Assessment } from "../models/assessment.model";

const usePatient = (patientId: string) => {
  const { fetchWithToken } = useApi();

  const patientKey = ["patient", patientId];
  const patientAssessmentsKey = ["patient", "assessments", patientId];

  /**
   * Get a patient by ID
   */
  const getPatient = async (id: string, signal?: AbortSignal) => {
    const result = await fetchWithToken<Response<Patient>>(`/patients/${id}`, {
      signal,
    });
    return result.data;
  };

  const patientQuery = useQuery(
    patientKey,
    ({ signal }) => getPatient(patientId, signal),
    {
      staleTime: 1000 * 60, // Stale after 1 minute
      refetchOnWindowFocus: false,
      retry: (_, error) =>
        error instanceof FetchError && error.response.status >= 500,
    }
  );

  /**
   * Get assessments for a patient by ID
   */
  const getPatientAssessments = async (id: string, signal?: AbortSignal) => {
    const result = await fetchWithToken<Response<Assessment[]>>(
      `/patients/${id}/assessments`,
      { signal }
    );
    const orderedResults = result.data.sort((a, b) =>
      a.updated > b.updated ? -1 : 1
    );
    return orderedResults;
  };

  const patientAssessmentsQuery = useQuery(
    patientAssessmentsKey,
    ({ signal }) => getPatientAssessments(patientId, signal),
    {
      retry: (_, error) =>
        error instanceof FetchError && error.response.status >= 500,
    }
  );

  return { patientQuery, patientAssessmentsQuery };
};

export { usePatient };
