import type React from "react";
import { mergeStyles } from "../../utilities/mergeStyles";

type AssessmentsTableCellProps = {
  flex?: "column" | "row";
  justify?: "start" | "end" | "center";
  items?: "start" | "end" | "center";
  textSize?: "xs" | "sm" | "base";
} & React.HTMLAttributes<HTMLDivElement>;

const AssessmentsTableCell: React.FC<AssessmentsTableCellProps> = ({
  className,
  flex = "row",
  justify = "start",
  items = "center",
  textSize = "xs",
  ...props
}) => {
  return (
    <div
      className={mergeStyles([
        `w-full h-full inline-flex px-3 py-2 text-${textSize}`,
        `justify-${justify}`,
        `items-${items}`,
        flex === "column" ? "flex-col" : "flex-row",
        className,
      ])}
      {...props}
    />
  );
};

export { AssessmentsTableCell };
