import { Select } from "../Select";
import { SelectOption } from "../SelectOption";
import { useUsers } from "../../hooks/useUsers";
import { formatName } from "../../utilities/cuiFormat";
import { User } from "../../models/user.model";

interface ConsultantSelectProps {
  onChange: (value: string | number) => void;
  value?: string;
}

const ConsultantSelect = ({
  onChange,
  value,
}: ConsultantSelectProps): JSX.Element => {
  const {
    consultantsQuery: { status: consultantStatus, data: consultantData },
  } = useUsers();

  const consultantName = (consultant?: User) =>
    consultant
      ? formatName(
          consultant.givenName ?? "",
          consultant.familyName ?? "",
          consultant.prefix
        )
      : undefined;

  return (
    <Select
      onChange={onChange}
      value={value}
      label="Consultant"
      currentName={consultantName(
        consultantData?.find((consultant) => consultant.id === value)
      )}
      loading={consultantStatus === "loading"}
    >
      {consultantStatus === "success" &&
        consultantData?.map((consultant) => (
          <SelectOption key={consultant.id} value={consultant.id}>
            {consultantName(consultant) ?? "Unknown"}
          </SelectOption>
        ))}
    </Select>
  );
};

export { ConsultantSelect };
