import React from "react";

const AsaIcon: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      fill="currentColor"
      viewBox="0 0 500 500"
      height="1em"
      {...props}
    >
      <title>ASA icon</title>
      <path d="M500,125C500,56.011 443.989,0 375,0L125,0C56.011,0 0,56.011 0,125L0,375C0,443.989 56.011,500 125,500L375,500C443.989,500 500,443.989 500,375L500,125ZM139.988,453.16L173.518,352.969L326.284,352.969L360.012,453.16L438.577,453.16L295.334,46.84L204.468,46.84L61.423,453.16L139.988,453.16ZM193.357,293.846L248.314,130.168L251.488,130.168L306.444,293.846L193.357,293.846Z" />
    </svg>
  );
};

export { AsaIcon };
