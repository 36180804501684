import React from "react";

const Logo: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 307.64 52.63"
      fill="currentColor"
      {...props}
    >
      <title>Ultramed logo</title>
      <g>
        <path d="M0,32.9C0,46.08,8.42,52.63,21.1,52.63S42.19,46.08,42.19,32.9V0h-9V30c0,6.91-.43,14.91-12.09,14.91S9,36.86,9,30V0H0V32.9ZM50.9,51.41h8.21V0H50.9V51.41ZM63.57,20.3h6.2v22c.14,6.2,1.73,9.51,10.29,9.51,1.8,0,3.53-.29,5.33-.43V45a13.88,13.88,0,0,1-3.45.29c-3.68,0-4-1.73-4-5.11V20.3h7.42V14.18H78V3h-8.2V14.18h-6.2V20.3Zm27,31.11h8.21V33.7c0-8.21,4.47-12.75,10.08-12.75a30.16,30.16,0,0,1,3.53.36V13.39a20.06,20.06,0,0,0-3.1-.21c-5,0-9.93,4.24-10.87,8.2h-.14v-7.2H90.57V51.41Zm47.6-12.53c0,5.18-5.62,7.06-9.22,7.06-2.88,0-7.56-1.08-7.56-4.76,0-4.32,3.17-5.61,6.7-6.19s7.56-.57,10.08-2.23v6.12Zm8.2-14.9c0-7.85-7.63-10.8-14.9-10.8-8.21,0-16.34,2.8-16.92,12.38h8.21c.36-4,3.6-5.9,8.21-5.9,3.31,0,7.7.79,7.7,5,0,4.82-5.26,4.17-11.16,5.25-6.91.79-14.33,2.31-14.33,11.59,0,7.28,6,10.88,12.75,10.88,4.39,0,9.64-1.37,12.88-4.54.65,3.38,3,4.54,6.34,4.54a20.62,20.62,0,0,0,5.18-.94V45.79a10.84,10.84,0,0,1-2,.15c-1.51,0-1.95-.8-1.95-2.81V24Zm8.14,27.43h8.21V29.3c0-6.19,3.89-9.64,8.13-9.64,5,0,6.56,2.73,6.56,7.84V51.41h8.2V29.59c0-6.26,2.38-9.93,7.92-9.93,6.41,0,6.77,4.1,6.77,10V51.41h8.21V25.7c0-9.07-4.61-12.52-12.46-12.52-5.4,0-8.92,2.37-11.66,6.19-1.66-4.25-5.9-6.19-10.44-6.19-5.9,0-9,2.52-11.45,6.19h-.21V14.18h-7.78V51.41ZM250.2,34.92c1.36-11.16-5.83-21.74-17.57-21.74-11.09,0-18,9.07-18,19.65,0,11.45,6.55,19.59,18.21,19.59,8.14,0,15-4.54,16.85-12.68h-7.77c-1.44,4.11-4.32,6.2-9.08,6.2-6.84,0-10-5.19-10-11Zm-27.36-5.4c.21-5.54,4.1-9.86,9.79-9.86,5.54,0,9.07,4.68,9.36,9.86Zm39.31,3.67c0-6.69,2.73-13.53,10.44-13.53,6.33,0,10.29,4.89,10.29,13.1,0,6.48-3.09,13.18-10.43,13.18-7.13,0-10.3-6.34-10.3-12.75ZM290.8,0h-8.2V19h-.15c-2.52-4.11-7.7-5.83-12.38-5.83-8.14,0-16.13,5.9-16.13,19.36,0,11.16,5.69,19.88,17.43,19.88,4.67,0,9.35-1.8,11.51-6.05H283v5h7.77V0Z" />
        <path d="M301,7h1l1.61,2.66h1.15L303,6.91a1.59,1.59,0,0,0,1.62-1.69c0-1.26-.76-1.79-2.26-1.79H300V9.64h1V7Zm0-.86V4.3h1.23c.62,0,1.35.09,1.35.84,0,.9-.67,1-1.44,1Zm6.6.31a5.51,5.51,0,1,0-11,0,5.51,5.51,0,1,0,11,0Zm-1.19,0a4.32,4.32,0,1,1-8.62,0,4.32,4.32,0,1,1,8.62,0Z" />
      </g>
    </svg>
  );
};

export { Logo };
