type TableHeaderCellProps = {
  children?: React.ReactNode;
};

const TableHeaderCell = ({ children }: TableHeaderCellProps) => (
  <th
    scope="col"
    className="w-1/4 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  >
    {children}
  </th>
);

export { TableHeaderCell };
