import { Item } from "@react-stately/collections";
import { FaUser } from "react-icons/fa";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { useUsers } from "../../hooks/useUsers";
import { User } from "../../models/user.model";
import { formatName } from "../../utilities/cuiFormat";
import { AssessmentHeaderDropdown } from "../AssessmentHeaderDropdown";
import { FilterBox } from "../FilterBox";

type AssignUserProps = {
  assessmentId: string;
  user?: User | null;
  disabled?: boolean;
};

function AssignUser({ assessmentId, user, disabled }: AssignUserProps) {
  const {
    assignableUsersQuery: { data, status },
  } = useUsers();

  const { updateAssignedUser } = useAssessmentMutations(assessmentId);

  const handleChange = (value: string) => {
    const newUser = data?.find((user) => user.id === value);
    if (newUser) {
      updateAssignedUser(newUser);
    }
  };

  const handleClear = () => {
    updateAssignedUser(null);
  };

  const formatUserName = (user: User) =>
    formatName(user.givenName ?? "", user.familyName ?? "", user.prefix);

  return (
    <FilterBox
      loading={status === "loading"}
      button={
        <AssessmentHeaderDropdown icon={<FaUser />}>
          {user
            ? formatUserName(user)
            : disabled
            ? "No user assigned"
            : "Assign user"}
        </AssessmentHeaderDropdown>
      }
      label="Assigned user"
      items={data ?? []}
      onChange={(value) => value && handleChange(value.toString())}
      selectedKey={user?.id}
      disabled={disabled}
      clearable
      onClear={() => handleClear()}
    >
      {(item) => <Item>{formatUserName(item)}</Item>}
    </FilterBox>
  );
}

export { AssignUser };
