import React from "react";
import { useMutation, useQuery } from "react-query";
import { Assessment, AssessmentInput } from "../models/assessment.model";
import { Filter, Pagination, Sort } from "../models/apiquery.model";
import { Response } from "../models/response.model";
import { useApi } from "./useApi";
import { filterQuery } from "../utilities/querystring";
import { StatusMap, statusMap } from "../utilities/statusMap";

/**
 * Object with methods that allows you to query and mutate assessments using
 * react-query.
 * Wrapped in Context to allow us to access the same queries in nested child components
 */
const useAssessmentsQuery = () => {
  const { fetchWithToken } = useApi();
  const [pagination, setPagination] = React.useState<Pagination>({
    pageIndex: 0,
    limit: 10,
  });

  const [sort, setSort] = React.useState<Sort<Assessment> | undefined>();

  const [filters, setFilters] = React.useState<
    Filter<Assessment> | undefined
  >();

  const [selectedStatus, setSelectedStatus] = React.useState<
    StatusMap | undefined
  >();

  React.useEffect(() => {
    const statusFilter = filters?.find(
      (filter) => filter.filterBy === "status"
    );
    if (statusFilter) {
      const selectedStatus = statusMap.find(
        (option) => option.status === statusFilter?.value
      );
      setSelectedStatus(selectedStatus);
    }
  }, [filters]);

  const getAssessments = async ({
    offset,
    limit,
  }: {
    offset: number;
    limit: number;
    sort?: Sort<Assessment>;
    filters?: Filter<Assessment>;
    signal?: AbortSignal;
  }) => {
    const filterQueryObj = filters
      ? filterQuery<Assessment>(filters)
      : undefined;

    return fetchWithToken<Response<Assessment[]>>("/assessments", {
      query: {
        offset,
        limit,
        ...(sort ? { [`sort[${sort.sortDirection}]`]: sort.sortBy } : {}),
        ...(filterQueryObj ?? undefined),
      },
    });
  };

  const assessmentsQuery = useQuery<Response<Assessment[]>, Error>(
    ["assessments", pagination, sort, filters],
    ({ signal }) =>
      getAssessments({
        offset: pagination.limit * pagination.pageIndex,
        limit: pagination.limit,
        sort,
        filters,
        signal,
      }),
    {
      staleTime: 1000 * 60 * 2, // Stale after 2 minutes
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      placeholderData: { data: [] },
    }
  );

  /**
   * API call to create an assessment
   */
  const createAssessment = async ({
    assessment,
  }: {
    assessment: AssessmentInput;
  }) => {
    return fetchWithToken<Response<Assessment>>("/assessments", {
      method: "POST",
      body: assessment,
    });
  };

  /**
   * react-query mutation for creating an assessment
   */
  const createAssessmentMutation = useMutation(createAssessment);

  return {
    assessmentsQuery,
    pagination,
    setPagination,
    setSort,
    filters,
    setFilters,
    selectedStatus,
    setSelectedStatus,
    createAssessmentMutation,
  };
};

const AssessmentsContext = React.createContext<ReturnType<
  typeof useAssessmentsQuery
> | null>(null);

const AssessmentsProvider: React.FC = ({ children }) => {
  const assessmentsQuery = useAssessmentsQuery();
  return (
    <AssessmentsContext.Provider value={assessmentsQuery}>
      {children}
    </AssessmentsContext.Provider>
  );
};

const useAssessments = () => {
  const context = React.useContext(AssessmentsContext);
  if (!context)
    throw new Error(
      "useAssessments must be used within an AssessmentsProvider"
    );
  return context;
};

export { useAssessments, AssessmentsProvider };
