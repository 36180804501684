import { Select } from "../Select";
import { SelectOption } from "../SelectOption";
import { useServices } from "../../hooks/useServices";
import { InputError } from "../InputError";
import { FieldError } from "react-hook-form";

interface ServiceSelectProps {
  onChange: (value: string | number) => void;
  value: string;
  error?: FieldError;
  id: string;
}

const ServiceSelect = ({
  onChange,
  value,
  error,
  id,
}: ServiceSelectProps): JSX.Element => {
  const {
    servicesQuery: { status: serviceStatus, data: serviceData },
  } = useServices();

  return (
    <>
      <Select
        onChange={(value) => onChange(value)}
        value={value}
        label="Service"
        currentName={serviceData?.find((service) => service.id === value)?.name}
        loading={serviceStatus === "loading"}
      >
        {serviceStatus === "success" &&
          serviceData?.map((service) => (
            <SelectOption key={service.id} value={service.id}>
              {service.name}
            </SelectOption>
          ))}
      </Select>
      <InputError
        error={error ? error.message : undefined}
        id={`${id}-error`}
      ></InputError>
    </>
  );
};

export { ServiceSelect };
