import { useQuery } from "react-query";
import { Document } from "../models/document.model";
import { Response } from "../models/response.model";
import { useApi } from "./useApi";

const useDocuments = (assessmentId: string) => {
  const { fetchWithToken } = useApi();

  const getDocumentsForAssessment = async (
    id: string,
    signal?: AbortSignal
  ) => {
    const response = await fetchWithToken<Response<Document[]>>(
      `/assessments/${id}/documents`,
      {
        signal,
      }
    );
    return response.data;
  };

  const documentsQuery = useQuery(
    ["documents", assessmentId],
    ({ signal }) => getDocumentsForAssessment(assessmentId, signal),
    {
      staleTime: 1000 * 60 * 10, // Documents aren't expected to change that often so stale after 10 mins
    }
  );

  return { documentsQuery };
};

export { useDocuments };
