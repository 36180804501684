import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Column, Row } from "react-table";

import { AssessmentsTable } from "../../components/AssessmentsTable";
import { AssessmentsTableCell } from "../../components/AssessmentsTableCell";
import { AssessmentsTableFilterConsultant } from "../../components/AssessmentsTableFilterConsultant";
import { AssessmentsTableFilterPriority } from "../../components/AssessmentsTableFilterPriority";
import { AssessmentsTableFilterService } from "../../components/AssessmentsTableFilterService";
import { AssessmentsTableFilterTag } from "../../components/AssessmentsTableFilterTags";
import { AssessmentsTableFilterTci } from "../../components/AssessmentsTableFilterTci";
import { AssessmentsTableFilterUser } from "../../components/AssessmentsTableFilterUser";
import { AssignTag } from "../../components/AssignTag";
import { Chip } from "../../components/Chip";
import { Heading } from "../../components/Heading";
import { PageBody } from "../../components/PageBody";
import { PageContainer } from "../../components/PageContainer";
import { PageHeader } from "../../components/PageHeader";
import { PatientIdentifier } from "../../components/PatientIdentifier";
import { useAssessments } from "../../hooks/useAssessments";
import { usePermissions } from "../../hooks/usePermissions";
import { Filter, Sort } from "../../models/apiquery.model";
import { Assessment } from "../../models/assessment.model";
import { Patient } from "../../models/patient.model";
import { User } from "../../models/user.model";
import { formatName, formatShortDate } from "../../utilities/cuiFormat";

function Assessments(): JSX.Element {
  const { hasPermissions } = usePermissions();

  const {
    assessmentsQuery: { status, error, data, isFetching, isPreviousData },
    setPagination,
    setSort,
    setFilters,
  } = useAssessments();

  // Wrapped in useCallback to prevent infinite render loop of the
  // table component
  const updateTable = React.useCallback(
    ({ pageIndex, limit }: { pageIndex: number; limit: number }) => {
      setPagination({ pageIndex, limit });
    },
    [setPagination]
  );

  // Wrapped in useCallback to prevent infinite render loop of the
  // table component
  const resetPagination = React.useCallback(
    (pagination: { pageIndex: number; limit: number }) => {
      setPagination({ ...pagination, pageIndex: 0 });
    },
    [setPagination]
  );

  const sort = React.useCallback(
    (sort?: Sort<Assessment>) => {
      setSort(sort);
    },
    [setSort]
  );

  const filter = React.useCallback(
    (filter?: Filter<Assessment>) => {
      setFilters(filter);
    },
    [setFilters]
  );

  const tableColumns: Column[] = React.useMemo(
    () => [
      {
        accessor: "patient",
        disableSortBy: true,
        Header: "Patient",
        disableFilters: true,
        Cell: ({ value, row }: { value: Patient; row: Row<Assessment> }) => (
          <AssessmentsTableCell flex="column" justify="center" items="start">
            <Link to={`/assessments/${row.original.id}`}>
              {value && (
                <>
                  <div className="font-medium text-sm text-gray-900">
                    {value &&
                      formatName(
                        value.givenName ?? "",
                        value.familyName ?? "",
                        value.prefix
                      )}
                  </div>
                  {Object.entries(value.identifier).map(([key, value]) => {
                    return (
                      <div
                        className="tabular-nums text-gray-500 pr-2"
                        key={key}
                      >
                        <PatientIdentifier
                          key={key}
                          identifierValue={value}
                          identifierId={key}
                        />
                      </div>
                    );
                  })}
                </>
              )}
              {!value && (
                <>
                  <div className="text-sm font-medium text-gray-900">
                    Unknown
                  </div>
                  <div className="tabular-nums text-gray-500">Unknown</div>
                </>
              )}
            </Link>
          </AssessmentsTableCell>
        ),
      },
      {
        accessor: "id",
        Header: "Id",
        Filter: Fragment,
        Cell: Fragment,
      },
      {
        accessor: "status",
        Header: "Status",
        Filter: Fragment,
        Cell: Fragment,
      },
      {
        accessor: "created",
        Header: "Created",
        width: 120,
        disableFilters: true,
        Cell: ({ value }: { value: string }) => (
          <AssessmentsTableCell>
            {formatShortDate({ date: value })}
          </AssessmentsTableCell>
        ),
      },
      {
        accessor: "updated",
        Header: "Updated",
        width: 120,
        disableFilters: true,
        Cell: ({ value }: { value: string }) => (
          <AssessmentsTableCell>
            {formatShortDate({ date: value })}
          </AssessmentsTableCell>
        ),
      },
      {
        accessor: "tciDate",
        Header: "TCI",
        width: 110,
        Filter: AssessmentsTableFilterTci,
        Cell: ({ value }: { value?: string }) => (
          <AssessmentsTableCell>
            {value ? formatShortDate({ date: value }) : "No TCI date"}
          </AssessmentsTableCell>
        ),
      },
      {
        accessor: "service.name",
        id: "service",
        disableSortBy: true,
        Header: "Service",
        Filter: AssessmentsTableFilterService,
        Cell: ({ value }: { value: string }) => (
          <AssessmentsTableCell>{value}</AssessmentsTableCell>
        ),
      },
      {
        accessor: "consultant",
        disableSortBy: true,
        Header: "Consultant",
        width: 140,
        Filter: AssessmentsTableFilterConsultant,
        Cell: ({ value }: { value?: User }) => (
          <AssessmentsTableCell>
            {value &&
              formatName(
                value.givenName ?? "",
                value.familyName ?? "",
                value.prefix
              )}
          </AssessmentsTableCell>
        ),
      },
      {
        accessor: "assignedUser",
        disableSortBy: true,
        Header: "Assigned",
        width: 140,
        Filter: AssessmentsTableFilterUser,
        Cell: ({ value }: { value?: User }) => (
          <AssessmentsTableCell>
            {value &&
              formatName(
                value.givenName ?? "",
                value.familyName ?? "",
                value.prefix
              )}
          </AssessmentsTableCell>
        ),
      },
      {
        accessor: "priority",
        Header: "Priority",
        disableSortBy: true,
        width: 100,
        Filter: AssessmentsTableFilterPriority,
        Cell: ({ value }: { value: string }) => (
          <AssessmentsTableCell items="center" justify="center">
            {value && <Chip color="green">{value}</Chip>}
          </AssessmentsTableCell>
        ),
      },
      {
        accessor: "tag",
        disableSortBy: true,
        Header: "Tag",
        minWidth: 182,
        Filter: AssessmentsTableFilterTag,
        Cell: (props) => (
          <AssessmentsTableCell>
            <AssignTag
              assessmentId={props.row.values.id}
              tag={props.row.values.tag}
              assessmentStatus={props.row.values.status}
              type="Chip"
              disabled={!hasPermissions(["update:tag"])}
            />
          </AssessmentsTableCell>
        ),
      },
    ],
    [hasPermissions]
  );

  const memoizedData = React.useMemo(() => data?.data, [data]);

  return (
    <PageContainer>
      <PageHeader>
        <Heading>Assessments</Heading>
      </PageHeader>
      <PageBody>
        {memoizedData && (
          <AssessmentsTable
            data={memoizedData}
            columns={tableColumns}
            pageCount={
              data?.pagination
                ? Math.ceil(data.pagination.total / data.pagination.limit)
                : 0
            }
            total={data?.pagination?.total ?? 0}
            updateTable={updateTable}
            resetPagination={resetPagination}
            sort={sort}
            filter={filter}
            loading={(isFetching && isPreviousData) || status === "loading"}
          ></AssessmentsTable>
        )}
        {status === "error" && error?.message}
      </PageBody>
    </PageContainer>
  );
}

export { Assessments };
