import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { classNames } from "../../utilities/mergeStyles";

const AssessmentHeaderDropdown = React.forwardRef<
  HTMLButtonElement,
  {
    children?: React.ReactNode;
    icon?: React.ReactNode;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, icon, ...props }, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      className={classNames(
        "flex items-stretch justify-between rounded-md overflow-hidden text-sm text-gray-600 border border-1 border-transparent px-2 py-1 space-x-2 group cursor-default focus:outline-none focus:ring-2 ring-primary-500 ring-offset-2",
        !props.disabled &&
          "hover:border-gray-300 cursor-pointer focus:border-gray-300"
      )}
    >
      {icon && <div className="flex items-center">{icon}</div>}
      <div className="flex items-center">{children}</div>
      <div className="flex items-center">
        {!props.disabled && (
          <FaChevronDown
            className={classNames(
              "w-3 h-3 text-transparent",
              !props.disabled &&
                "group-hover:text-gray-600 group-focus:text-gray-600"
            )}
            aria-hidden="true"
          />
        )}
      </div>
    </button>
  );
});

export { AssessmentHeaderDropdown };
