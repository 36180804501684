import { EnableBehavior, EnableWhen, Operator } from "../models/form.model";
import {
  Characteristics,
  CharacteristicValue,
} from "../models/submission.model";

/**
 * Translates string comparison operators to
 * Javascript comparitors and does the comparison
 */
function logicTest(
  operator: Operator,
  value: CharacteristicValue,
  expectedValue: string | number | boolean
): boolean {
  if (
    typeof value === "number" ||
    typeof value === "string" ||
    typeof value === "boolean"
  ) {
    switch (operator) {
      case "=":
        return value === expectedValue;
      case "!=":
        return value !== expectedValue;
      case ">":
        return (
          value !== undefined &&
          expectedValue !== undefined &&
          value > expectedValue
        );
      case "<":
        return (
          value !== undefined &&
          expectedValue !== undefined &&
          value < expectedValue
        );
      case ">=":
        return (
          value !== undefined &&
          expectedValue !== undefined &&
          value >= expectedValue
        );
      case "<=":
        return (
          value !== undefined &&
          expectedValue !== undefined &&
          value <= expectedValue
        );
      case "exists":
        return value !== null && value !== undefined;
      default:
        return false;
    }
  }
  return false;
}

/**
 * Checks to see if a given characteristic is enabled
 * Used in the form renderer to display characteristic values
 * depending on some other characteristics.
 * Defaults to true if there are no enable rules.
 */
function isEnabled(
  characteristics: Characteristics,
  enableWhen: EnableWhen[] | undefined,
  enableBehavior: EnableBehavior | undefined
) {
  return enableWhen && enableWhen.length > 0
    ? enableWhen.reduce((acc: boolean | undefined, curr: EnableWhen) => {
        const test = logicTest(
          curr.operator,
          curr.type === "characteristic" && curr.characteristicId
            ? characteristics[curr.characteristicId]?.value
            : "",
          curr.type === "characteristic" && curr.answer ? curr.answer : ""
        );
        if (enableBehavior === "all")
          return acc !== undefined ? acc && test : test;
        if (enableBehavior === "any")
          return acc !== undefined ? acc || test : test;
        return acc;
      }, undefined) ?? true
    : true;
}

export { logicTest, isEnabled };
