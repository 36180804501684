import React from "react";
import { classNames } from "../../utilities/mergeStyles";
import { Spinner } from "../Spinner";

type CommonButtonProps = {
  color?: "primary" | "secondary" | "default";
  size?: "sm" | "md" | "lg";
  variant?: "contained" | "outlined" | "text";
  flat?: boolean;
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ElementType;
  endIcon?: React.ElementType;
  spinnerColor?: "white" | "red" | "gray";
};

interface AnchorElementProps
  extends CommonButtonProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "color"> {
  as: "a";
}

interface ButtonElementProps
  extends CommonButtonProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  as: "button";
}

type ButtonProps = AnchorElementProps | ButtonElementProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = "default",
      size = "md",
      variant = "contained",
      disabled = false,
      loading = false,
      fullWidth = false,
      className,
      startIcon,
      endIcon,
      flat,
      children,
      spinnerColor,
      ...props
    },
    ref
  ) => {
    const StartIcon = startIcon;
    const EndIcon = endIcon;

    const styles = {
      primary: {
        contained:
          "border-transparent text-white bg-primary-400 hover:bg-primary-500 focus:ring-primary-400",
        outlined:
          "border-primary-400 text-primary-400 hover:bg-primary-100 focus:ring-primary-400",
        text: "border-transparent text-primary-400 hover:bg-primary-100 focus:ring-primary-400",
      },
      secondary: {
        contained:
          "border-transparent text-white bg-red-500 hover:bg-red-600 focus:ring-primary-400",
        outlined:
          "border-red-500 text-red-500 hover:bg-red-100 focus:ring-primary-400 ",
        text: "border-transparent text-red-500 hover:bg-red-100 focus:ring-primary-400",
      },
      default: {
        contained:
          "border-transparent text-gray-900 bg-gray-300 hover:bg-gray-400 focus:ring-primary-400",
        outlined:
          "border-gray-300 text-gray-700 hover:bg-gray-200 focus:ring-primary-400 ",
        text: "border-transparent text-gray-900 hover:bg-gray-200 focus:ring-primary-400",
      },
      disabled: {
        contained:
          "border-transparent text-gray-400 bg-gray-300 focus:ring-primary-400",
        outlined: "border-gray-300 text-gray-400 focus:ring-primary-400 ",
        text: "border-transparent text-gray-400 focus:ring-primary-400",
      },
    };

    const sizeStyles = {
      sm: "px-2.5 py-1.5 text-xs font-medium rounded-md",
      md: "px-3 py-2 text-sm font-medium rounded-md",
      lg: "px-4 py-2 text-base font-medium rounded-md",
    };

    const spinnerColors: {
      [key: string]: "white" | "red" | "gray";
    } = {
      primary: "white",
      secondary: "red",
      default: "gray",
    };

    const common = (
      <>
        {loading && (
          <Spinner
            size="md"
            color={spinnerColor ?? spinnerColors[color]}
            className="px-5 py-0.5"
          />
        )}
        {!loading && (
          <>
            {StartIcon && <StartIcon className="mr-2" />}
            {children}
            {EndIcon && <EndIcon className="ml-2" />}
          </>
        )}
      </>
    );

    const classes = classNames(
      "inline-flex items-center justify-center border focus:outline-none focus:ring-2 focus:ring-offset-2",
      styles[disabled ? "disabled" : color][variant],
      sizeStyles[size],
      fullWidth && "flex-1",
      !flat && "shadow-sm",
      className
    );

    switch (props.as) {
      case "a": {
        return (
          <a className={classes} {...props} ref={ref as never}>
            {common}
          </a>
        );
      }
      case "button": {
        return (
          <button
            className={classes}
            disabled={disabled || loading}
            {...props}
            ref={ref}
          >
            {common}
          </button>
        );
      }
      default:
        return null;
    }
  }
);

export { Button };
