import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { useModal, useOverlay, usePreventScroll } from "@react-aria/overlays";
import React from "react";
import { classNames } from "../../utilities/mergeStyles";

type ModalProps = {
  fixedHeight?: boolean;
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  isDismissable?: boolean;
  onClose?: () => void;
  role?: "dialog" | "alertdialog";
} & React.HTMLAttributes<HTMLDivElement>;

const Modal = (props: ModalProps): JSX.Element => {
  const { fixedHeight, title, isDismissable, children } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = React.useRef(null);
  const { overlayProps, underlayProps } = useOverlay(
    { ...props, isKeyboardDismissDisabled: !isDismissable },
    ref
  );

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      className="inset-0 fixed z-10 bg-black bg-opacity-50 flex justify-center items-center min-h-0"
      {...underlayProps}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          ref={ref}
          className={classNames(
            "bg-white p-6 xl:rounded-lg w-screen h-screen xl:w-1/2 max-h-screen min-h-0 flex flex-col",
            fixedHeight || "xl:h-auto"
          )}
        >
          <h3
            {...titleProps}
            className="text-lg leading-6 font-medium text-gray-900 flex-shrink-0"
          >
            {title}
          </h3>
          {children}
        </div>
      </FocusScope>
    </div>
  );
};

export { Modal };
