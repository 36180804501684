/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Dialog as ReactDialog, Transition } from "@headlessui/react";
import { FaExclamationCircle } from "react-icons/fa";
import { Button } from "../Button";
import { Spinner } from "../Spinner";
import { useToasts } from "../../context/ToastProvider";
import { formatLongDate } from "../../utilities/cuiFormat";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";

type AccessCodeDialogProps = {
  assessmentId: string;
  open?: boolean;
  onClose?: () => void;
};

const AccessCodeDialog = ({
  assessmentId,
  open = false,
  onClose,
}: AccessCodeDialogProps) => {
  const {
    createClinicianInvite,
    createAssessmentInviteMutation: { status, reset, data },
  } = useAssessmentMutations(assessmentId);

  const { addToast } = useToasts();

  const cancelButtonRef = React.useRef(null);

  const onCreate = async () => {
    createClinicianInvite();
  };

  const copyToClipboard = async (accessCode: string) => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(accessCode);
      addToast({ type: "message", content: "Copied to clipboard" });
    }
  };

  const handleClose = React.useCallback(() => {
    reset();
    if (onClose) {
      onClose();
    }
  }, [reset, onClose]);

  React.useEffect(() => {
    if (status === "error") {
      handleClose();
    }
  }, [status, handleClose]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <ReactDialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => void {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ReactDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {status === "idle" && (
                <>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FaExclamationCircle
                        className="h-5 w-5 text-primary-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <ReactDialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Create clinician code
                      </ReactDialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Creating a clinician code will delete the existing
                          patient invite code. The patient will no longer be
                          able to complete the form using their old code. Are
                          you sure you want to create a clinician code?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <Button
                      as="button"
                      onClick={() => onCreate()}
                      className="ml-2"
                      color="primary"
                    >
                      Create
                    </Button>
                    <Button
                      as="button"
                      onClick={() => handleClose()}
                      variant="outlined"
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
              {status === "loading" && (
                <div className="flex justify-center items-center h-52">
                  <Spinner size="lg" />
                </div>
              )}
              {status === "success" && data?.code && (
                <div className="flex flex-col h-52 text-sm">
                  {" "}
                  <ReactDialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Create clinician code
                  </ReactDialog.Title>
                  <div className="flex-1 flex flex-col mt-2">
                    <div>
                      To start this assessment go to{" "}
                      <a
                        href="https://ultramed.app/start"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-500"
                      >
                        https://ultramed.app/start
                      </a>{" "}
                      and enter the following code. This code will expire{" "}
                      {`${
                        data.expired
                          ? `on ${formatLongDate({ date: data.expired })}`
                          : "1 hour"
                      }`}
                      .
                    </div>
                    <div className="flex justify-center items-center flex-1">
                      <div
                        className="font-mono text-xl border border-gray-300 p-4 rounded-lg border-dashed cursor-pointer hover:bg-gray-50"
                        onClick={() => copyToClipboard(data.code)}
                      >
                        {data.code}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end items-end">
                    <Button
                      as="button"
                      onClick={() => handleClose()}
                      variant="outlined"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </ReactDialog>
    </Transition.Root>
  );
};

export { AccessCodeDialog };
