import { FaFolder } from "react-icons/fa";
import { IconType } from "react-icons/lib";

type EmptyProps = {
  title?: string;
  icon?: IconType;
  children?: React.ReactNode;
};

function Empty({ title, children, ...props }: EmptyProps): JSX.Element {
  return (
    <div className="flex justify-center items-center flex-1 flex-col">
      {props.icon ? (
        <props.icon className="h-12 w-12 text-gray-400" />
      ) : (
        <FaFolder className="h-12 w-12 text-gray-400" />
      )}
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {title ? title : "Nothing to show"}
      </h3>
      <div className="mt-1 text-sm text-gray-500">
        {!children && <>Nothing to show here.</>}
        {children}
      </div>
    </div>
  );
}

export { Empty };
