import React from "react";
import { FaCalendar, FaEnvelope, FaMobileAlt, FaPhone } from "react-icons/fa";
import { formatBorn, formatName } from "../../utilities/cuiFormat";
import { Patient } from "../../models/patient.model";
import { useToasts } from "../../context/ToastProvider";
import { classNames } from "../../utilities/mergeStyles";
import { Button } from "../Button";
import { EditPatientModal } from "../EditPatientModal";
import { PermissionGuard } from "../PermissionGuard";
import { formatIdentifier } from "../../utilities/patient-identifier";
import { PatientIdentifierIcon } from "../PatientIdentifierIcon";
import { useTenant } from "../../context/TenantContext";

type DemographicItemProps = {
  copyText?: string;
  allowCopy?: boolean;
  label: string;
};

const DemographicItem: React.FC<DemographicItemProps> = ({
  children,
  copyText,
  allowCopy = true,
  label,
}) => {
  const { addToast } = useToasts();

  const copyToClipboard = async (text: string) => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      addToast({ type: "message", content: "Copied to clipboard" });
    }
  };

  return (
    <button
      type="button"
      className={classNames(
        "flex items-center px-2 py-1",
        allowCopy && copyText
          ? "border hover:border-gray-300 border-transparent rounded-md"
          : "cursor-default"
      )}
      onClick={() => allowCopy && copyText && copyToClipboard(copyText)}
      title="Click to copy"
      disabled={!allowCopy || !copyText}
      aria-label={label}
    >
      {children}
    </button>
  );
};

type DemographicBannerProps = {
  patient: Patient;
};

function PatientDemographics({ patient }: DemographicBannerProps): JSX.Element {
  const [editPatientOpen, setEditPatientOpen] = React.useState(false);
  const { tenant } = useTenant();

  return (
    <>
      <div className="flex mb-2 space-x-2">
        <h1 className="text-2xl font-semibold text-gray-900">
          {formatName(
            patient?.givenName ?? "",
            patient?.familyName ?? "",
            patient?.prefix
          )}
        </h1>
        <div className="flex items-center text-sm text-gray-600 flex-wrap">
          {Object.entries(patient.identifier).map(([key, value]) => {
            const idType = tenant.identifierTypes?.find((i) => i.id === key);
            return (
              <DemographicItem
                key={key}
                copyText={value}
                label={`${idType?.name} ${value}`}
              >
                <PatientIdentifierIcon
                  identifierType={idType}
                  className="mr-2"
                />
                {idType ? formatIdentifier(value, idType) : value}
              </DemographicItem>
            );
          })}
          <DemographicItem
            copyText={formatBorn(patient.born)}
            label={`Born ${formatBorn(patient.born)}`}
          >
            <FaCalendar className="mr-2" />
            {patient?.born && `Born ${formatBorn(patient.born)}`}
          </DemographicItem>
          <DemographicItem
            copyText={patient.mobile}
            label={`Mobile phone ${patient.mobile}`}
          >
            <FaMobileAlt className="mr-2" />
            {patient.mobile && patient.mobile.length > 0
              ? patient.mobile
              : "None"}
          </DemographicItem>
          <DemographicItem
            copyText={patient.telephone}
            label={`Telephone ${patient.telephone}`}
          >
            <FaPhone className="mr-2" />
            {patient.telephone && patient.telephone.length > 0
              ? patient.telephone
              : "None"}
          </DemographicItem>
          {patient.email && (
            <DemographicItem
              copyText={patient.email}
              label={`Email ${patient.email}`}
            >
              <FaEnvelope className="mr-2" />
              {patient.email}
            </DemographicItem>
          )}
        </div>
        <PermissionGuard requiredPermissions={["write:patient"]}>
          <div>
            <Button
              as="button"
              size="sm"
              variant="outlined"
              onClick={() => setEditPatientOpen(true)}
            >
              Edit patient
            </Button>
          </div>
          <EditPatientModal
            patient={patient}
            isOpen={editPatientOpen}
            setIsOpen={setEditPatientOpen}
          />
        </PermissionGuard>
      </div>
    </>
  );
}

export { PatientDemographics };
