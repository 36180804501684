import React from "react";
import { FaFilter } from "react-icons/fa";
import { FilterProps } from "react-table";
import { mergeStyles } from "../../utilities/mergeStyles";
import { useServices } from "../../hooks/useServices";
import { FilterBox } from "../FilterBox";
import { Item } from "@react-stately/collections";

const AssessmentsTableFilterService: React.FC<
  FilterProps<Record<string, unknown>>
> = ({ column }) => {
  const {
    servicesQuery: { status: serviceStatus, data: services },
  } = useServices();

  const onItemSelect = (keys: React.Key[]) => {
    column.setFilter(keys);
  };

  const selectedServices =
    services
      ?.map((service) => service.id)
      .filter((id) => column.filterValue?.includes(id)) ?? [];

  return (
    <FilterBox
      placement="bottom end"
      loading={serviceStatus === "loading"}
      button={
        <button
          className={mergeStyles([
            "flex w-10 items-center justify-center pr-1 pt-2 pb-2 pl-1 outline-none focus-visible:ring-2 focus-visible:ring-primary-400 rounded-md mr-1 mt-1 mb-1 hover:text-gray-500",
            selectedServices?.length > 0 ? "text-gray-500" : "text-gray-300",
          ])}
          aria-label={`Filter by ${column.Header?.toString()}`}
        >
          <FaFilter></FaFilter>
        </button>
      }
      label="Service"
      items={services ?? []}
      selectedKeys={selectedServices}
      onMultipleChange={(keys) => onItemSelect(keys)}
      selectionMode="multiple"
    >
      {(item) => <Item>{item.name}</Item>}
    </FilterBox>
  );
};

export { AssessmentsTableFilterService };
