import { Item } from "@react-stately/collections";
import { FaHospitalSymbol } from "react-icons/fa";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { useServices } from "../../hooks/useServices";
import { Service } from "../../models/service.model";
import { AssessmentHeaderDropdown } from "../AssessmentHeaderDropdown";
import { FilterBox } from "../FilterBox";

type AssignServiceProps = {
  assessmentId: string;
  service: Service;
  disabled?: boolean;
};

function AssignService({
  assessmentId,
  service,
  disabled,
}: AssignServiceProps) {
  const {
    servicesQuery: { data, status },
  } = useServices();

  const { updateService } = useAssessmentMutations(assessmentId);

  const handleChange = (value: string) => {
    const newService = data?.find((service) => service.id === value);
    if (newService) {
      updateService(newService);
    }
  };

  return (
    <FilterBox
      loading={status === "loading"}
      button={
        <AssessmentHeaderDropdown
          disabled={disabled}
          icon={<FaHospitalSymbol />}
        >
          {service.name ?? "Select service"}
        </AssessmentHeaderDropdown>
      }
      label="Service"
      items={data ?? []}
      onChange={(value) => value && handleChange(value.toString())}
      selectedKey={service.id}
      disabled={disabled}
    >
      {(item) => <Item>{item.name}</Item>}
    </FilterBox>
  );
}

export { AssignService };
