import {
  FaArchive,
  FaClipboardCheck,
  FaHourglass,
  FaInbox,
  FaStopwatch,
  FaTrash,
} from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { AssessmentStatus } from "../models/assessment.model";

type StatusMap = {
  status: AssessmentStatus | "archived";
  text: string;
  icon: IconType;
  color: "gray" | "yellow" | "primary" | "green" | "red" | "orange";
};

const statusMap: StatusMap[] = [
  { status: "draft", text: "Expected", icon: FaStopwatch, color: "gray" },
  { status: "on-hold", text: "Received", icon: FaInbox, color: "yellow" },
  {
    status: "active",
    text: "In progress",
    icon: FaHourglass,
    color: "primary",
  },
  {
    status: "completed",
    text: "Completed",
    icon: FaClipboardCheck,
    color: "green",
  },
  { status: "archived", text: "Archived", icon: FaArchive, color: "orange" },
  { status: "entered-in-error", text: "Bin", icon: FaTrash, color: "red" },
];

export type { StatusMap };
export { statusMap };
