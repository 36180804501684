import { classNames } from "../../utilities/mergeStyles";

type PageHeaderProps = {
  justify?: "start" | "end" | "between";
} & React.HTMLAttributes<HTMLDivElement>;

const PageHeader: React.FC<PageHeaderProps> = ({
  className,
  justify = "between",
  ...props
}) => {
  return (
    <header
      className={classNames(
        justify === "between" && "justify-between",
        justify === "start" && "justify-start",
        justify === "end" && "justify-end",
        "flex mb-8 flex-shrink-0",
        className
      )}
      {...props}
    />
  );
};

export { PageHeader };
