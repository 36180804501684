import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/browser";
import { config } from "../config";
import { stringify } from "../utilities/querystring";

type FetchOptions<T> = Omit<RequestInit, "body"> & {
  query?: {
    [key: string]: string | number | boolean | (string | number | boolean)[];
  };
  body?: Record<string, unknown>;
  responseHandler?: (res: Response) => T;
};

type UseApi = {
  fetchWithToken: <T>(
    input: RequestInfo,
    options?: FetchOptions<T>
  ) => Promise<T>;
  isErrorCode: (error: unknown, status: number) => boolean;
};

/**
 * Validation Error
 *
 * @class ValidationError
 * @extends {Error}
 */
class FetchError extends Error {
  response: Response;
  constructor(response: Response) {
    super();
    this.name = "FetchError";
    this.response = response;
  }
}

/**
 * Custom hook that returns a promise with fetch result as JSON.
 * As a convenience we add the Auth0 token and set the base API URL.
 * @returns
 */
const useApi = (): UseApi => {
  const { getAccessTokenSilently } = useAuth0();

  async function fetchWithToken<T = unknown>(
    input: RequestInfo,
    options?: FetchOptions<T>
  ) {
    try {
      const { query, ...rest } = options ?? {};

      const queryString = query ? stringify(query) : "";

      // Create a new AbortController instance for this request
      const controller = new AbortController();
      // Get the abortController's signal
      const signal = controller.signal;

      const token = await getAccessTokenSilently({
        audience: config.auth0.audience,
      });

      if (config.environment === "development") {
        // eslint-disable-next-line no-console
        console.log(token);
      }

      const res = await window.fetch(
        `${config.api.url}${input}${queryString}`,
        {
          ...rest,
          headers: {
            ...rest?.headers,
            Authorization: `Bearer ${token}`,
            ...(rest.body && { "Content-Type": "application/json" }),
          },
          body: rest.body && JSON.stringify(rest.body),
          signal,
        }
      );

      if (!res.ok) {
        throw new FetchError(res);
      }

      if (options?.responseHandler) {
        return options.responseHandler(res) as T;
      }

      if (res.status !== 204) {
        return res.json() as unknown as T;
      }

      return res.text() as unknown as T;
    } catch (error) {
      // Don't log 404 errors in Sentry
      if (error instanceof FetchError && error.response.status === 404) {
        throw error;
      }
      Sentry.captureException(error, { extra: options });
      throw error;
    }
  }

  const isErrorCode = (error: unknown, status: number): boolean =>
    Boolean(error) &&
    error instanceof FetchError &&
    error.response.status === status;

  return { fetchWithToken, isErrorCode };
};

export { useApi, FetchError };
