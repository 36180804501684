import { useDownload } from "../../hooks/useDownload";
import { Button } from "../Button";

type DownloadButtonProps = {
  id: string;
};

function DownloadButton({ id }: DownloadButtonProps) {
  const { downloadViaSignedUrl, status } = useDownload({
    route: `/documents/download/${id}`,
  });

  return (
    <Button
      as="button"
      color="primary"
      onClick={() => downloadViaSignedUrl()}
      loading={status === "loading"}
      spinnerColor="gray"
      className="w-24"
      variant="text"
      flat
    >
      Download
    </Button>
  );
}

export { DownloadButton };
