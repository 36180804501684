import React from "react";
import { classNames } from "../../utilities/mergeStyles";

type Colors = "green" | "red" | "yellow" | "primary" | "gray" | "orange";
type PillProps = {
  color: Colors;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const colorMap: {
  [key in Colors]: {
    hoverColor: string;
    bgColor: string;
  };
} = {
  green: {
    hoverColor: "bg-green-600",
    bgColor: "bg-green-500",
  },
  red: {
    hoverColor: "bg-red-600",
    bgColor: "bg-red-500",
  },
  yellow: {
    hoverColor: "bg-yellow-600",
    bgColor: "bg-yellow-500",
  },
  orange: {
    hoverColor: "bg-yellow-800",
    bgColor: "bg-yellow-700",
  },
  primary: {
    hoverColor: "bg-primary-600",
    bgColor: "bg-primary-500",
  },
  gray: {
    hoverColor: "bg-gray-600",
    bgColor: "bg-gray-500",
  },
};

const Pill = React.forwardRef<HTMLButtonElement, PillProps>(
  ({ disabled = false, color = "green", ...rest }, ref) => {
    const colors = colorMap[color];
    return (
      <button
        className={classNames(
          `flex items-center ${colors.bgColor} px-2 py-1 rounded-md text-white text-xs`,
          !disabled && `hover:${colors.hoverColor} cursor-pointer`,
          disabled && "cursor-default"
        )}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    );
  }
);

export { Pill };
