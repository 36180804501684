import { classNames } from "../../utilities/mergeStyles";

const ModalContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames("flex-1 mt-2 min-h-0 flex flex-col", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export { ModalContent };
