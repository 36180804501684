import React from "react";
import { FlatSetting } from "../../models/form.model";
import { Characteristics, Medication } from "../../models/submission.model";

interface MedicationListProps {
  settings: FlatSetting;
  characteristics: Characteristics;
}

const MedicationList: React.FC<MedicationListProps> = ({
  settings,
  characteristics,
  children,
}) => {
  const medications = characteristics["medication_list"]?.value as
    | Medication[]
    | undefined;

  return (
    <>
      {medications && medications.length > 0 ? (
        <>
          <tr>
            <td>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-left">Medicine</th>
                    <th className="p-2 text-left">Dosage, Route</th>
                    <th className="p-2 text-left">Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {medications.map((medication, index) => (
                    <tr key={index}>
                      <td className="p-2">{medication.name}</td>
                      <td className="p-2">
                        {medication.dose} {medication.doseUnits.text},{" "}
                        {medication.route.term ?? medication.route.text}
                      </td>
                      <td className="p-2">
                        {medication.frequency} times{" "}
                        {medication.periodUnit.text}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td className="p-2">No medications recorded</td>
        </tr>
      )}
    </>
  );
};

export { MedicationList };
