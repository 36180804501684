/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const config = {
  sentry: {
    dsn: "https://b8ad55ead9dd48b29d3b45d0e53fcbdd@sentry.ultramed.co/5",
  },
  posthog: {
    id: "phc_qQQ8hGQ3PdalUCfG2qAinzrYfUADv6TmpruI4BYn0MM",
    apiHost: "https://a.ultramed.app",
  },
  environment: process.env.NODE_ENV,
  domain: process.env.REACT_APP_DOMAIN,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN!,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID!,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
  },
  api: {
    url: process.env.REACT_APP_API_BASE_URL!,
  },
};
