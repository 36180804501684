import { useQueryClient, useMutation, QueryKey } from "react-query";
import { useToasts } from "../context/ToastProvider";
import {
  Assessment,
  AssessmentTagUpdate,
  AssessmentUpdate,
} from "../models/assessment.model";
import {
  PatientInvite,
  PatientInviteContactMethod,
} from "../models/patientInvite.model";
import { Response } from "../models/response.model";
import { Service } from "../models/service.model";
import { Tag } from "../models/tag.model";
import { User } from "../models/user.model";
import { FetchError, useApi } from "./useApi";

const useAssessmentMutations = (assessmentId: string) => {
  const { fetchWithToken } = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const assessmentKey = ["assessment", assessmentId];

  const updateAssessment = async ({
    id,
    assessmentUpdate,
  }: {
    id: string;
    assessmentUpdate: AssessmentUpdate;
  }) => {
    const result = await fetchWithToken<Response<Assessment>>(
      `/assessments/${id}`,
      {
        method: "PATCH",
        body: assessmentUpdate,
      }
    );
    return result.data;
  };

  const updateAssessmentTag = async ({
    id,
    assessmentTagUpdate,
  }: {
    id: string;
    assessmentTagUpdate: AssessmentTagUpdate;
  }) => {
    const result = await fetchWithToken<Response<Assessment>>(
      `/assessments/${id}/tags`,
      {
        method: "PUT",
        body: {
          tagId: assessmentTagUpdate.tag?.tagId ?? null,
          comment: assessmentTagUpdate.comment,
        },
      }
    );
    return result.data;
  };

  const createAssessmentInvite = async ({
    id,
    contactMethod,
  }: {
    id: string;
    contactMethod: PatientInviteContactMethod;
  }) => {
    const result = await fetchWithToken<Response<PatientInvite>>(
      `/assessments/${id}/invites`,
      {
        method: "POST",
        body: {
          contactMethod,
        },
      }
    );
    return result.data;
  };

  const deleteAssessment = async ({
    id,
    assessmentDeleteBody,
  }: {
    id: string;
    assessmentDeleteBody: { comment: string };
  }) => {
    await fetchWithToken<Response<Assessment>>(`/assessments/${id}`, {
      method: "DELETE",
      body: assessmentDeleteBody,
    });
  };

  const createAssessmentInviteMutation = useMutation<
    PatientInvite,
    FetchError,
    {
      id: string;
      contactMethod: PatientInviteContactMethod;
    }
  >(createAssessmentInvite, {
    onError: () => {
      addToast({
        type: "error",
        content: "Error creating invite code. Please try again",
      });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(assessmentKey);
    },
    onSuccess: () => {
      addToast({
        type: "message",
        content: "Invite code successfully created.",
      });
    },
  });

  const deleteAssessmentMutation = useMutation<
    unknown,
    FetchError,
    {
      id: string;
      assessmentDeleteBody: {
        comment: string;
      };
    }
  >(deleteAssessment, {
    onError: () => {
      addToast({
        type: "error",
        content: "Error moving assessment to bin. Please try again",
      });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(assessmentKey);
      queryClient.invalidateQueries(["assessments"]);
    },
    onSuccess: () => {
      addToast({
        type: "message",
        content: "Assessment moved to bin.",
      });
    },
  });

  const updateAssessmentMutation = useMutation<
    Assessment,
    FetchError,
    {
      id: string;
      assessmentUpdate: AssessmentUpdate;
      assessment: Partial<Assessment>;
    },
    Response<Assessment>
  >((update) => updateAssessment(update), {
    // When mutate is called:
    onMutate: async (update) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(assessmentKey);

      // Snapshot the previous value
      const previousAssessment =
        queryClient.getQueryData<Response<Assessment>>(assessmentKey);

      // Optimistically update to the new value
      queryClient.setQueryData<Response<Assessment> | undefined>(
        assessmentKey,
        (old) => {
          return old
            ? { ...old, data: { ...old.data, ...update.assessment } }
            : undefined;
        }
      );

      // Return a context with the previous Assessments
      return previousAssessment;
    },
    // If the mutation fails, use the context we returned above
    onError: (err, update, context) => {
      addToast({
        type: "error",
        content: "Error updating assessment. Please try again",
      });
      queryClient.setQueryData(assessmentKey, context);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(assessmentKey);
    },
    onSuccess: () => {
      addToast({ type: "message", content: "Assessment updated" });
    },
  });

  const updateAssessmentTagMutation = useMutation<
    Assessment,
    FetchError,
    {
      id: string;
      assessmentTagUpdate: AssessmentTagUpdate;
    },
    {
      previousAssessment: Response<Assessment> | undefined;
      previousAssessments: [QueryKey, Assessment[]][];
    }
  >((update) => updateAssessmentTag(update), {
    // When mutate is called:
    onMutate: async (update) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(assessmentKey);

      // Snapshot the previous value
      const previousAssessment =
        queryClient.getQueryData<Response<Assessment>>(assessmentKey);

      // Snapshot all the assessments queries
      const previousAssessments = queryClient.getQueriesData<Assessment[]>([
        "assessments",
      ]);

      // Update all the assessments in the assessments query (to make sure it updates in the table).
      queryClient.setQueriesData<Partial<Response<Assessment[]>> | undefined>(
        ["assessments"],
        (old) => ({
          ...old,
          data: old?.data?.map((assessment) =>
            assessment.id === update.id
              ? {
                  ...assessment,
                  tag: update.assessmentTagUpdate.tag,
                  status:
                    update.assessmentTagUpdate.tag?.parentStatus ??
                    assessment.status,
                }
              : assessment
          ),
        })
      );

      // Optimistically update the individual assessment to the new value as well.
      queryClient.setQueryData<Response<Assessment> | undefined>(
        assessmentKey,
        (old) => {
          return old
            ? {
                ...old,
                data: {
                  ...old.data,
                  tag: update.assessmentTagUpdate.tag,
                  status:
                    update.assessmentTagUpdate.tag?.parentStatus ??
                    old.data.status,
                },
              }
            : undefined;
        }
      );
      return { previousAssessment, previousAssessments };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, update, context) => {
      addToast({
        type: "error",
        content: "Error updating tag. Please try again",
      });
      queryClient.setQueryData(assessmentKey, context?.previousAssessment);
      queryClient.setQueryData(["assessments"], context?.previousAssessments);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(assessmentKey);
      queryClient.invalidateQueries(["assessments"]);
    },
    onSuccess: () => {
      addToast({ type: "message", content: "Assessment tag updated" });
    },
  });

  const updateTci = (tciDate: Date | undefined) => {
    return updateAssessmentMutation.mutate({
      id: assessmentId,
      assessment: { tciDate: tciDate ?? null },
      assessmentUpdate: { tciDate: tciDate ?? null },
    });
  };

  const updatePriority = (priority: number) => {
    return updateAssessmentMutation.mutate({
      id: assessmentId,
      assessment: { priority },
      assessmentUpdate: { priority },
    });
  };

  const updateAsa = (asaGrade: number) => {
    return updateAssessmentMutation.mutate({
      id: assessmentId,
      assessment: { asaGrade },
      assessmentUpdate: { asaGrade },
    });
  };

  const updateArchived = (archived: boolean) => {
    return updateAssessmentMutation.mutate({
      id: assessmentId,
      assessment: { archived },
      assessmentUpdate: { archived },
    });
  };

  const updateService = (service: Service) => {
    return updateAssessmentMutation.mutate({
      id: assessmentId,
      assessment: { service },
      assessmentUpdate: { serviceId: service.id },
    });
  };

  const updateConsultant = (consultant: User | null) => {
    return updateAssessmentMutation.mutate({
      id: assessmentId,
      assessment: { consultant },
      assessmentUpdate: { consultantId: consultant?.id ?? null },
    });
  };

  const updateAssignedUser = (assignedUser: User | null) => {
    return updateAssessmentMutation.mutate({
      id: assessmentId,
      assessment: { assignedUser },
      assessmentUpdate: { assignedUserId: assignedUser?.id ?? null },
    });
  };

  const updateTag = (tag: Tag | null, comment?: string) => {
    return updateAssessmentTagMutation.mutate({
      id: assessmentId,
      assessmentTagUpdate: {
        tag,
        comment,
      },
    });
  };

  const createClinicianInvite = () => {
    return createAssessmentInviteMutation.mutate({
      id: assessmentId,
      contactMethod: "clinician",
    });
  };

  const createPatientInvite = (
    contactMethod: Exclude<PatientInviteContactMethod, "clinician">
  ) => {
    return createAssessmentInviteMutation.mutate({
      id: assessmentId,
      contactMethod: contactMethod,
    });
  };

  return {
    updateTci,
    updatePriority,
    updateAsa,
    updateArchived,
    updateService,
    updateConsultant,
    updateAssignedUser,
    updateTag,
    createClinicianInvite,
    createPatientInvite,
    createAssessmentInviteMutation,
    deleteAssessmentMutation,
  };
};

export { useAssessmentMutations };
