import { PageContainer } from "../../components/PageContainer";
import { PageBody } from "../../components/PageBody";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { usePatient } from "../../hooks/usePatient";
import { PatientDemographics } from "../../components/PatientDemographics";
import { useApi } from "../../hooks/useApi";
import { PatientAssessments } from "../../components/PatientAssessments";

function Patient(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const {
    patientQuery: { status, data: patient, error },
  } = usePatient(id);

  const { isErrorCode } = useApi();

  if (status === "error" && isErrorCode(error, 404)) {
    return <Redirect to="/notfound" />;
  }

  return (
    <>
      <PageContainer status={status}>
        <div>
          <button
            type="button"
            onClick={() => history.goBack()}
            className={"text-sm text-gray-500 hover:text-gray-700"}
          >
            Back
          </button>
        </div>
        {patient && (
          <>
            <PatientDemographics patient={patient} />
            <PageBody>
              <PatientAssessments patientId={patient.id} />
            </PageBody>
          </>
        )}
      </PageContainer>
    </>
  );
}

export { Patient };
