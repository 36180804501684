import React from "react";

type BreadcrumbProps = {
  children: React.ReactNode;
};

function Breadcrumb({ children }: BreadcrumbProps): JSX.Element {
  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2">
        {React.Children.map(
          children,
          (child, index) =>
            React.isValidElement(child) &&
            React.cloneElement(child, {
              first: index === 0,
            })
        )}
      </ol>
    </nav>
  );
}

export { Breadcrumb };
