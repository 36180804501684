import React from "react";
import { Listbox } from "@headlessui/react";
import { usePopper } from "react-popper";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import { classNames } from "../../utilities/mergeStyles";
import { Filters } from "react-table";
import { StatusMap, statusMap } from "../../utilities/statusMap";
import { useAssessments } from "../../hooks/useAssessments";

type AssessmentsTableFilterStatusProps = {
  // Disabled as this type is taken from react-table
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilter: (columnId: string, updater: any) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  filters: Filters<{}>;
};

const AssessmentsTableFilterStatus = ({
  setFilter,
}: AssessmentsTableFilterStatusProps): JSX.Element => {
  const { selectedStatus, setSelectedStatus } = useAssessments();

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLUListElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const handleListBoxSelect = (option: StatusMap) => {
    setSelectedStatus(option);
    setFilter("status", option.status);
  };

  const Icon = React.useMemo(() => selectedStatus?.icon, [selectedStatus]);

  const colorMap = {
    green: "text-green-500",
    red: "text-red-500",
    yellow: "text-yellow-500",
    orange: "text-yellow-600",
    primary: "text-primary-500",
    gray: "text-gray-500",
  };

  return (
    <Listbox value={selectedStatus} onChange={handleListBoxSelect}>
      <div className="inline-flex items-center">
        <Listbox.Label className="block text-sm font-medium text-gray-700 mr-2">
          <span className="sr-only">Change status filter.</span>
          <span aria-hidden="true">Showing</span>
        </Listbox.Label>
        <div className="inline-flex">
          <Listbox.Button
            ref={setReferenceElement}
            className="relative inline-flex items-center px-2.5 py-1.5 rounded-md focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-400 border-gray-300 border shadow-sm bg-white text-xs text-gray-700 font-medium"
          >
            {Icon && (
              <Icon
                className={`h-3 w-3 mr-2 ${
                  selectedStatus?.color
                    ? colorMap[selectedStatus?.color]
                    : "text-gray-500"
                }`}
                aria-hidden="true"
              />
            )}
            <span aria-hidden="true">{selectedStatus?.text}</span>
            <span className="sr-only">
              Currently showing {selectedStatus?.text}.
            </span>

            <FaChevronDown
              className="h-3 w-3 border-gray-400 ml-2"
              aria-hidden="true"
            />
          </Listbox.Button>
          <Listbox.Options
            ref={setPopperElement}
            style={styles.popper}
            className="z-20 right-0 w-56 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
            {...attributes.popper}
          >
            {statusMap.map((option) => (
              <Listbox.Option
                key={option.status}
                className={({ active }) =>
                  classNames(
                    active ? "text-white bg-primary-400" : "text-gray-900",
                    "flex justify-between pl-4 pr-4 items-center cursor-default select-none p-2 text-sm"
                  )
                }
                value={option}
              >
                {({ selected, active }) => {
                  const OptionIcon = option.icon;
                  return (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "inline-flex items-center flex-1"
                        )}
                      >
                        {OptionIcon && (
                          <OptionIcon
                            className={classNames(
                              `h-3 w-3 mr-4`,
                              active
                                ? "text-white"
                                : `${
                                    colorMap[option?.color] ?? "text-gray-500"
                                  }`
                            )}
                            aria-hidden="true"
                          />
                        )}
                        {option.text}
                      </span>
                      {selected ? (
                        <FaCheck
                          className={classNames(
                            active ? "text-white" : "text-primary-400",
                            "h-3 w-3"
                          )}
                          aria-hidden="true"
                        />
                      ) : null}
                    </>
                  );
                }}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </div>
    </Listbox>
  );
};

export { AssessmentsTableFilterStatus };
