import React from "react";
import { FlatSetting } from "../../models/form.model";

interface SpanProps {
  name: string;
  settings: FlatSetting;
}

const Span: React.FC<SpanProps> = ({ settings, children }) => {
  return (
    <>
      <span>{settings.text?.value}</span>
      {children}
    </>
  );
};

export { Span };
