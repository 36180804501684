import React from "react";

import { useToken } from "../../context/TokenContext";
import { useAssignableRoles } from "../../hooks/useAssignableRoles";
import { useServices } from "../../hooks/useServices";
import { useUserRoles } from "../../hooks/useUserRoles";
import { User } from "../../models/user.model";
import { Modal } from "../Modal";
import { Spinner } from "../Spinner";
import { UserDetailsForm } from "../UserDetailsForm";

type ViewUserModalProps = {
  user: User;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const ViewUserModal = ({ user, isOpen, setIsOpen }: ViewUserModalProps) => {
  const { decodedToken } = useToken();
  const { servicesQuery } = useServices();
  const { rolesQuery } = useAssignableRoles();
  const { data: servicesData, status: servicesStatus } = servicesQuery;
  const { data: rolesData, status: rolesStatus } = rolesQuery;
  const {
    userRolesQuery: { status: userRolesStatus, data: userRolesData },
  } = useUserRoles({ userId: user.authId, orgId: decodedToken.org_id });

  return (
    <>
      {(servicesStatus === "loading" ||
        rolesStatus === "loading" ||
        userRolesStatus === "loading") && <Spinner></Spinner>}
      {servicesStatus === "success" &&
        rolesStatus === "success" &&
        userRolesStatus === "success" &&
        rolesData &&
        servicesData &&
        userRolesData && (
          <Modal
            title="View user"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="lg:w-6/12 flex min-h-0"
          >
            <UserDetailsForm
              disabled
              services={servicesData}
              roles={rolesData}
              onSubmit={() => setIsOpen(false)}
              onCancel={() => setIsOpen(false)}
              userData={{ ...user, authRoles: userRolesData }}
              actionButtonText="Close"
            ></UserDetailsForm>
          </Modal>
        )}
    </>
  );
};

export { ViewUserModal };
