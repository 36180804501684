import * as React from "react";
import { IconBaseProps } from "react-icons";
import { FaHospitalSymbol } from "react-icons/fa";

import { IdentifierType } from "../../models/identifier-type.model";
import { NhsLogo } from "../NhsLogo";

type PatientIdentifierIconProps = {
  identifierType?: IdentifierType;
} & React.InputHTMLAttributes<SVGElement>;

const PatientIdentifierIcon: React.FC<PatientIdentifierIconProps> = ({
  identifierType,
  children,
  ...rest
}: PatientIdentifierIconProps) => {
  if (
    identifierType?.code === "NH" &&
    (identifierType?.authority === "NHS" ||
      identifierType?.authority === "NHS Scotland")
  ) {
    return <NhsLogo {...rest}>{children}</NhsLogo>;
  }

  if (identifierType?.type === "local") {
    return (
      <FaHospitalSymbol {...(rest as IconBaseProps)}>
        {children}
      </FaHospitalSymbol>
    );
  }

  return null;
};

export { PatientIdentifierIcon };
