/**
 * @copyright 2021 Ultramed Ltd
 * @author Nicolaas Prinsloo <nick@ultramed.co>
 * ToastContext
 * Manages the state of toast messages.
 */
import React from "react";
import { ToastContainer } from "../components/ToastContainer";

type ToastItem = {
  id: number;
  content: string;
  type: "message" | "warning" | "error";
};

type ToastItemInput = Omit<ToastItem, "id">;

const ToastContext = React.createContext<{
  addToast: (item: ToastItemInput) => void;
  deleteToast: (id: number) => void;
} | null>(null);

const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = React.useState<ToastItem[]>([]);
  // Use ref to store the ID as we don't want incrementing this to result in a re-render.
  const id = React.useRef(0);

  // Wrap the functions in useCallback to reuse them instead of recreating on each render.
  const addToast = React.useCallback(
    (item: ToastItemInput) => {
      // Increment the ID
      id.current++;
      setToasts((toasts) => [
        ...toasts,
        {
          id: id.current,
          content: item.content,
          type: item.type,
        },
      ]);
    },
    [setToasts]
  );

  const deleteToast = React.useCallback(
    (id: number) => {
      setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider
      value={{
        addToast,
        deleteToast,
      }}
    >
      <ToastContainer toasts={toasts}></ToastContainer>
      {children}
    </ToastContext.Provider>
  );
};

const useToasts = () => {
  const context = React.useContext(ToastContext);

  if (!context) {
    throw new Error("useToasts must be used inside of ToastContext");
  }

  return context;
};

export type { ToastItem };
export { ToastProvider, useToasts };
