import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { SideNav } from "../../components/SideNav";
import { Users } from "../../components/Users";
import { AssessmentsProvider } from "../../hooks/useAssessments";
import { classNames } from "../../utilities/mergeStyles";
import { Assessment } from "../Assessment";
import { Assessments } from "../Assessments";
import { Patient } from "../Patient";
import { NotFound } from "../NotFound";
import { GlobalPatientSearch } from "../../components/GlobalPatientSearch";
import NavigationAnnouncer from "../../components/NavigationAnnouncer/NavigationAnnouncer";
import { PermissionGuard } from "../../components/PermissionGuard";

function AuthenticatedApp(): JSX.Element {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  return (
    <>
      <div className={classNames("w-full h-screen flex")}>
        <nav
          className={classNames(
            "group bg-primary-400 flex flex-col max-h-screen overflow-hidden transition-width duration-150 ease-in-out",
            isCollapsed ? "w-20" : "w-80"
          )}
        >
          <SideNav collapsed={isCollapsed} toggleCollapsed={setIsCollapsed} />
        </nav>
        <main className="bg-gray-100 flex flex-col max-h-screen w-full">
          <AssessmentsProvider>
            <NavigationAnnouncer />
            <GlobalPatientSearch />
            <Switch>
              <Route path="/notfound" exact>
                <NotFound />
              </Route>
              <Route path="/assessments" exact>
                <PermissionGuard
                  requiredPermissions={[
                    "list:assessments",
                    "list:tags",
                    "list:services",
                    "list:users",
                  ]}
                  fallback
                >
                  <Assessments />
                </PermissionGuard>
              </Route>
              <Route path="/assessments/:id">
                <PermissionGuard
                  requiredPermissions={["read:assessment"]}
                  fallback
                >
                  <Assessment />
                </PermissionGuard>
              </Route>
              <Route path="/users" exact>
                <Users></Users>
              </Route>
              <Route path="/patients/:id">
                <Patient />
              </Route>
              <Redirect from="/" to="/assessments" />
              <Redirect to="/assessments" />
            </Switch>
          </AssessmentsProvider>
        </main>
      </div>
    </>
  );
}

export { AuthenticatedApp };
