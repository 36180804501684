import React from "react";
import { FlatSetting } from "../../models/form.model";

interface TextHeadingProps {
  name: string;
  settings: FlatSetting;
}

const TextHeading: React.FC<TextHeadingProps> = ({ settings }) => {
  return (
    <>
      <p className="text-xl font-medium">{settings.headingText?.value}</p>
      <p className="text-base font-medium">{settings.subHeading?.value}</p>
    </>
  );
};

export { TextHeading };
