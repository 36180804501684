import React from "react";

const NhsLogo: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 200 80"
      height="1em"
      {...props}
    >
      <title>NHS logo</title>
      <g transform="matrix(1.29735,0,0,1.29735,-29.8391,-51.5309)">
        <path d="M171.39,47.08L168.15,57C164.678,55.418 160.894,54.639 157.08,54.72C151.76,54.72 147.44,55.5 147.44,59.51C147.44,66.59 166.93,63.95 166.93,79.12C166.93,92.93 154.05,96.51 142.4,96.51C137.131,96.404 131.904,95.559 126.87,94L130,83.79C132.65,85.51 138,86.66 142.37,86.66C146.55,86.66 153.09,85.87 153.09,80.72C153.09,72.72 133.6,75.72 133.6,61.62C133.6,48.73 144.97,44.87 155.97,44.87C162.16,44.87 167.97,45.52 171.36,47.09M132.73,45.73L122.3,95.73L108.88,95.73L113.34,74.33L97.44,74.33L93,95.67L79.54,95.67L90,45.72L103.44,45.72L99.46,64.83L115.35,64.83L119.35,45.72L132.79,45.72L132.73,45.73ZM85,45.72L74.36,95.72L57.67,95.72L47.18,61.11L47,61.11L40,95.67L27.39,95.67L38.11,45.67L54.87,45.67L65.16,80.36L65.3,80.36L72.3,45.72L85,45.72ZM177.15,39.72L23,39.72L23,101.78L177.16,101.78L177.16,39.73L177.15,39.72Z" />
      </g>
    </svg>
  );
};

export { NhsLogo };
