/**
 * @copyright 2021 Ultramed Ltd
 * @author Nicolaas Prinsloo <nick@ultramed.co>
 */

/**
 * View model for Patients
 * Based on Firestore Patients but rationalised
 */
type Patient = Readonly<{
  id: string;
  created: Date;
  updated: Date;
  givenName?: string;
  familyName?: string;
  prefix?: string;
  email?: string;
  mobile?: string;
  telephone?: string;
  born: Date;
  deceased: boolean;
  address?: {
    line: string[];
    city?: string;
    district?: string;
    state?: string;
    postalCode: string;
    country: string;
  };
  gender?: string;
  identifier: {
    [key: string]: string;
  };
}>;

type PatientInput = Omit<
  Patient,
  "id" | "created" | "updated" | "deceased" | "mobile" | "telephone"
> & {
  mobile?: string | null;
  telephone?: string | null;
};

const makePatient = (patient: Patient): Patient => Object.freeze(patient);
const makePatientInput = (patientInput: PatientInput): PatientInput =>
  Object.freeze(patientInput);

export { makePatient, makePatientInput };
export type { Patient, PatientInput };
