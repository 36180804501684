/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from "react-query";
import { Response } from "../models/response.model";
import { Filter } from "../models/apiquery.model";
import { useApi } from "./useApi";
import { Service } from "../models/service.model";
import { filterQuery } from "../utilities/querystring";

const useServices = () => {
  const { fetchWithToken } = useApi();

  const sortServices = (services: Service[]): Service[] => {
    return services.sort((a, b) =>
      (a.name?.toLowerCase() ?? "z") > (b.name?.toLocaleLowerCase() ?? "z")
        ? 1
        : -1
    );
  };

  const getServices = (params?: { filter?: Filter<Service> }) => {
    const { filter } = params ?? {};
    const filterQueryObj = filter ? filterQuery<Service>(filter) : undefined;
    return fetchWithToken<Response<Service[]>>("/services", {
      query: {
        ...filterQueryObj,
      },
    }).then((services) => ({
      ...services,
      data: sortServices(services.data),
    }));
  };

  const servicesQuery = useQuery(["services"], () => getServices(), {
    select: (data) => data.data,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 30, // Stale after 30 mins
  });

  return { servicesQuery };
};

export { useServices };
