import { useCombobox } from "downshift";
import { usePatients } from "../../hooks/usePatients";
import { Patient } from "../../models/patient.model";
import { classNames } from "../../utilities/mergeStyles";
import { formatName, formatShortDate } from "../../utilities/cuiFormat";
import { FaCheckCircle, FaSearch } from "react-icons/fa";
import { Input } from "../Input";
import { Spinner } from "../Spinner";
import { PatientIdentifier } from "../PatientIdentifier";

type PatientSearchProps = {
  onChange?: (patient?: Patient) => void;
};

const PatientSearch = ({ onChange }: PatientSearchProps): JSX.Element => {
  const {
    searchPatientsQuery: { data, status, isFetching, isPlaceholderData },
    setPatientSearchState,
    patientSearchState,
  } = usePatients();

  const itemToString = (patient: Patient | null) =>
    patient ? `${patient.givenName} ${patient.familyName}` : "";

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    itemToString,
    onSelectedItemChange: (changes) =>
      onChange && onChange(changes.selectedItem ?? undefined),
    items: data as Patient[],
  });

  return (
    <>
      <div
        {...getComboboxProps()}
        className="h-full min-h-0 flex flex-col"
        aria-haspopup={undefined}
        aria-expanded={undefined}
      >
        <div className="flex flex-col flex-shrink-0 pt-0.5">
          <Input
            id="patientSearch"
            name="patientSearch"
            type="text"
            label={
              <span className="sr-only" {...getLabelProps()}>
                Search
              </span>
            }
            {...getInputProps()}
            onChange={(e) => setPatientSearchState(e.currentTarget.value)}
            value={patientSearchState ?? ""}
            placeholder="Start typing to search"
            iconAfter={FaSearch}
            error={status === "error" ? "Something went wrong" : undefined}
            loading={status === "loading"}
          />
          {data && !isPlaceholderData && (
            <div className="text-gray-600 text-xs py-2 text-right">
              Showing {data.length} results
            </div>
          )}
        </div>
        <ul
          className="flex-1 overflow-y-auto p-1 min-h-0 divide-y divide-gray-200 space-y-0.5 scrollbar"
          {...getMenuProps()}
        >
          {status === "success" &&
            data &&
            data?.length > 0 &&
            data.map((patient, index) => (
              <li
                key={patient.id}
                className={classNames("flex")}
                {...getItemProps({
                  item: patient,
                  index,
                })}
              >
                <div
                  className={classNames(
                    "flex flex-1 justify-between items-center cursor-pointer py-4 box-border",
                    selectedItem?.id === patient.id &&
                      "ring-2 ring-primary-400 rounded-md",
                    highlightedIndex === index && "bg-gray-100"
                  )}
                >
                  <span className="sr-only">
                    {patient.prefix} {patient.givenName} {patient.familyName}
                  </span>
                  <div className="ml-3">
                    <p
                      aria-hidden
                      className="text-sm font-medium text-gray-900"
                    >
                      {formatName(
                        patient.givenName ?? "",
                        patient.familyName ?? "",
                        patient.prefix
                      )}
                    </p>
                    {Object.entries(patient.identifier).map(([key, value]) => {
                      return (
                        <p className="text-sm text-gray-500">
                          <PatientIdentifier
                            identifierId={key}
                            identifierValue={value}
                            prefixFormatter={(prefix) => `${prefix}:`}
                          ></PatientIdentifier>
                        </p>
                      );
                    })}
                    <p className="text-sm text-gray-500">
                      Born: {formatShortDate({ date: patient.born })}
                    </p>
                  </div>
                  {selectedItem?.id === patient.id && (
                    <FaCheckCircle className="mr-3 h-4 w-4 text-primary-400" />
                  )}
                </div>
              </li>
            ))}
          {status === "success" &&
            !isFetching &&
            !isPlaceholderData &&
            data &&
            data.length === 0 && (
              <li className="flex justify-center items-center text-sm">
                <p>No results found.</p>
              </li>
            )}
          {status === "loading" ||
            (isFetching && (
              <li className="flex justify-center items-center">
                <Spinner size="lg"></Spinner>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export { PatientSearch };
