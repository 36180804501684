import React from "react";
import { FlatSetting } from "../../models/form.model";

interface ChipProps {
  name: string;
  settings: FlatSetting;
}

const Chip: React.FC<ChipProps> = ({ settings, children }) => {
  return (
    <>
      <div className="font-medium">{settings.chipContent?.value}</div>
      {children}
    </>
  );
};

export { Chip };
