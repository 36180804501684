import { mergeStyles } from "../../utilities/mergeStyles";

type AvatarProps = {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  text?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

function Avatar({
  size = "md",
  text,
  className,
  ...rest
}: AvatarProps): JSX.Element {
  const getSizeStyles = () => {
    switch (size) {
      case "xs":
        return { container: "h-6 w-6", text: "text-xs" };
      case "sm":
        return { container: "h-8 w-8", text: "text-sm" };
      case "md":
      default:
        return { container: "h-10 w-10", text: "" };
      case "lg":
        return { container: "h-12 w-12", text: "text-lg" };
      case "xl":
        return { container: "h-14 w-14", text: "text-xl" };
    }
  };

  const getInitials = (text: string) =>
    text
      .split(" ")
      .slice(0, 2)
      .map((x) => x[0])
      .join("")
      .toUpperCase();

  return (
    <>
      {text ? (
        <span
          className={mergeStyles([
            getSizeStyles().container,
            "inline-flex items-center justify-center rounded-full",
            className,
          ])}
          {...rest}
        >
          <span
            className={mergeStyles([
              getSizeStyles().text,
              "font-medium leading-none",
            ])}
          >
            {getInitials(text)}
          </span>
        </span>
      ) : (
        <span
          className={mergeStyles([
            "inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100",
            getSizeStyles().container,
            className,
          ])}
        >
          <svg
            className="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
    </>
  );
}

export { Avatar };
