import React from "react";
import { Response } from "../models/response.model";
import { useApi } from "./useApi";

type DownloadParams = {
  route: string;
};

const useDownload = ({ route }: DownloadParams) => {
  const { fetchWithToken } = useApi();

  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );

  const getDownloadUrl = () => {
    return fetchWithToken<Response<{ url: string }>>(route);
  };

  const downloadViaSignedUrl = async () => {
    try {
      setStatus("loading");
      const response = await getDownloadUrl();
      const signedUrl = response.data.url;
      const link = document.createElement("a");
      link.href = signedUrl;
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      setStatus("idle");
    } catch (error) {
      setStatus("error");
    }
  };

  return { downloadViaSignedUrl, status };
};

export { useDownload };
