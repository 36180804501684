import { PageContainer } from "../../components/PageContainer";
import { NotFound as NotFoundComponent } from "../../components/NotFound";

function NotFound(): JSX.Element {
  return (
    <PageContainer>
      <NotFoundComponent />
    </PageContainer>
  );
}

export { NotFound };
