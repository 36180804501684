/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AsaGrade } from "../AsaGrade/AsaGrade";
import { Priority } from "../Priority";
import { AssignService } from "../AssignService";
import { TciDate } from "../TciDate";
import { AssignConsultant } from "../AssignConsultant";
import { AssignUser } from "../AssignUser";
import { AssignTag } from "../AssignTag";
import { Assessment } from "../../models/assessment.model";
import { PatientDemographics } from "../PatientDemographics";
import { usePermissions } from "../../hooks/usePermissions";

type DemographicBannerProps = {
  assessment: Assessment;
};

function AssessmentHeader({ assessment }: DemographicBannerProps): JSX.Element {
  const { hasPermissions } = usePermissions();
  const {
    patient,
    tciDate,
    priority,
    service,
    consultant,
    assignedUser,
    asaGrade,
    id,
    tag,
    status,
  } = assessment;
  return (
    <div className="flex flex-col">
      <PatientDemographics patient={patient!} />
      <div className="flex items-center text-sm text-gray-600 flex-wrap">
        <AssignService
          assessmentId={id}
          service={service}
          disabled={!hasPermissions(["update:service"])}
        />
        <AssignConsultant
          assessmentId={id}
          consultant={consultant}
          disabled={!hasPermissions(["update:consultant"])}
        />
        <AssignUser
          assessmentId={id}
          user={assignedUser}
          disabled={!hasPermissions(["update:user"])}
        />
      </div>
      <div className="flex justify-start gap-x-2 mt-2">
        <AssignTag
          assessmentId={id}
          tag={tag}
          assessmentStatus={status}
          disabled={!hasPermissions(["update:tag"])}
        />
        <TciDate
          assessmentId={id}
          tciDate={tciDate ?? undefined}
          disabled={!hasPermissions(["update:tci"])}
        />
        <AsaGrade
          assessmentId={id}
          asaGrade={asaGrade}
          disabled={!hasPermissions(["update:asa"])}
        />
        <Priority
          priority={priority}
          assessmentId={id}
          disabled={!hasPermissions(["update:priority"])}
        />
      </div>
    </div>
  );
}

export { AssessmentHeader };
