import { useApi } from "../../hooks/useApi";
import { useForm } from "../../hooks/useForm";
import { useSubmission } from "../../hooks/useSubmission";
import { ClinicalNotes } from "../ClinicalNotes";
import { Error } from "../Error";
import { FormRenderer } from "../FormRenderer";
import { componentMap } from "../FormRenderer/ComponentMap";
import { NotFound } from "../NotFound";
import { Spinner } from "../Spinner";
import { Tasks } from "../Tasks";

type ClinicalReportProps = {
  assessmentId: string;
  summaryFormId: string;
  fullReportId: string;
  clinicalNotesId: string;
  submissionId: string;
  encounterId: string;
};

const ClinicalReport = ({
  assessmentId,
  summaryFormId,
  fullReportId,
  clinicalNotesId,
  submissionId,
  encounterId,
}: ClinicalReportProps) => {
  const { isErrorCode } = useApi();

  const {
    formQuery: {
      status: summaryFormStatus,
      data: summaryFormData,
      error: summaryFormError,
    },
  } = useForm(summaryFormId);

  const {
    formQuery: {
      status: fullReportStatus,
      data: fullReportData,
      error: fullReportError,
    },
  } = useForm(fullReportId);

  const {
    submissionQuery: {
      status: submissionStatus,
      data: submissionData,
      error: submissionError,
    },
  } = useSubmission(submissionId);

  const isNotFoundError = () => {
    return (
      isErrorCode(summaryFormError, 404) ||
      isErrorCode(fullReportError, 404) ||
      isErrorCode(summaryFormError, 404)
    );
  };

  const isError = () => {
    return (
      summaryFormStatus === "error" ||
      fullReportStatus === "error" ||
      submissionError === "error"
    );
  };

  return (
    <>
      {isError() && !isNotFoundError() && <Error />}
      {isError() && isNotFoundError() && <NotFound />}
      {(summaryFormStatus === "loading" ||
        fullReportStatus === "loading" ||
        submissionStatus === "loading") && (
        <div className="flex justify-center items-center flex-1">
          <Spinner size="xl" />
        </div>
      )}
      {summaryFormStatus === "success" &&
        fullReportStatus === "success" &&
        submissionStatus === "success" &&
        summaryFormData &&
        fullReportData &&
        submissionData && (
          <div className="flex mt-2 space-x-4 min-h-0">
            <div className="flex flex-col w-3/12 flex-shrink-0 space-y-4 overflow-y-auto -m-0.5">
              <div className="flex flex-col shadow bg-white m-0.5 flex-shrink-0">
                <Tasks assessmentId={assessmentId} />
              </div>
              <div className="shadow bg-white flex flex-col m-0.5 flex-shrink-0">
                <div className="p-2 border-b border-gray-200 bg-gray-50 font-medium uppercase tracking-wider text-gray-500 text-xs ">
                  Clinical Notes
                </div>
                <div className="p-2">
                  <ClinicalNotes
                    assessmentId={assessmentId}
                    clinicalNotesId={clinicalNotesId}
                  ></ClinicalNotes>
                </div>
              </div>
            </div>
            <div className="overflow-auto p-2 shadow bg-white flex-1">
              <FormRenderer
                componentMap={componentMap}
                scaffold={summaryFormData.data.scaffold}
                items={summaryFormData.data.items}
                characteristics={submissionData.data.characteristics}
              ></FormRenderer>
              <FormRenderer
                componentMap={componentMap}
                scaffold={fullReportData.data.scaffold}
                items={fullReportData.data.items}
                characteristics={submissionData.data.characteristics}
              ></FormRenderer>
            </div>
          </div>
        )}
    </>
  );
};

export { ClinicalReport };
