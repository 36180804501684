import React from "react";
import { FaComments } from "react-icons/fa";
import { useEvents } from "../../hooks/useEvents";
import { Empty } from "../Empty";
import { Error } from "../Error";
import { PermissionGuard } from "../PermissionGuard";
import { Spinner } from "../Spinner";
import { TimelineForm } from "./TimelineForm";

type TimelineProps = {
  assessmentId: string;
  status?: "error" | "loading" | "success" | "idle";
};

const Timeline: React.FC<TimelineProps> = ({
  children,
  status,
  assessmentId,
}) => {
  const { addComment, addCommentMutation } = useEvents(assessmentId);

  const handleSubmit = (form: { comment: string }) => {
    addComment(form.comment);
  };

  return (
    <div className="flex flex-1 flex-col min-h-0 space-y-2">
      <h3 className="text-gray-900 text-base font-semibold">Timeline</h3>
      <PermissionGuard requiredPermissions={["write:comment"]}>
        <TimelineForm
          onSubmit={handleSubmit}
          isSuccess={addCommentMutation.isSuccess}
          isLoading={addCommentMutation.isLoading}
        />
      </PermissionGuard>
      {status === "loading" && (
        <div className="flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      )}
      {status !== "loading" && React.Children.count(children) === 0 && (
        <Empty icon={FaComments} title="Timeline is empty">
          Nothing on the timeline yet
        </Empty>
      )}
      {status === "error" && (
        <Error>An error occurred fetching timeline items</Error>
      )}
      {status !== "loading" && React.Children.count(children) > 0 && (
        <ul className="-mb-8 flex flex-col flex-grow overflow-auto ">
          {children}
        </ul>
      )}
    </div>
  );
};

export { Timeline };
