import { useField } from "formik";
import { classNames } from "../../utilities/mergeStyles";

type CheckboxItemProps = {
  label: string;
  id: string;
  name: string;
  description?: string;
  withinFormik?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const FormikCheckboxItem = (props: CheckboxItemProps) => {
  const [field] = useField(props);

  return <StyledCheckboxItem {...props} {...field} />;
};

const StyledCheckboxItem = ({
  withinFormik,
  id,
  name,
  disabled,
  description,
  label,
  ...rest
}: CheckboxItemProps): JSX.Element => {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          name={name}
          type="checkbox"
          className={classNames(
            "h-4 w-4 border-gray-300 rounded",
            disabled
              ? "text-gray-500"
              : "text-primary-500 focus:ring-primary-500"
          )}
          disabled={disabled}
          aria-describedby={description ? `${id}-description` : undefined}
          {...rest}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="font-medium text-gray-700">
          {label}
        </label>
        {description && (
          <p id={`${id}-description`} className="text-gray-500">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

const CheckboxItem = ({
  withinFormik,
  ...rest
}: CheckboxItemProps): JSX.Element => {
  return withinFormik ? (
    <FormikCheckboxItem {...rest} />
  ) : (
    <StyledCheckboxItem {...rest} />
  );
};

export { CheckboxItem };
