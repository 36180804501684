import { useTasks } from "../../hooks/useTasks";
import { Task } from "../Task/Task";

type TasksProps = {
  assessmentId: string;
};

function Tasks({ assessmentId }: TasksProps): JSX.Element {
  const {
    tasksQuery: { data, status },
  } = useTasks(assessmentId);
  return (
    <div className="flex flex-col flex-shrink-0">
      <div className="p-2 border-b border-gray-200 font-medium uppercase tracking-wider text-gray-500 text-xs bg-gray-50">
        Suggested Tasks
      </div>
      {status === "error" && (
        <div className="text-sm p-2">Error fetching tasks.</div>
      )}
      {status === "success" && data && data.length === 0 && (
        <div className="text-sm p-2">No suggested tasks</div>
      )}
      {status === "success" && data && data.length > 0 && (
        <div className="flex flex-col text-sm flex-shrink-0">
          <ul className="flex flex-col divide-y divide-gray-200 ">
            {data.map((task) => (
              <Task key={task.id} task={task} assessmentId={assessmentId} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export { Tasks };
