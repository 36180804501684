import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useBuildIdentifierTypesSchema } from "../../hooks/useBuildTenantIdentifierTypesSchema";

import { TenantIdentifierType } from "../../models/identifier-type.model";
import { makePatientInput, PatientInput } from "../../models/patient.model";
import { CountrySelect } from "../CountrySelect";
import { DateField } from "../DateField";
import { Input } from "../Input";
import { InputLabel } from "../InputLabel";
import { Spinner } from "../Spinner";

type PatientFormProps = {
  onSubmit: (patient: PatientInput) => void;
  status: "idle" | "loading" | "success" | "error";
  patient?: PatientInput;
  id: string;
  tenantIdentifierTypes: TenantIdentifierType[];
};

const PatientForm = ({
  onSubmit,
  status,
  patient,
  id,
  tenantIdentifierTypes,
}: PatientFormProps): JSX.Element => {
  const { buildTenantIdentifierTypesSchema } = useBuildIdentifierTypesSchema({
    patient,
  });

  const validationSchema = Yup.object({
    givenName: Yup.string()
      .max(30, "Must be 30 characters or less")
      .required("First name is required"),
    familyName: Yup.string()
      .max(30, "Must be 30 characters or less")
      .required("Last name is required"),
    identifiers: buildTenantIdentifierTypesSchema(),
    born: Yup.date()
      .typeError("Date is invalid")
      .min(new Date(1900, 0, 1), "Date of birth is too long ago")
      .max(new Date(), "Date of birth can't be in the future")
      .required("Date of birth is required"),
    addressLine1: Yup.string()
      .max(100, "Must be less than 100 characters")
      .required("Address line 1 is required"),
    addressLine2: Yup.string(),
    city: Yup.string(),
    county: Yup.string(),
    country: Yup.string().required("Country is required"),
    postcode: Yup.string()
      .max(10, "Must be less than 10 characters")
      .required("Postcode is required"),
    homePhone: Yup.string().nullable(),
    mobilePhone: Yup.string().nullable(),
    email: Yup.string().email("Must be a valid email address"),
  });

  type PatientFormFields = Yup.Asserts<typeof validationSchema>;

  const defaultValues = {
    givenName: patient?.givenName ?? "",
    familyName: patient?.familyName ?? "",
    identifiers: tenantIdentifierTypes.reduce<
      { value: string | null; id: string }[]
    >((acc, curr) => {
      const existing = patient?.identifier[curr.id];
      if (!existing) {
        acc.push({ id: curr.id, value: null });
      } else {
        acc.push({ id: curr.id, value: existing });
      }

      return acc;
    }, []) as never[],
    born: patient?.born ?? undefined,
    addressLine1: patient?.address?.line[0] ?? "",
    addressLine2: patient?.address?.line[1] ?? "",
    city: patient?.address?.city ?? "",
    county: patient?.address?.state ?? "",
    country: patient?.address?.country ?? "GB",
    postcode: patient?.address?.postalCode ?? "",
    homePhone: patient?.telephone ?? "",
    mobilePhone: patient?.mobile ?? "",
    email: patient?.email ?? "",
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PatientFormFields>({
    reValidateMode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const makePatient = (values: PatientFormFields) => {
    const newPatient = makePatientInput({
      givenName: values.givenName,
      familyName: values.familyName,
      email:
        values.email && values.email?.length > 0 ? values.email : undefined,
      mobile:
        values.mobilePhone && values.mobilePhone?.length > 0
          ? values.mobilePhone
          : null,
      telephone:
        values.homePhone && values.homePhone?.length > 0
          ? values.homePhone
          : null,
      born: values.born,
      address: {
        line: [
          values.addressLine1,
          ...(values.addressLine2 ? [values.addressLine2] : []),
        ],
        city: values.city,
        state: values.county,
        postalCode: values.postcode,
        country: values.country,
      },
      identifier: (values.identifiers ?? []).reduce((acc, curr) => {
        const idType = tenantIdentifierTypes.find((i) => i.id === curr.id);
        if (!idType) {
          return acc;
        }

        return {
          ...acc,
          [idType.id]: curr.value && curr.value.length > 0 ? curr.value : null,
        };
      }, {}),
    });
    return newPatient;
  };

  return (
    <>
      <div className="flex-1 min-h-0 overflow-auto relative">
        {status === "loading" && (
          <div className="absolute inset-0 h-full w-full flex flex-col justify-center items-center bg-white opacity-90 z-10">
            <Spinner size="lg"></Spinner>
            <p className="text-gray-600 text-sm mt-6">
              {patient ? "Updating patient" : "Adding new patient"}
            </p>
          </div>
        )}
        <form
          id={id}
          onSubmit={handleSubmit((values) => onSubmit(makePatient(values)))}
        >
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-3">
              Demographics
            </h3>
          </div>
          <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1 sm:grid-cols-6">
            <div className="col-span-3">
              <InputLabel htmlFor="givenName">First name*</InputLabel>
              <Input
                type="text"
                error={Boolean(errors.givenName)}
                errorMessage={errors.givenName?.message}
                aria-invalid={Boolean(errors.givenName)}
                aria-describedby={`givenName-error`}
                {...register("givenName")}
              />
            </div>
            <div className="col-span-3">
              <InputLabel htmlFor="familyName">Last name*</InputLabel>
              <Input
                type="text"
                error={Boolean(errors.familyName)}
                errorMessage={errors.familyName?.message}
                aria-invalid={Boolean(errors.familyName)}
                aria-describedby={`familyName-error`}
                {...register("familyName")}
              />
            </div>
          </div>
          <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1 sm:grid-cols-6">
            {tenantIdentifierTypes.map((idType, index) => (
              <div className="col-span-3" key={idType.id}>
                <InputLabel htmlFor="">
                  {idType.name}
                  {idType.required && "*"}
                </InputLabel>
                <Input
                  type="text"
                  error={Boolean(errors.identifiers?.[index]?.value)}
                  errorMessage={errors.identifiers?.[index]?.value?.message}
                  aria-invalid={Boolean(errors.identifiers?.[index]?.value)}
                  aria-describedby={`${idType.name}-error`}
                  // Have to cast here as TS can't infer the .value
                  {...register(`identifiers.${index}.value` as never)}
                />
                {/* Hidden input for identifier type id so we can get that back when the form is submitted */}
                <input
                  hidden
                  {...register(`identifiers.${index}.id` as never, {
                    value: idType.id as never,
                  })}
                ></input>
              </div>
            ))}
          </div>
          <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1">
            <Controller
              control={control}
              name="born"
              render={({
                field: { onChange, value },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <DateField
                    touched={isTouched}
                    error={error}
                    value={value}
                    onChange={onChange}
                    label="Date of birth*"
                  ></DateField>
                );
              }}
            />
          </div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-3">
              Contact details
            </h3>
          </div>
          <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1 sm:grid-cols-6">
            <div className="col-span-3">
              <InputLabel htmlFor="mobilePhone">Mobile phone</InputLabel>
              <Input
                type="tel"
                error={Boolean(errors.mobilePhone)}
                errorMessage={errors.mobilePhone?.message}
                aria-invalid={Boolean(errors.mobilePhone)}
                aria-describedby={`mobilePhone-error`}
                {...register("mobilePhone")}
              />
            </div>
            <div className="col-span-3">
              <InputLabel htmlFor="homePhone">Home phone</InputLabel>
              <Input
                type="tel"
                error={Boolean(errors.homePhone)}
                errorMessage={errors.homePhone?.message}
                aria-invalid={Boolean(errors.homePhone)}
                aria-describedby={`homePhone-error`}
                {...register("homePhone")}
              />
            </div>
          </div>
          <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1 sm:grid-cols-6">
            <div className="col-span-6">
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                type="email"
                error={Boolean(errors.email)}
                errorMessage={errors.email?.message}
                aria-invalid={Boolean(errors.email)}
                aria-describedby={`email-error`}
                {...register("email")}
              />
            </div>
          </div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-3">
              Address
            </h3>
          </div>
          <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1 sm:grid-cols-6">
            <div className="col-span-6">
              <Controller
                control={control}
                name="country"
                render={({ field: { onChange, value } }) => (
                  <CountrySelect value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
          <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1 sm:grid-cols-6">
            <div className="col-span-6">
              <InputLabel htmlFor="addressLine1">Address line 1*</InputLabel>
              <Input
                type="text"
                error={Boolean(errors.addressLine1)}
                errorMessage={errors.addressLine1?.message}
                aria-invalid={Boolean(errors.addressLine1)}
                aria-describedby={`addressLine1-error`}
                {...register("addressLine1")}
              />
            </div>
            <div className="col-span-6">
              <InputLabel htmlFor="addressLine2">Address line 2</InputLabel>
              <Input
                type="text"
                error={Boolean(errors.addressLine2)}
                errorMessage={errors.addressLine2?.message}
                aria-invalid={Boolean(errors.addressLine2)}
                aria-describedby={`addressLine2-error`}
                {...register("addressLine2")}
              />
            </div>
            <div className="col-span-2">
              <InputLabel htmlFor="city">City</InputLabel>
              <Input
                type="text"
                error={Boolean(errors.city)}
                errorMessage={errors.city?.message}
                aria-invalid={Boolean(errors.city)}
                aria-describedby={`city-error`}
                {...register("city")}
              />
            </div>
            <div className="col-span-2">
              <InputLabel htmlFor="county">County</InputLabel>
              <Input
                type="text"
                error={Boolean(errors.county)}
                errorMessage={errors.county?.message}
                aria-invalid={Boolean(errors.county)}
                aria-describedby={`county-error`}
                {...register("county")}
              />
            </div>
            <div className="col-span-2">
              <InputLabel htmlFor="postcode">Postcode*</InputLabel>
              <Input
                type="text"
                error={Boolean(errors.postcode)}
                errorMessage={errors.postcode?.message}
                aria-invalid={Boolean(errors.postcode)}
                aria-describedby={`postcode-error`}
                {...register("postcode")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export { PatientForm };
