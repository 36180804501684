import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";
import { useFormRenderer } from "../../context/FormRendererProvider";
import { AnswerOption, FlatSetting } from "../../models/form.model";
import { Characteristics } from "../../models/submission.model";
import { Checkbox as CheckboxGroup } from "../Checkbox";
import { CheckboxItem } from "../CheckboxItem";

type CheckboxValue = {
  value: string[];
  characteristics: Characteristics;
};

interface CheckboxProps {
  control: Control<FieldValues>;
  name: string;
  value: CheckboxValue;
  settings: FlatSetting;
  characteristics: Characteristics;
  options: AnswerOption[];
  editing: boolean;
  onChange?: (value: unknown) => void;
}

const ControlledCheckbox: React.FC<CheckboxProps> = ({
  value,
  settings,
  name,
  options,
  characteristics,
  editing = false,
  onChange,
}) => {
  const { updateCharacteristics } = useFormRenderer();

  const getValueFromCharacteristics = React.useCallback(
    (options: AnswerOption[], characteristics: Characteristics) => {
      return options
        ? options
            .filter((option) =>
              option.private.characteristics.some(
                (characteristic) =>
                  characteristics[characteristic]?.value === true
              )
            )
            .map((option) => option.value)
        : [];
    },
    []
  );

  const getCharacteristicsFromValue = React.useCallback(
    (options: AnswerOption[], value: string[]): Characteristics => {
      return options.reduce<Characteristics>((acc, curr) => {
        const newChars = curr.private.characteristics
          .map((char) => ({
            [char]: { value: value.some((v) => v === curr.value) },
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {});
        return { ...acc, ...newChars };
      }, {});
    },
    []
  );

  const [checkboxValue, setCheckboxValue] = React.useState<CheckboxValue>(
    () =>
      value ?? {
        value: getValueFromCharacteristics(options, characteristics),
        characteristics: getCharacteristicsFromValue(
          options,
          getValueFromCharacteristics(options, characteristics) ?? ""
        ),
      }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value;
    setCheckboxValue((old) => {
      const newValue = old.value.includes(currentValue)
        ? old.value.filter((v) => v !== currentValue)
        : [...old.value, currentValue];

      const newCharacteristics = getCharacteristicsFromValue(options, newValue);

      return {
        value: newValue,
        characteristics: newCharacteristics,
      };
    });
  };

  React.useEffect(() => {
    const newCharacteristics = getCharacteristicsFromValue(
      options,
      checkboxValue.value
    );
    updateCharacteristics(newCharacteristics);
  }, [
    checkboxValue,
    getCharacteristicsFromValue,
    options,
    updateCharacteristics,
  ]);

  React.useEffect(() => {
    if (onChange) {
      onChange(checkboxValue);
    }
  }, [checkboxValue, onChange, updateCharacteristics]);

  return (
    <div className="mb-2">
      <CheckboxGroup label={(settings.label?.value as string) ?? ""} showLabel>
        {options.map((option) => (
          <CheckboxItem
            key={option.value}
            id={option.value}
            name={name}
            label={option.value}
            value={option.value}
            disabled={!editing}
            onChange={handleChange}
            checked={checkboxValue.value.includes(option.value)}
          />
        ))}
      </CheckboxGroup>
    </div>
  );
};

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { name, control } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ControlledCheckbox
          {...props}
          value={value}
          onChange={onChange}
          name={name}
        />
      )}
    />
  );
};

export { Checkbox };
