import { classNames } from "../../utilities/mergeStyles";

const PageBody: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={classNames("flex flex-col flex-1 min-h-0", className)}
      {...props}
    />
  );
};

export { PageBody };
