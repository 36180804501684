import { Modal } from "../Modal";
import { ModalContent } from "../ModalContent";
import { ModalActions } from "../ModalActions";
import { Button } from "../Button";
import { Patient, PatientInput } from "../../models/patient.model";
import { PatientForm } from "../PatientForm";
import { usePatients } from "../../hooks/usePatients";
import { useTenant } from "../../context/TenantContext";

type EditPatientModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  patient: Patient;
};

const EditPatientModal = ({
  isOpen,
  setIsOpen,
  patient,
}: EditPatientModalProps): JSX.Element => {
  const {
    updatePatientMutation: {
      mutate: updatePatientMutate,
      status: updatePatientStatus,
    },
  } = usePatients();
  const { tenant } = useTenant();

  const close = () => {
    setIsOpen(false);
  };

  const updatePatient = (values: PatientInput) => {
    updatePatientMutate(
      { patient: values, patientId: patient.id },
      {
        onSuccess: () => {
          close();
        },
      }
    );
    return;
  };

  return (
    <>
      {isOpen && (
        <Modal
          title="Update patient"
          isOpen={isOpen}
          isDismissable={false}
          onClose={() => setIsOpen(false)}
          fixedHeight
        >
          <ModalContent>
            <div className="flex flex-col flex-1 min-h-0">
              <PatientForm
                onSubmit={updatePatient}
                status={updatePatientStatus}
                patient={patient}
                tenantIdentifierTypes={tenant?.identifierTypes ?? []}
                id="updatePatientForm"
              />
            </div>
          </ModalContent>
          <ModalActions>
            <Button
              as="button"
              type="button"
              color="secondary"
              variant="outlined"
              className="shadow-none"
              disabled={updatePatientStatus === "loading"}
              onClick={() => close()}
            >
              Cancel
            </Button>
            <Button
              as="button"
              type="submit"
              color="primary"
              form="updatePatientForm"
              loading={updatePatientStatus === "loading"}
            >
              Save patient
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

export { EditPatientModal };
