import { classNames } from "../../utilities/mergeStyles";

type CheckboxProps = {
  label: string;
  showLabel?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  label,
  showLabel = false,
}): JSX.Element => {
  return (
    <fieldset className="space-y-5">
      <legend className={classNames(!showLabel && "sr-only")}>{label}</legend>
      {children}
    </fieldset>
  );
};

export { Checkbox };
