import { Spinner } from "../Spinner";

function FullscreenSpinner(): JSX.Element {
  return (
    <div className="bg-primary-400 h-screen w-screen flex items-center justify-center">
      <Spinner color="white" size="xl" />
    </div>
  );
}

export { FullscreenSpinner };
