import { NavLink as Link } from "react-router-dom";
import { classNames } from "../../utilities/mergeStyles";

interface NavLinkProps {
  label: string;
  icon: React.ElementType;
  to: string;
  exact?: boolean;
  collapsed?: boolean;
}

function NavLink({
  icon,
  label,
  to,
  exact,
  collapsed = false,
}: NavLinkProps): JSX.Element {
  const Icon = icon;
  return (
    <li className="flex items-center">
      <Link
        activeClassName="bg-primary-600 hover:bg-primary-600"
        to={to}
        exact={exact}
        title={label}
        className={classNames(
          "flex flex-auto items-center font-normal text-sm text-white px-2.5 py-2 hover:bg-primary-500 rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-1 focus-visible:ring-offset-primary-400"
        )}
      >
        <Icon
          className={classNames(
            "h-6 w-6 text-primary-100",
            collapsed || "mr-3"
          )}
          aria-hidden="true"
        />
        <span className={classNames(collapsed && "sr-only")}>{label}</span>
      </Link>
    </li>
  );
}

export { NavLink };
