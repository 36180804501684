import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";
import { FlatSetting } from "../../models/form.model";
import { Characteristics } from "../../models/submission.model";
import { classNames } from "../../utilities/mergeStyles";

type TextAreaValue = {
  value: string | number | undefined;
  characteristics: Characteristics;
};

interface TextAreaProps {
  control: Control<FieldValues>;
  settings: FlatSetting;
  onChange?: (value: unknown) => void;
  name: string;
  editing?: boolean;
  characteristics: Characteristics;
  answerCharacteristics?: string[];
  value: TextAreaValue;
}

const ControlledTextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      settings,
      onChange,
      name,
      editing,
      characteristics,
      answerCharacteristics,
      value,
    },
    ref
  ) => {
    const generateCharacteristics = (
      value: string | number | undefined
    ): Characteristics =>
      answerCharacteristics?.reduce<Characteristics>(
        (acc, curr) => ({
          ...acc,
          ...(value ? { [curr]: { value } } : {}),
        }),
        {}
      ) ?? {};

    const defaultValue = React.useMemo(
      () =>
        answerCharacteristics?.reduce<string | number>((acc, curr) => {
          const charValue = characteristics[curr];
          const result =
            charValue && charValue.value && typeof charValue.value !== "object"
              ? charValue.value
              : acc;

          return typeof result === "number" ? result : result.toString();
        }, ""),
      [answerCharacteristics, characteristics]
    );

    const [fieldState, setFieldState] = React.useState<TextAreaValue>(
      () =>
        value ?? {
          value: defaultValue,
          characteristics: generateCharacteristics(defaultValue),
        }
    );

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFieldState({
        value: e.currentTarget.value,
        characteristics: generateCharacteristics(e.currentTarget.value),
      });
    };

    React.useEffect(() => {
      if (onChange) {
        onChange(fieldState);
      }
    }, [fieldState, onChange]);

    // Clear the field value if the field is unmounted.
    React.useEffect(() => {
      return () => {
        if (onChange) {
          onChange("");
        }
      };
    }, [onChange]);

    return (
      <div className="flex flex-col mb-2">
        <div>
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {settings.label?.value}
          </label>
        </div>
        <div>
          <textarea
            ref={ref}
            id={name}
            disabled={!editing}
            className={classNames(
              "shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-300 rounded-md",
              !editing && "bg-gray-100"
            )}
            onChange={handleChange}
            value={fieldState.value}
          />
        </div>
      </div>
    );
  }
);

const TextArea: React.FC<TextAreaProps> = (props) => {
  const { name, control } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <ControlledTextArea
          {...props}
          value={value}
          onChange={onChange}
          ref={ref}
        />
      )}
    />
  );
};

export { TextArea };
