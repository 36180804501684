import { IdentifierType } from "../models/identifier-type.model";
import { validateNhsNumber } from "./nhsNumber";
import { StringMask } from "./string-mask";

const formatIdentifier = (
  value: string,
  identifier: IdentifierType
): string => {
  if (!identifier.mask) {
    return value;
  }

  return new StringMask(identifier.mask).apply(value);
};

const validateIdentifier = (
  value: string,
  identifier: IdentifierType
): boolean => {
  if (identifier.code === "NH" && identifier.authority === "NHS") {
    return validateNhsNumber(value);
  }

  if (identifier.code === "NH" && identifier.authority === "NHS Scotland") {
    return validateNhsNumber(value);
  }

  return true;
};

export { formatIdentifier, validateIdentifier };
