import * as React from "react";
import { Permissions, usePermissions } from "../../hooks/usePermissions";
import { Error } from "../Error";

type PermissionGuardProps = {
  requiredPermissions: Permissions[];
  fallback?: boolean;
};

const PermissionGuard: React.FC<PermissionGuardProps> = ({
  children,
  requiredPermissions,
  fallback = false,
}) => {
  const { hasPermissions } = usePermissions();

  return (
    <>
      {hasPermissions(requiredPermissions) ? (
        children
      ) : fallback ? (
        <Error>You don't have the permission to view this page.</Error>
      ) : null}
    </>
  );
};

export { PermissionGuard };
