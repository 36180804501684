import React, { isValidElement, useCallback, useEffect, useRef } from "react";

type ClickAwayListenerProps = {
  onClickAway: (e: Event) => void;
  children: React.ReactChild;
};

const ClickAwayListener = ({
  onClickAway,
  children,
}: ClickAwayListenerProps): JSX.Element | null => {
  const child = React.Children.only(children);

  const childRef = useRef<HTMLElement | null>(null);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClickAway(e);
      }
    },
    [onClickAway]
  );

  const clickListener = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!childRef.current?.contains(e.target as Node)) {
        onClickAway(e);
      }
    },
    [onClickAway]
  );

  const focusListener = useCallback(
    (e: FocusEvent) => {
      if (!childRef.current?.contains(e.target as Node)) {
        onClickAway(e);
      }
    },
    [onClickAway]
  );

  useEffect(() => {
    document.addEventListener("mousedown", clickListener);
    document.addEventListener("touchstart", clickListener);
    document.addEventListener("keyup", escapeListener);
    document.addEventListener("focusin", focusListener);
    return () => {
      document.removeEventListener("mousedown", clickListener);
      document.removeEventListener("touchstart", clickListener);
      document.removeEventListener("keyup", escapeListener);
      document.removeEventListener("focusin", focusListener);
    };
  }, [clickListener, escapeListener, focusListener]);

  return isValidElement(child)
    ? React.cloneElement(child, {
        ref: childRef,
      })
    : null;
};

export { ClickAwayListener };
