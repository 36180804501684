import { FaChevronRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { classNames } from "../../utilities/mergeStyles";

type BreadcrumbItemProps = {
  first?: boolean;
  children: string;
  href: string;
};

function BreadcrumbItem({
  first,
  children,
  href,
}: BreadcrumbItemProps): JSX.Element {
  return (
    <li>
      <div className="flex items-center">
        {first || (
          <FaChevronRight
            className="flex-shrink-0 h-3 w-3 text-gray-400"
            aria-hidden="true"
          />
        )}
        <NavLink
          exact
          to={href}
          className={classNames(
            first || "ml-2",
            "text-sm text-gray-500 hover:text-gray-700"
          )}
        >
          {children}
        </NavLink>
      </div>
    </li>
  );
}

export { BreadcrumbItem };
