import { Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { AuthenticatedApp } from "./pages/AuthenticatedApp";
import Login from "./pages/Login/Login";

function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>
      <ProtectedRoute path="/" component={AuthenticatedApp} />
    </Switch>
  );
}

export { Routes };
