import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { usePatient } from "../../hooks/usePatient";
import { formatDate } from "../../utilities/cuiFormat";
import { AsaGrade } from "../AsaGrade";
import { AssignConsultant } from "../AssignConsultant";
import { AssignTag } from "../AssignTag";
import { AssignUser } from "../AssignUser";
import { Empty } from "../Empty";
import { Error } from "../Error";
import { Priority } from "../Priority";
import { Spinner } from "../Spinner";
import { TciDate } from "../TciDate";

type PatientAssessmentsProps = {
  patientId: string;
};

function PatientAssessments({ patientId }: PatientAssessmentsProps) {
  const {
    patientAssessmentsQuery: { data: assessments, status },
  } = usePatient(patientId);

  return (
    <>
      {status === "loading" && (
        <div className="flex flex-1 justify-center items-center">
          <Spinner size="lg" />
        </div>
      )}
      {status === "success" && assessments?.length === 0 && (
        <Empty>This patient has no assessments</Empty>
      )}
      {status === "error" && <Error />}
      {assessments && assessments.length > 0 && status === "success" && (
        <>
          <h3 className="font-semibold text-lg mb-2 text-gray-900">
            Assessments
          </h3>
          <div className="bg-white shadow overflow-hidden  flex flex-col">
            <ul className="divide-y divide-gray-200 flex flex-col overflow-auto ">
              {assessments.map((assessment) => (
                <li key={assessment.id}>
                  <Link to={`/assessments/${assessment.id}`}>
                    <div className="px-4 py-4 flex items-center justify-between">
                      <div className="flex flex-col space-y-2 flex-1">
                        <div className="flex text-sm">
                          <p className="font-medium text-gray-900 truncate">
                            Assessment in {assessment.service.name}
                          </p>
                        </div>
                        <div className="flex items-center -ml-2">
                          <AssignConsultant
                            assessmentId={assessment.id}
                            consultant={assessment.consultant}
                            disabled
                          />
                          <AssignUser
                            assessmentId={assessment.id}
                            user={assessment.assignedUser}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 space-y-2 flex-1">
                        <div className="flex justify-end space-x-2">
                          <TciDate
                            assessmentId={assessment.id}
                            tciDate={assessment.tciDate ?? undefined}
                            disabled
                          />
                          <Priority
                            assessmentId={assessment.id}
                            priority={assessment.priority}
                            disabled
                          />
                          <AsaGrade
                            assessmentId={assessment.id}
                            asaGrade={assessment.asaGrade}
                            disabled
                          />
                          <AssignTag
                            assessmentId={assessment.id}
                            assessmentStatus={assessment.status}
                            tag={assessment.tag}
                            disabled
                          />
                        </div>
                        <div className="flex flex-col items-end">
                          <p className="text-sm font-normal text-gray-600 space-x-2">
                            <span>
                              Updated {formatDate(assessment.updated)}
                            </span>
                            <span>
                              - Created {formatDate(assessment.created)}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="ml-5 flex-shrink-0">
                        <FaChevronRight
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export { PatientAssessments };
