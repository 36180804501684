import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../Button";
import { InputLabel } from "../InputLabel";
import { TextArea } from "../TextArea";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type TimelineFormProps = {
  status?: "error" | "loading" | "success" | "idle";
  onSubmit?: (form: { comment: string }) => void;
  isSuccess?: boolean;
  isLoading?: boolean;
};

const schema = yup.object({
  comment: yup.string().max(500).required(),
});

const TimelineForm: React.FC<TimelineFormProps> = ({
  onSubmit,
  isSuccess,
  isLoading,
}) => {
  const formRef = React.useRef<HTMLFormElement>(null);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { comment: "" },
    resolver: yupResolver(schema),
  });

  const watchComment = watch("comment");

  const localHandleSubmit = (form: { comment: string }) => {
    onSubmit && onSubmit(form);
  };

  React.useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.ctrlKey) {
      handleSubmit(localHandleSubmit)();
    }
  };

  return (
    <div className="bg-white overflow-hidden shadow flex-shrink-0">
      <div className="p-4">
        <form onSubmit={handleSubmit(localHandleSubmit)} ref={formRef}>
          <div className="flex flex-1 flex-col">
            <div className="w-full">
              <InputLabel htmlFor="comment">Add comment</InputLabel>
              <TextArea
                {...register("comment")}
                id="comment"
                cols={20}
                className="resize-none"
                maxLength={500}
                currentLength={watchComment?.length ?? 0}
                placeholder="Add a comment to the timeline"
                error={errors.comment !== undefined}
                errorMessage={errors.comment && "Comment is required"}
                disabled={isLoading}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="flex justify-end">
              <Button
                as="button"
                type="submit"
                color="primary"
                size="sm"
                loading={isLoading}
              >
                Add comment
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { TimelineForm };
