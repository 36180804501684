import { useClinicalSubmission } from "../../hooks/useClinicalSubmission";
import { useForm } from "../../hooks/useForm";
import { SubmissionUpdate } from "../../models/submission.model";
import { FormRenderer } from "../FormRenderer";
import { componentMap } from "../FormRenderer/ComponentMap";
import { Spinner } from "../Spinner";

type ClinicalNotesProps = {
  assessmentId: string;
  clinicalNotesId: string;
};

const ClinicalNotes = ({
  assessmentId,
  clinicalNotesId,
}: ClinicalNotesProps) => {
  const {
    formQuery: { status: formStatus, data: formData },
  } = useForm(clinicalNotesId);

  const {
    clinicalSubmissionQuery: { status: submissionStatus, data: submissionData },
    updateClinicalSubmissionMutation,
  } = useClinicalSubmission(assessmentId);

  return (
    <>
      {(formStatus === "error" || submissionStatus === "error") && (
        <span className="text-sm">
          Unable to load clinical notes. Please contact Ultramed.
        </span>
      )}
      {submissionStatus === "loading" && (
        <div className="flex justify-center items-center flex-1">
          <Spinner size="lg"></Spinner>
        </div>
      )}
      {formStatus === "success" && formData && submissionData && (
        <FormRenderer
          componentMap={componentMap}
          scaffold={formData.data.scaffold}
          items={formData.data.items}
          characteristics={submissionData.characteristics}
          isForm
          onSubmit={(data: SubmissionUpdate) => {
            updateClinicalSubmissionMutation.mutate({
              id: submissionData.id,
              submission: data,
            });
          }}
          loading={updateClinicalSubmissionMutation.status === "loading"}
        ></FormRenderer>
      )}
    </>
  );
};

export { ClinicalNotes };
