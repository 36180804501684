/* eslint-disable no-console */
import React from "react";
import type { ComboBoxProps } from "@react-types/combobox";
import { useComboBoxState, useSearchFieldState } from "react-stately";
import {
  useComboBox,
  useButton,
  useSearchField,
  useOverlayPosition,
  mergeProps,
} from "react-aria";
import { Item } from "@react-stately/collections";
import { SearchFieldProps } from "@react-types/searchfield";
import { FaSearch, FaTimes } from "react-icons/fa";
import { Popover } from "../Popover";
import { ListBox } from "../ListBox";
import { Spinner } from "../Spinner";
import { usePatients } from "../../hooks/usePatients";
import { formatBorn, formatName } from "../../utilities/cuiFormat";
import { useHistory } from "react-router";
import { PatientIdentifier } from "../PatientIdentifier";

function PatientSearchComboBox<T extends Record<string, unknown>>({
  status,
  ...props
}: { status: "loading" | "idle" | "success" | "error" } & ComboBoxProps<T>) {
  const state = useComboBoxState({
    ...props,
    menuTrigger: "input",
    allowsEmptyCollection: true,
    onSelectionChange: (e) => {
      state.setInputValue("");
      if (props.onSelectionChange) {
        props.onSelectionChange(e);
      }
    },
  });

  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  // Get props for the clear button from useSearchField
  const searchProps: SearchFieldProps = {
    label: props.label,
    value: state.inputValue,
    onChange: (v: string) => state.setInputValue(v),
    onSubmit: (e) => state.setSelectedKey(null as unknown as string),
  };

  const searchState = useSearchFieldState({
    ...searchProps,
  });

  const { clearButtonProps, inputProps: inputSearchProps } = useSearchField(
    searchProps,
    searchState,
    inputRef
  );

  const clearButtonRef = React.useRef(null);

  const { buttonProps } = useButton(clearButtonProps, clearButtonRef);

  // Get popover positioning props relative to the trigger
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: inputRef,
    overlayRef: popoverRef,
    placement: "bottom start",
    offset: 0,
    isOpen: state.isOpen,
  });

  React.useEffect(() => {
    const onCommandK = (e: KeyboardEvent) => {
      if (e.key === "k" && e.metaKey) {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };
    window.addEventListener("keydown", onCommandK, false);
    return () => {
      window.addEventListener("keydown", onCommandK, false);
    };
  }, []);

  return (
    <div className="flex-shrink-0 flex h-12 bg-white shadow relative">
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex">
          <label htmlFor="search-field" className="sr-only" {...labelProps}>
            Search
          </label>
          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <FaSearch className="h-4 w-4" aria-hidden="true" />
            </div>
            <input
              className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
              {...mergeProps(inputProps, inputSearchProps)}
              placeholder="Search for a patient"
              id="patientSearch"
              name="patientSearch"
              type="text"
              ref={inputRef}
            />
          </div>
          {!state.isFocused && (
            <div className="flex justify-center items-center">
              <span className="text-gray-400 text-sm leading-5 py-0.5 px-1.5 border border-gray-300 rounded-md">
                ⌘K
              </span>
            </div>
          )}
          {status === "loading" && (
            <div className="flex justify-center items-center mr-2">
              <Spinner size="md" />
            </div>
          )}
          <div className="flex justify-center items-center">
            <button
              {...buttonProps}
              ref={clearButtonRef}
              style={{
                visibility: state.inputValue !== "" ? "visible" : "hidden",
              }}
              className="text-gray-500 hover:text-gray-600"
            >
              <FaTimes className="w-4 h-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      {state.isOpen && (
        <Popover
          ref={popoverRef}
          {...positionProps}
          isOpen={state.isOpen}
          onClose={() => {
            state.setInputValue("");
            state.close();
          }}
          className="flex-1 w-full left-0 max-h-96"
        >
          <div className="flex min-h-0">
            {state.inputValue.length === 0 && state.collection.size === 0 && (
              <div className="p-10 text-gray-700 text-sm">
                Tip: You can search by name, NHS number or hospital number.
              </div>
            )}
            {state.collection.size === 0 &&
              status === "success" &&
              state.inputValue.length > 0 && (
                <div className="p-10 text-gray-700 text-sm">
                  No results found.
                </div>
              )}
            {state.collection.size > 0 && status === "success" && (
              <ListBox
                {...listBoxProps}
                listBoxRef={listBoxRef}
                state={state}
              />
            )}
          </div>
        </Popover>
      )}
    </div>
  );
}

const GlobalPatientSearch = () => {
  const {
    searchPatientsQuery: { data: patients, status, isFetching },
    setPatientSearchState,
    patientSearchState,
    debouncedSearchInput,
  } = usePatients();

  const history = useHistory();

  const [isTyping, setIsTyping] = React.useState(false);

  React.useEffect(() => {
    setIsTyping(false);
  }, [debouncedSearchInput]);

  React.useEffect(() => {
    if (patientSearchState) {
      setIsTyping(true);
    }
  }, [patientSearchState]);

  return (
    <PatientSearchComboBox
      label="Patient search"
      onSelectionChange={(e) => {
        if (e) {
          history.replace(`/patients/${e}`);
        }
      }}
      onInputChange={setPatientSearchState}
      inputValue={patientSearchState}
      items={patients ?? []}
      status={isFetching || isTyping ? "loading" : status}
    >
      {(patient) => (
        <Item
          key={patient.id as string}
          textValue={formatName(
            patient.givenName ?? "",
            patient.familyName ?? "",
            patient.prefix
          )}
        >
          <div className="p-2 flex flex-col">
            <span className="font-medium">
              {formatName(
                patient.givenName ?? "",
                patient.familyName ?? "",
                patient.prefix
              )}
            </span>
            <span className="text-xs">Born {formatBorn(patient.born)}</span>
            {console.log("patient in global patient search", patient)}
            {Object.entries(patient.identifier).map(([key, value]) => {
              return (
                <PatientIdentifier
                  className="text-xs"
                  identifierValue={value}
                  identifierId={key}
                ></PatientIdentifier>
              );
            })}
          </div>
        </Item>
      )}
    </PatientSearchComboBox>
  );
};

export { GlobalPatientSearch };
