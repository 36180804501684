import { Item } from "@react-stately/collections";
import { FaStethoscope } from "react-icons/fa";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { useUsers } from "../../hooks/useUsers";
import { User } from "../../models/user.model";
import { formatName } from "../../utilities/cuiFormat";
import { AssessmentHeaderDropdown } from "../AssessmentHeaderDropdown";
import { FilterBox } from "../FilterBox";

type AssignConsultantProps = {
  assessmentId: string;
  consultant?: User | null;
  disabled?: boolean;
};

function AssignConsultant({
  assessmentId,
  consultant,
  disabled,
}: AssignConsultantProps) {
  const {
    consultantsQuery: { data, status },
  } = useUsers();

  const { updateConsultant } = useAssessmentMutations(assessmentId);

  const handleChange = (value: string) => {
    const newConsultant = data?.find((consultant) => consultant.id === value);
    if (newConsultant) {
      updateConsultant(newConsultant);
    }
  };

  const handleClear = () => {
    updateConsultant(null);
  };

  const formatConsultantName = (consultant: User) =>
    formatName(
      consultant.givenName ?? "",
      consultant.familyName ?? "",
      consultant.prefix
    );

  return (
    <FilterBox
      loading={status === "loading"}
      button={
        <AssessmentHeaderDropdown disabled={disabled} icon={<FaStethoscope />}>
          {consultant
            ? formatName(
                consultant.givenName ?? "",
                consultant.familyName ?? "",
                consultant.prefix
              )
            : disabled
            ? "No consultant"
            : "Assign consultant"}
        </AssessmentHeaderDropdown>
      }
      label="Assigned consultant"
      items={data ?? []}
      onChange={(value) => value && handleChange(value.toString())}
      selectedKey={consultant?.id}
      disabled={disabled}
      clearable
      onClear={() => handleClear()}
    >
      {(item) => <Item>{formatConsultantName(item)}</Item>}
    </FilterBox>
  );
}

export { AssignConsultant };
