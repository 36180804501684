import { FaFrown } from "react-icons/fa";

type ErrorProps = {
  children?: React.ReactNode;
};

function Error({ children }: ErrorProps): JSX.Element {
  return (
    <div className="flex justify-center items-center flex-1 flex-col">
      <FaFrown className="h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        Something went wrong
      </h3>
      <div className="mt-1 text-sm text-gray-500">
        {!children && (
          <>
            Ultramed has been notified. If this problem persists, please get in
            touch via{" "}
            <a
              href="https://support.ultramed.co"
              target="_blank"
              rel="noreferrer"
              className="text-primary-500"
            >
              support
            </a>
            .
          </>
        )}
        {children}
      </div>
    </div>
  );
}

export { Error };
