import { Characteristics } from "../models/submission.model";

/**
 * Builds an object of characteristics based on an array of characteristic IDs
 * and the value provided.
 */
const generateCharacteristics = (
  value: string | number | undefined | null,
  characteristics: string[]
): Characteristics =>
  characteristics?.reduce<Characteristics>(
    (acc, curr) => ({
      ...acc,
      ...(value !== undefined ? { [curr]: { value } } : {}),
    }),
    {}
  ) ?? {};

export { generateCharacteristics };
