import React from "react";
import { Characteristics, Operation } from "../../models/submission.model";

interface OperationsListProps {
  characteristics: Characteristics;
}

const OperationsList: React.FC<OperationsListProps> = ({ characteristics }) => {
  const operations = (
    characteristics["patients_operations_list"]?.value as
      | Operation[]
      | undefined
  )?.filter((operation) => Boolean(operation));

  return (
    <>
      {operations && operations.length > 0 ? (
        <>
          <tr>
            <td>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-left">Operations</th>
                    <th className="p-2 text-left">Year</th>
                  </tr>
                </thead>
                <tbody>
                  {operations.map((operation, index) => (
                    <tr key={index}>
                      <td className="p-2">{operation.userText}</td>
                      <td className="p-2">{operation.year}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td className="p-2">No operations recorded</td>
        </tr>
      )}
    </>
  );
};

export { OperationsList };
