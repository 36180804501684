import { useDocumentPoll } from "../../hooks/useDocumentPoll";
import { Button } from "../Button";

type PollingButtonProps = {
  assessmentId: string;
  documentId: string;
};

function PollingButton({ assessmentId, documentId }: PollingButtonProps) {
  useDocumentPoll({ assessmentId, documentId });
  return (
    <Button
      loading
      disabled
      as="button"
      variant="text"
      flat
      className="cursor-default w-24 text-primary-500"
    ></Button>
  );
}

export { PollingButton };
