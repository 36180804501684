import { classNames } from "../../utilities/mergeStyles";
import { Error } from "../Error";
import { Spinner } from "../Spinner";

type PageContainerProps = {
  status?: "idle" | "loading" | "success" | "error";
} & React.HTMLAttributes<HTMLDivElement>;

const PageContainer: React.FC<PageContainerProps> = ({
  status,
  className,
  ...props
}) => {
  if (status === "loading") {
    return (
      <div className="flex justify-center items-center flex-1">
        <Spinner size="xl" />
      </div>
    );
  }

  if (status === "error") {
    return <Error />;
  }

  return (
    <div
      className={classNames(
        "flex flex-col flex-auto p-6 h-full min-h-0",
        className
      )}
      {...props}
    />
  );
};

export { PageContainer };
