/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Response } from "../models/response.model";
import { Filter } from "../models/apiquery.model";
import { useApi } from "./useApi";
import { User, UserInput, UserUpdate } from "../models/user.model";
import { filterQuery } from "../utilities/querystring";
import { useToasts } from "../context/ToastProvider";

const useUsers = () => {
  const { fetchWithToken } = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const sortUsers = (users: User[]): User[] => {
    return users.sort((a, b) =>
      (a.familyName?.toLowerCase() ?? "z") >
      (b.familyName?.toLocaleLowerCase() ?? "z")
        ? 1
        : -1
    );
  };

  const getUsers = async (params?: {
    filter?: Filter<User>;
    signal?: AbortSignal;
  }) => {
    const { filter } = params ?? {};
    const filterQueryObj = filter ? filterQuery<User>(filter) : undefined;
    return fetchWithToken<Response<User[]>>("/users", {
      query: {
        ...filterQueryObj,
      },
      signal: params?.signal,
    }).then((users) => ({
      ...users,
      data: sortUsers(users.data),
    }));
  };

  const usersQuery = useQuery<User[], Error>(
    ["users"],
    ({ signal }) => getUsers({ signal }).then((data) => data.data),
    {
      staleTime: 1000 * 60 * 5, // Stale after 5 minutes
    }
  );

  const consultantsQuery = useQuery(
    ["consultants"],
    () =>
      getUsers({
        filter: [
          {
            filterBy: "isConsultant",
            value: true,
          },
        ],
      }),
    {
      select: (result) => result.data,
      staleTime: 1000 * 60 * 5, // Stale after 5 minutes
    }
  );

  const assignableUsersQuery = useQuery(
    ["assignableUsers"],
    () =>
      getUsers({
        filter: [
          {
            filterBy: "isAssignable",
            value: true,
          },
        ],
      }),
    {
      select: (result) => result.data,
      staleTime: 1000 * 60 * 5, // Stale after 5 minutes
    }
  );

  const createUser = async ({ user }: { user: UserInput }) => {
    return fetchWithToken<User>("/users", {
      method: "POST",
      body: user,
    });
  };

  const createUserMutation = useMutation(createUser, {
    onError: (err, { user }, context) => {
      addToast({
        type: "error",
        content: "Error adding user. Please try again",
      });
      queryClient.setQueryData("users", context);
    },
    onSettled: () => {
      queryClient.invalidateQueries("users");
    },
    onSuccess: async (user) => {
      await queryClient.cancelQueries(["users"]);
      queryClient.setQueryData<User[]>("users", (old) => {
        return old && old ? [...old, user] : [user];
      });

      addToast({ type: "message", content: "User successfully added" });
    },
  });

  const updateUser = ({ user }: { user: UserUpdate }) => {
    return fetchWithToken<User>(`/users/${user.id}`, {
      method: "PATCH",
      body: user,
    });
  };

  const updateUserMutation = useMutation(updateUser, {
    onMutate: async ({ user }) => {
      await queryClient.cancelQueries("users");

      const previousValue = queryClient.getQueryData<User[]>("users") ?? [];

      queryClient.setQueryData<User[]>("users", (old) => {
        return (
          old?.map((oldUser) => {
            return oldUser.id === user.id ? { ...oldUser, ...user } : oldUser;
          }) ?? []
        );
      });

      return previousValue;
    },
    onError: (err, updatedUser, context) => {
      addToast({
        type: "error",
        content: "Error updating user. Please try again",
      });
      queryClient.setQueryData("users", context);
    },
    onSuccess: () => {
      addToast({ type: "message", content: "User successfully updated" });
    },
    onSettled: (user) => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries(["userRoles", user?.authId]);
    },
  });

  return {
    consultantsQuery,
    usersQuery,
    assignableUsersQuery,
    createUserMutation,
    updateUserMutation,
  };
};

export { useUsers };
