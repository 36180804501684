import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToasts } from "../context/ToastProvider";
import { Event } from "../models/event.model";
import { Response } from "../models/response.model";
import { FetchError, useApi } from "./useApi";

const useEvents = (assessmentId: string) => {
  const { fetchWithToken } = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const eventsKey = ["events", assessmentId];

  const getEvents = async (id: string) => {
    const response = await fetchWithToken<Response<Event[]>>(
      `/assessments/${id}/events`
    );
    const sortedData = response.data
      .sort((a, b) => (a.performed > b.performed ? -1 : 1))
      .filter((a) => a.visible);
    return sortedData;
  };

  const postComment = async ({
    id,
    comment,
  }: {
    id: string;
    comment: string;
  }) => {
    const result = await fetchWithToken<Response<Event>>(
      `/assessments/${id}/comments`,
      {
        method: "POST",
        body: {
          comment,
        },
      }
    );
    return result.data;
  };

  const addCommentMutation = useMutation<
    Event,
    FetchError,
    {
      id: string;
      comment: string;
    }
  >((update) => postComment(update), {
    // If the mutation fails, use the context we returned above
    onError: () => {
      addToast({
        type: "error",
        content: "Error adding comment.",
      });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(eventsKey);
    },
    onSuccess: async (result) => {
      // Once we get the new event back, add it to the list.
      queryClient.setQueryData<Event[] | undefined>(eventsKey, (old) =>
        old ? [result, ...old] : [result]
      );
      addToast({ type: "message", content: "Comment added" });
    },
  });

  const eventsQuery = useQuery(eventsKey, () => getEvents(assessmentId));

  const addComment = (comment: string) => {
    addCommentMutation.mutate({ id: assessmentId, comment });
  };

  return { eventsQuery, addComment, addCommentMutation };
};

export { useEvents };
