/**
 * @copyright 2021 Ultramed Ltd
 * @author Nicolaas Prinsloo <nick@ultramed.co>
 * FormRendererContext
 * Manages the state of FormRenderer.
 */
import React from "react";
import { Characteristics } from "../models/submission.model";

type FormRendererProviderProps = {
  characteristics: Characteristics;
};

const FormRendererContext = React.createContext<{
  characteristics: Characteristics;
  updateCharacteristics: (characteristics: Characteristics) => void;
} | null>(null);

const FormRendererProvider: React.FC<FormRendererProviderProps> = ({
  children,
  characteristics,
}) => {
  const [formCharacteristics, setFormCharacteristics] =
    React.useState<Characteristics>(characteristics);

  // Wrap the functions in useCallback to reuse them instead of recreating on each render.
  const updateCharacteristics = React.useCallback(
    (characteristics: Characteristics) => {
      setFormCharacteristics((old) => ({ ...old, ...characteristics }));
    },
    [setFormCharacteristics]
  );

  return (
    <FormRendererContext.Provider
      value={{
        characteristics: formCharacteristics,
        updateCharacteristics,
      }}
    >
      {children}
    </FormRendererContext.Provider>
  );
};

const useFormRenderer = () => {
  const context = React.useContext(FormRendererContext);

  if (!context) {
    throw new Error(
      "useFormRenderers must be used inside of FormRendererContext"
    );
  }

  return context;
};

export { FormRendererProvider, useFormRenderer };
