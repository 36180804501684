import { RadioGroup } from "../RadioGroup";
import { RadioOption } from "../RadioOption";

type ContactMethodRadioProps = {
  disabledOptions?: string[];
  allowClinicianEntered?: boolean;
  value: string;
  onChange: (value: string) => void;
};

const ContactMethodRadio = ({
  disabledOptions,
  allowClinicianEntered = true,
  value,
  onChange,
}: ContactMethodRadioProps) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      label="Contact method"
      description="The assessment invite will be sent using the selected method"
    >
      <RadioOption
        value="sms"
        label="SMS"
        disabled={disabledOptions?.includes("sms")}
      />
      <RadioOption
        value="email"
        label="Email"
        disabled={disabledOptions?.includes("email")}
      />
      {allowClinicianEntered && (
        <RadioOption value="clinician" label="Clinician entered" />
      )}
    </RadioGroup>
  );
};

export { ContactMethodRadio };
