import React from "react";
import { Private } from "../../models/form.model";

interface ReportSectionProps {
  name: string;
  privateSettings: Private;
}

const RecommendationsTable: React.FC<ReportSectionProps> = ({
  privateSettings,
}) => {
  const firstRow =
    privateSettings.tableRowOptions &&
    Array.isArray(privateSettings.tableRowOptions)
      ? privateSettings.tableRowOptions[0]
      : undefined;

  const [, ...restRows] =
    privateSettings.tableRowOptions &&
    Array.isArray(privateSettings.tableRowOptions)
      ? privateSettings.tableRowOptions
      : [];

  return (
    <table className="mb-2 border w-full border-gray-300 text-sm">
      <thead>
        <tr>
          {firstRow?.columns.map((column, index) => (
            <th key={index} className="p-2 text-left">
              {column.value}
            </th>
          ))}
        </tr>
      </thead>
      {restRows?.map((row, index) => (
        <tr key={index}>
          {row.columns.map((column, index) => (
            <td key={index} className="p-2">
              {column.value}
            </td>
          ))}
        </tr>
      ))}
    </table>
  );
};

export { RecommendationsTable };
