import React from "react";

interface ReportSectionProps {
  name: string;
  settings: {
    [key: string]: {
      value: string;
    };
  };
}

const ReportSection: React.FC<ReportSectionProps> = ({
  settings,
  children,
}) => {
  return (
    <>
      {settings.headingText && (
        <h1 className="text-xl font-medium">{settings.headingText?.value}</h1>
      )}
      {settings.subHeading && (
        <h2 className="text-lg font-medium mb-2">
          {settings.subHeading?.value}
        </h2>
      )}
      <table className="mb-2 border w-full border-gray-300 text-sm">
        <tbody>{children}</tbody>
      </table>
    </>
  );
};

export { ReportSection };
