import { FieldValidator, useField } from "formik";
import React from "react";
import { IconType } from "react-icons/lib";
import { Input } from "../Input";
import { InputLabel } from "../InputLabel";

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: NonNullable<React.InputHTMLAttributes<HTMLInputElement>["type"]>;
  label: React.ReactNode;
  iconAfter?: IconType;
  loading?: boolean;
  validate?: FieldValidator;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, validate, ...props }, ref) => {
    const [field, meta] = useField({ ...props, validate });
    return (
      <div>
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <Input
          ref={ref}
          error={meta.touched && !!meta.error}
          errorMessage={meta.touched && !!meta.error ? meta.error : undefined}
          aria-invalid={meta.touched && !!meta.error}
          aria-describedby={`${props.id}-error`}
          {...field}
          {...props}
        />
      </div>
    );
  }
);

export { TextField };
