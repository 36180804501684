import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";

import { Role } from "../../models/role.model";
import { Service } from "../../models/service.model";
import { User } from "../../models/user.model";
import { Button } from "../Button";
import { CheckboxItem } from "../CheckboxItem";
import { ModalActions } from "../ModalActions";
import { ModalContent } from "../ModalContent";
import { TextField } from "../TextField";
import { RoleFormControl } from "./RoleFormControl";
import { ServiceFormControl } from "./ServiceFormControl";

type UserDetailsFormProps = {
  services: Service[];
  roles: Role[];
  onSubmit: (values: UserDetailsFormFields) => void;
  onCancel: () => void;
  actionButtonText: string;
  isLoading?: boolean;
  userData?: User & { authRoles: Role[] };
  disabled?: boolean;
};

const userDetailsFormSchema = Yup.object({
  prefix: Yup.string().max(30, "Must be 30 characters or less"),
  email: Yup.string().email().required("Email is required"),
  givenName: Yup.string()
    .max(30, "Must be 30 characters or less")
    .required("First name is required"),
  familyName: Yup.string()
    .max(30, "Must be 30 characters or less")
    .required("Last name is required"),
  isConsultant: Yup.boolean().required("Consultant is required"),
  isAssignable: Yup.boolean().required("Assignable is required"),
  services: Yup.array().required(),
  roles: Yup.array().required(),
});
type UserDetailsFormFields = Yup.Asserts<typeof userDetailsFormSchema>;

const UserDetailsForm = ({
  services,
  roles,
  onSubmit,
  userData,
  actionButtonText,
  disabled,
  onCancel,
  isLoading,
}: UserDetailsFormProps) => {
  const initialValues = {
    prefix: userData?.prefix ?? "",
    email: userData?.email ?? "",
    givenName: userData?.givenName ?? "",
    familyName: userData?.familyName ?? "",
    isConsultant: userData?.isConsultant ?? false,
    isAssignable: userData?.isAssignable ?? false,
    services: userData?.services ?? [],
    roles: userData?.authRoles.map((a) => a.id) ?? [],
  };

  return (
    <ModalContent>
      <Formik
        initialValues={initialValues}
        validationSchema={userDetailsFormSchema}
        onSubmit={(values) =>
          onSubmit(values as unknown as UserDetailsFormFields)
        }
        // we need this to make sure formik re-renders after the form is submitted
        // This is needed when you edit a user, submit and then re-open the edit modal
        // see https://github.com/formium/formik/issues/811
        enableReinitialize
      >
        <Form id="addUserForm" className="flex flex-col">
          <div className="mb-4">
            <p>User details</p>
            <hr />
          </div>
          <TextField
            id="email"
            name="email"
            type="email"
            label="Email"
            disabled={disabled}
          ></TextField>
          <TextField
            id="prefix"
            name="prefix"
            type="text"
            label="Title"
            disabled={disabled}
          ></TextField>
          <TextField
            id="givenName"
            name="givenName"
            type="text"
            label="First name"
            disabled={disabled}
          ></TextField>
          <TextField
            id="familyName"
            name="familyName"
            type="text"
            label="Last name"
            disabled={disabled}
          ></TextField>
          <div className="mt-4 mb-2">
            <p>Roles</p>
            <hr />
          </div>
          <CheckboxItem
            id="isConsultant"
            name="isConsultant"
            label="Consultant"
            withinFormik
            disabled={disabled}
            defaultChecked={userData?.isConsultant ?? false}
          ></CheckboxItem>
          <CheckboxItem
            id="isAssignable"
            name="isAssignable"
            label="Assignable"
            withinFormik
            disabled={disabled}
            defaultChecked={userData?.isAssignable ?? false}
          ></CheckboxItem>
          {roles.map((role, i) => (
            <RoleFormControl
              key={i}
              role={role}
              disabled={disabled}
            ></RoleFormControl>
          ))}
          <div className="mt-4 mb-2">
            <p>Services</p>
            <hr />
          </div>
          {services.map((service, i) => (
            <ServiceFormControl
              key={i}
              service={service}
              disabled={disabled}
            ></ServiceFormControl>
          ))}
          <ModalActions>
            <Button
              color="default"
              type="button"
              as="button"
              flat
              variant="text"
              className="mr-2"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              as="button"
              type="submit"
              form="addUserForm"
              loading={isLoading}
              className="w-24"
            >
              {actionButtonText}
            </Button>
          </ModalActions>
        </Form>
      </Formik>
    </ModalContent>
  );
};

export type { UserDetailsFormFields };
export { UserDetailsForm };
