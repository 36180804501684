type InputErrorProps = {
  error?: string;
  id: string;
};

const InputError = ({ error, id }: InputErrorProps) => {
  return error ? (
    <p className="mt-2 text-sm text-red-600" id={id}>
      {error}
    </p>
  ) : null;
};

export { InputError };
