import { useQuery, UseQueryResult } from "react-query";
import { config } from "../config";

type Organization = {
  id: string;
  name: string;
  display_name: string;
};

const useOrganization = (): UseQueryResult<Organization, Error> => {
  const getSubdomain = (hostname: string) => hostname.split(".")[0];

  const subdomain =
    window.location.hostname === "localhost"
      ? "test"
      : getSubdomain(window.location.hostname);

  const getOrganizationByName = async (name: string) => {
    const result = await fetch(`${config.api.url}/auth/organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
      }),
    });
    return result.json();
  };

  const organizationQuery = useQuery<Organization, Error>(
    ["organization", subdomain],
    () => getOrganizationByName(subdomain),
    { refetchOnWindowFocus: false, refetchOnReconnect: false }
  );
  return organizationQuery;
};

export { useOrganization };
