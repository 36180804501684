import type React from "react";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

type AssessmentsTableHeaderProps = {
  canSort?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  toggleSortBy?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const AssessmentsTableHeader: React.FC<AssessmentsTableHeaderProps> = ({
  canSort = false,
  isSorted = false,
  isSortedDesc,
  toggleSortBy,
  className,
  children,
  ...props
}) => {
  const Component = toggleSortBy ? "button" : "span";
  return (
    <Component
      className="flex items-center p-2 outline-none focus-visible:ring-2 focus-visible:ring-primary-400 rounded-md mt-1 mb-1 ml-2 group"
      onClick={() => toggleSortBy && toggleSortBy()}
      aria-label={canSort ? `Sort by ${children}` : undefined}
    >
      <span className="text-left font-medium uppercase tracking-wider">
        {children}
      </span>
      {canSort && !isSorted && (
        <FaSort className="ml-2 text-gray-300 group-hover:text-gray-500" />
      )}
      {isSorted ? (
        isSortedDesc ? (
          <FaSortDown className="ml-2" />
        ) : (
          <FaSortUp className="ml-2" />
        )
      ) : (
        ""
      )}
    </Component>
  );
};

export { AssessmentsTableHeader };
