import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { Pill } from "../Pill";
import { Select } from "../Select";
import { SelectOption } from "../SelectOption";

type PriorityProps = {
  priority: number | undefined;
  assessmentId: string;
  disabled?: boolean;
};

function Priority({
  priority,
  assessmentId,
  disabled = false,
}: PriorityProps): JSX.Element {
  const { updatePriority } = useAssessmentMutations(assessmentId);

  const priorityColor = React.useMemo(() => {
    switch (priority) {
      case 1:
      case 2:
        return "red";
      case 3:
        return "yellow";
      case 4:
      case 5:
      case 6:
      default:
        return "green";
    }
  }, [priority]);

  return (
    <Select
      value={priority}
      label="Assessment priority"
      showLabel={false}
      onChange={(value) => typeof value === "number" && updatePriority(value)}
      button={
        <Pill color={priorityColor} disabled={disabled}>
          <FaExclamationCircle className="mr-2" />
          {priority
            ? `P${priority}`
            : disabled
            ? "No priority"
            : "Add priority"}
        </Pill>
      }
    >
      <SelectOption value={1}>1</SelectOption>
      <SelectOption value={2}>2</SelectOption>
      <SelectOption value={3}>3</SelectOption>
      <SelectOption value={4}>4</SelectOption>
      <SelectOption value={5}>5</SelectOption>
      <SelectOption value={6}>6</SelectOption>
    </Select>
  );
}
export { Priority };
