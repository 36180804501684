import * as React from "react";

type TableProps = {
  children: React.ReactNode;
};

const Table = ({ children }: TableProps) => (
  <div className="align-middle w-full overflow-x-auto shadow">
    <div className="sm:rounded-lg flex flex-col border-b min-h-0 border-gray-200">
      <table className="table-fixed min-w-full divide-y divide-gray-200 min-h-0">
        {children}
      </table>
    </div>
  </div>
);

export { Table };
