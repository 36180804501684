import { createPortal } from "react-dom";
import { ToastItem } from "../../context/ToastProvider";
import { Toast } from "../ToastItem";

type ToastContainerProps = { toasts: ToastItem[] };

const ToastContainer = ({ toasts }: ToastContainerProps) => {
  return createPortal(
    <div
      className="fixed inset-0 flex px-4 py-6 pointer-events-none sm:p-6 z-20"
      aria-live="assertive"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </div>
    </div>,
    document.body
  );
};

export { ToastContainer };
