import React from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../utilities/mergeStyles";

type PriorityRadioOptionProps = {
  value: string;
  children: React.ReactNode;
  first?: boolean;
  last?: boolean;
};

function PriorityRadioOption({
  value,
  children,
  first,
  last,
}: PriorityRadioOptionProps) {
  return (
    <RadioGroup.Option value={value} className="focus:outline-none group">
      {({ checked }) => (
        <span
          className={classNames(
            first && "rounded-l-md",
            last && "rounded-r-md",
            !first && "-ml-px",
            checked
              ? "bg-primary-400 text-white border-primary-400"
              : "bg-white text-gray-700 hover:bg-gray-50 border-gray-300",
            "relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer group-focus:focus:outline-none group-focus:ring-2 group-focus:ring-offset-2 group-focus:ring-primary-400"
          )}
        >
          {children}
        </span>
      )}
    </RadioGroup.Option>
  );
}

type PriorityRadioProps = {
  value: number;
  onChange: (value: number) => void;
};

function PriorityRadio({ value, onChange }: PriorityRadioProps): JSX.Element {
  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="block text-sm font-medium text-gray-700">
          Priority
        </RadioGroup.Label>
        <span className="relative inline-flex shadow-sm rounded-md mt-1 focus:outline-none">
          <PriorityRadioOption value="1" first>
            1
          </PriorityRadioOption>
          <PriorityRadioOption value="2">2</PriorityRadioOption>
          <PriorityRadioOption value="3">3</PriorityRadioOption>
          <PriorityRadioOption value="4">4</PriorityRadioOption>
          <PriorityRadioOption value="5">5</PriorityRadioOption>
          <PriorityRadioOption value="6" last>
            6
          </PriorityRadioOption>
        </span>
      </RadioGroup>
    </>
  );
}

export { PriorityRadio };
