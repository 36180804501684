import { useQuery } from "react-query";
import { Response } from "../models/response.model";
import { useApi } from "./useApi";
import { Tag } from "../models/tag.model";

const useTags = () => {
  const { fetchWithToken } = useApi();

  const sortTags = (users: Tag[]): Tag[] => {
    return users.sort((a, b) =>
      a.text?.toLocaleLowerCase() > b.text?.toLocaleLowerCase() ? 1 : -1
    );
  };

  const getTags = async () => {
    return fetchWithToken<Response<Tag[]>>("/tags").then((users) => ({
      ...users,
      data: sortTags(users.data),
    }));
  };

  const tagsQuery = useQuery(["tags"], () => getTags(), {
    select: (result) => result.data,
    staleTime: 1000 * 60 * 60, // Stale after 1 hour
  });

  return { tagsQuery };
};

export { useTags };
