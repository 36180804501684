import { Filter } from "../models/apiquery.model";

/**
 * Makes a query string from an object.
 * Only supports a single level object.
 * If something more is required we can consider
 * using qs instead.
 * @see https://github.com/ljharb/qs
 * e.g.
 * {
 *    a: "x",
 *    b: ["y", "z"]
 * }
 * -> ?a=x&b=y&b=z
 * Our API will accept the duplicate keys and
 * covert them into an array
 * @param query
 * @returns
 */
function stringify(query: {
  [key: string]: string | number | boolean | (string | number | boolean)[];
}): string {
  const urlSearchParams = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    const currentQuery = value;
    if (Array.isArray(currentQuery)) {
      currentQuery.forEach((entry) => {
        if (
          typeof entry !== "string" &&
          typeof entry !== "number" &&
          typeof entry !== "boolean"
        ) {
          throw new Error(
            `Error stringifying query object. ${JSON.stringify(
              entry
            )} must be string | number | Array<string | number>`
          );
        }
        urlSearchParams.append(key, entry.toString());
      });
      return;
    }
    if (
      typeof currentQuery !== "string" &&
      typeof currentQuery !== "number" &&
      typeof currentQuery !== "boolean"
    ) {
      throw new Error(
        `Error stringifying query object. ${JSON.stringify(
          currentQuery
        )} must be string | number | Array<string | number>`
      );
    }
    urlSearchParams.append(key, currentQuery.toString());
  });
  const queryString = `?${urlSearchParams.toString()}`;

  return queryString;
}

type FilterQuery = {
  [x: string]: string | number | boolean | (string | number | boolean)[];
};

function filterQuery<T>(filter: Filter<T>): FilterQuery {
  return filter.reduce(
    (acc, current) => ({
      ...acc,
      [`filter[${current.filterBy}]`]: current.value,
    }),
    {}
  );
}

export { stringify, filterQuery };
