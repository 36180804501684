import { useEvents } from "../../hooks/useEvents";
import { Assessment } from "../../models/assessment.model";
import { Alert } from "../Alert";
import { Timeline, TimelineItem } from "../Timeline";

type AssessmentSummaryProps = {
  assessment: Assessment;
};

const AssessmentSummary = ({ assessment }: AssessmentSummaryProps) => {
  const {
    eventsQuery: { data: events, status },
  } = useEvents(assessment.id);

  return (
    <>
      {assessment.status === "draft" && (
        <div className="mb-4">
          <Alert
            title={`${
              assessment.legacyExpected
                ? "This assessment was created with an old version of Ultramed and has not been submitted."
                : "This assessment has not been submitted."
            }`}
            text={
              assessment.legacyExpected
                ? "You can't send invite codes by SMS or email if an assessment was started using an old version of Ultramed. Please consider starting a new assessment."
                : ""
            }
            type="warn"
          />
        </div>
      )}
      {assessment.archived && (
        <div className="mb-4">
          <Alert
            title="This assessment is archived"
            text="This assessment has been archived. You are still able to view the assessment. If you would like to unarchive, please use the actions dropdown."
            type="warn"
          />
        </div>
      )}
      <div className="flex min-h-0 justify-center">
        <div className="flex flex-col flex-1 max-w-2xl space-y-4">
          <Timeline status={status} assessmentId={assessment.id}>
            {events &&
              events.map((event, eventIndex) => (
                <TimelineItem
                  key={event.id}
                  event={event}
                  isLast={eventIndex === events.length - 1}
                />
              ))}
          </Timeline>
        </div>
      </div>
    </>
  );
};

export { AssessmentSummary };
