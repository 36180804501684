import type {
  Answers,
  Bmi,
  Characteristics,
  SubmissionUpdate,
} from "../../models/submission.model";
import type {
  Scaffold,
  ScaffoldItem,
  ComponentMap,
  FlatSetting,
  Private,
  FormItem,
  EnableWhen,
} from "../../models/form.model";
import {
  Control,
  FieldValues,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { logicTest } from "../../utilities/formRenderer";
import { Button } from "../Button";
import React from "react";
import {
  FormRendererProvider,
  useFormRenderer,
} from "../../context/FormRendererProvider";
import { Prompt } from "react-router";

interface FormRendererProps {
  characteristics: Characteristics;
  scaffold: Scaffold;
  items: FormItem[];
  componentMap: ComponentMap;
  onSubmit?: (data: SubmissionUpdate) => void;
  loading?: boolean;
  isForm?: boolean;
}

interface RenderComponentProps {
  componentMap: ComponentMap;
  scaffoldItems: ScaffoldItem[];
  items: FormItem[];
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  editing?: boolean;
}

type FormData = {
  [key: string]: {
    value: string | number | boolean | Bmi;
    characteristics: Characteristics;
  };
};

function FormRenderer({
  characteristics,
  scaffold,
  items,
  componentMap,
  isForm,
  onSubmit = () => void {},
  loading,
}: FormRendererProps): JSX.Element {
  const { register, control, handleSubmit } = useForm({
    defaultValues: {},
  });

  const [editing, setEditing] = React.useState(false);

  const convertToAnswers = (formData: FormData): Answers =>
    Object.entries(formData).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value?.value !== undefined
          ? { [key]: [{ value: value.value }] }
          : {}),
      }),
      {}
    );

  const convertCharacteristics = (formData: FormData): Characteristics =>
    Object.values(formData).reduce(
      (acc, value) => ({
        ...acc,
        ...(value?.characteristics !== undefined
          ? { ...value.characteristics }
          : {}),
      }),
      {}
    );

  const handleFormSave = (data: FormData) => {
    const answers = convertToAnswers(data);
    const newCharacteristics = convertCharacteristics(data);
    onSubmit({ answers, characteristics: newCharacteristics });
    setEditing(false);
  };

  return (
    <FormRendererProvider characteristics={characteristics}>
      <Prompt
        when={editing}
        message="You haven't saved your changes. Are you sure you want to leave?"
      />
      {isForm ? (
        <form onSubmit={handleSubmit<FormData>(handleFormSave)}>
          <RenderComponent
            scaffoldItems={scaffold.item}
            items={items}
            componentMap={componentMap}
            control={control}
            register={register}
            editing={editing}
          ></RenderComponent>
          <div className="flex justify-end">
            {editing && (
              <Button
                as="button"
                type="submit"
                color="primary"
                className="mt-2"
                loading={loading}
              >
                Save
              </Button>
            )}
            {!editing && (
              <Button
                as="button"
                type="button"
                color="default"
                className="mt-2"
                loading={loading}
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            )}
          </div>
        </form>
      ) : (
        <RenderComponent
          scaffoldItems={scaffold.item}
          items={items}
          componentMap={componentMap}
          control={control}
          register={register}
        ></RenderComponent>
      )}
    </FormRendererProvider>
  );
}

function RenderComponent(props: RenderComponentProps): JSX.Element {
  const { scaffoldItems, items, componentMap, control, register, editing } =
    props;

  const { characteristics } = useFormRenderer();

  // TODO: Move this to the server to reduce client side computation
  const flattenSettings = (item?: Private): FlatSetting =>
    item?.settings.reduce(
      (acc, curr) => ({
        ...acc,
        ...(curr.carepennyAttr ? { [curr.carepennyAttr]: curr } : {}),
      }),
      {}
    ) ?? {};

  return (
    <>
      {scaffoldItems.map((item) => {
        const linkedItem = items.find((i) => i.id === item.linkId);

        // Check if the item passes its logic tests so that we can show or hide it
        const isEnabled = linkedItem?.private.enableWhen
          ? linkedItem.private.enableWhen.reduce(
              (acc: boolean | undefined, curr: EnableWhen) => {
                const test = logicTest(
                  curr.operator,
                  curr.type === "characteristic" && curr.characteristicId
                    ? characteristics[curr.characteristicId]?.value
                    : "",
                  curr.type === "characteristic" && curr.answer
                    ? curr.answer
                    : ""
                );
                if (linkedItem.private.enableBehavior === "all")
                  return acc !== undefined ? acc && test : test;
                if (linkedItem.private.enableBehavior === "any")
                  return acc !== undefined ? acc || test : test;
                return acc;
              },
              undefined
            ) ?? true
          : true;

        // Defined the generic component and fallback to an inline component that shows
        // any missing components visually.
        const Component =
          componentMap[item.carepennyType] ??
          (({ children }) => (
            <div>
              Missing component type {item.carepennyType}
              {children}
            </div>
          ));

        // Flatten array of settings into map of settings
        const flatSettings = flattenSettings(linkedItem?.private);

        return (
          isEnabled && (
            <Component
              key={item.id}
              name={item.id}
              register={register}
              control={control}
              options={linkedItem?.private.answerOption}
              privateSettings={linkedItem?.private} // Named privateSettings as private is protected in ECMAScript.
              public={linkedItem?.public}
              settings={flatSettings}
              characteristics={characteristics}
              answerCharacteristics={linkedItem?.private.answerCharacteristics}
              editing={editing}
            >
              {item.item && ( // Recursively render any child items
                <RenderComponent
                  {...props}
                  scaffoldItems={item.item}
                  editing={editing}
                ></RenderComponent>
              )}
            </Component>
          )
        );
      })}
    </>
  );
}

export { FormRenderer };
