import { useQuery } from "react-query";

import { useToken } from "../context/TokenContext";
import { Tenant } from "../models/tenant.model";
import { useApi } from "./useApi";

// This hook is used within the tenant provider so we have to use the decoded token to
// get the tenant ID to fetch
const useTenantQuery = () => {
  const { decodedToken } = useToken();
  const { fetchWithToken } = useApi();

  const { tenantId } = decodedToken["https://ultramed.tech/practitioner"];
  const getTenant = () => fetchWithToken<Tenant>(`/tenants/${tenantId}`);

  const getTenantQuery = useQuery(["tenant", tenantId], getTenant, {
    cacheTime: Infinity,
  });

  return { getTenantQuery };
};

export { useTenantQuery };
