import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";
import { FlatSetting } from "../../models/form.model";
import { Characteristics } from "../../models/submission.model";
import { generateCharacteristics } from "../../utilities/clinicalCalculators";
import { classNames } from "../../utilities/mergeStyles";

interface BmiCalculatorProps {
  name: string;
  settings: FlatSetting;
  control: Control<FieldValues>;
  characteristics: Characteristics;
  editing?: boolean;
}

type BmiValue = {
  height: number;
  weight: number;
  bmi: number;
};

interface ControlledCalculatorProps {
  name: string;
  settings: FlatSetting;
  value: BmiValue;
  characteristics: Characteristics;
  onChange: (value: unknown) => void;
  editing?: boolean;
}

const ControlledCalculator: React.FC<ControlledCalculatorProps> = ({
  name,
  settings,
  characteristics,
  onChange,
  editing,
}) => {
  const bmiCharacteristics = React.useMemo(
    () => (settings.bmiCharacteristic?.value ?? []) as unknown as string[],
    [settings]
  );
  const weightCharacteristics = React.useMemo(
    () => (settings.weightCharacteristic?.value ?? []) as unknown as string[],
    [settings]
  );
  const heightCharacteristics = React.useMemo(
    () => (settings.heightCharacteristic?.value ?? []) as unknown as string[],
    [settings]
  );

  const isNumber = (value: string | undefined | boolean): value is string => {
    if (typeof value != "string") {
      return false;
    }
    return !isNaN(Number.parseFloat(value));
  };

  const getCharacteristicsValue = React.useCallback(
    (characteristicIds: string[], characteristics: Characteristics) =>
      characteristicIds?.reduce<number | undefined>((acc, curr) => {
        const charValue = characteristics[curr];
        const result =
          charValue && charValue.value && typeof charValue.value !== "object"
            ? charValue.value
            : acc;

        return typeof result === "number"
          ? result
          : isNumber(result)
          ? Number.parseFloat(result)
          : undefined;
      }, undefined),
    []
  );

  const defaultBmiValue = React.useMemo(
    () => getCharacteristicsValue(bmiCharacteristics, characteristics),
    [bmiCharacteristics, characteristics, getCharacteristicsValue]
  );
  const defaultWeightValue = React.useMemo(
    () => getCharacteristicsValue(weightCharacteristics, characteristics),
    [weightCharacteristics, characteristics, getCharacteristicsValue]
  );
  const defaultHeightValue = React.useMemo(
    () => getCharacteristicsValue(heightCharacteristics, characteristics),
    [heightCharacteristics, characteristics, getCharacteristicsValue]
  );

  const [heightWeightState, setHeightWeight] = React.useState<{
    height: number | undefined;
    weight: number | undefined;
  }>({
    height: defaultHeightValue,
    weight: defaultWeightValue,
  });

  const [bmiState, setBmiState] = React.useState<number | undefined>(
    defaultBmiValue
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setHeightWeight({
      ...heightWeightState,
      [e.currentTarget.name]: isNumber(e.currentTarget.value)
        ? Number.parseFloat(e.currentTarget.value)
        : undefined,
    });
  };

  const calculateBmi = (height: number, weight: number) => {
    return weight / height ** 2;
  };

  React.useEffect(() => {
    const { height, weight } = heightWeightState;
    if (height && weight) {
      const bmi = calculateBmi(height, weight);
      setBmiState(Math.round(bmi));
    } else {
      setBmiState(undefined);
    }
  }, [heightWeightState]);

  React.useEffect(() => {
    const { height, weight } = heightWeightState;
    const bmi = bmiState;

    const change = {
      value:
        height && weight && bmi
          ? {
              height,
              weight,
              bmi,
            }
          : null,
      characteristics: {
        ...generateCharacteristics(bmiState ?? null, bmiCharacteristics),
        ...generateCharacteristics(height ?? null, heightCharacteristics),
        ...generateCharacteristics(weight ?? null, weightCharacteristics),
      },
    };

    onChange(change);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightWeightState, bmiState]);

  return (
    <div className="space-y-2">
      <div>
        <label
          htmlFor={`${name}-height`}
          className="block text-sm font-medium text-gray-700"
        >
          Height (m)
        </label>
        <input
          type="number"
          max="3"
          value={heightWeightState.height ?? ""}
          onChange={handleChange}
          disabled={!editing}
          name="height"
          id={`${name}-height`}
          className={classNames(
            "shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md",
            !editing && "bg-gray-100"
          )}
        ></input>
      </div>
      <div>
        <label
          htmlFor={`${name}-weight`}
          className="block text-sm font-medium text-gray-700"
        >
          Weight (kg)
        </label>
        <input
          type="number"
          max="300"
          value={heightWeightState.weight ?? ""}
          onChange={handleChange}
          disabled={!editing}
          name="weight"
          id={`${name}-weight`}
          className={classNames(
            "shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md",
            !editing && "bg-gray-100"
          )}
        ></input>
      </div>
      <div>
        <label
          htmlFor={`${name}-bmi`}
          className="block text-sm font-medium text-gray-700"
        >
          BMI
        </label>
        <input
          type="number"
          value={bmiState ?? ""}
          name="bmi"
          id={`${name}-bmi`}
          className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md text-gray-400 bg-gray-100"
          disabled
        ></input>
      </div>
    </div>
  );
};

function BmiCalculator({
  name,
  characteristics,
  settings,
  control,
  editing,
}: BmiCalculatorProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ControlledCalculator
          settings={settings}
          characteristics={characteristics}
          name={name}
          onChange={onChange}
          value={value}
          editing={editing}
        />
      )}
    />
  );
}

export { BmiCalculator };
