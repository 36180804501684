import React from "react";
import { FaBell, FaComment, FaInfo } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { Event, EventType } from "../../models/event.model";
import { formatLongDate, formatName } from "../../utilities/cuiFormat";

type TimelineItemProps = {
  isLast?: boolean;
  event: Event;
};

type EventMap = {
  [key in EventType]?: {
    icon: IconType;
    color: `bg-${"primary" | "green" | "red" | "yellow" | "gray"}-500`;
  };
};

const eventMap: EventMap = {
  "add:comment": {
    icon: FaComment,
    color: "bg-primary-500",
  },
  "send:reminder": {
    icon: FaBell,
    color: "bg-yellow-500",
  },
};

function TimelineItem({ isLast = false, event }: TimelineItemProps) {
  const [performer, setPerformer] = React.useState<string | undefined>(
    undefined
  );

  const getPerformer = (event: Event) => {
    switch (event.performer.type) {
      case "Practitioner":
        return event.performer.givenName || event.performer.familyName
          ? formatName(
              event.performer.givenName ?? "",
              event.performer.familyName ?? "",
              event.performer.prefix
            )
          : undefined;
      case "Patient":
        return "patient";
      case "System":
      default:
        return "system";
    }
  };

  React.useEffect(() => {
    setPerformer(getPerformer(event));
  }, [event]);

  const eventDisplay = eventMap[event.type];
  const eventColor = eventDisplay?.color
    ? eventDisplay?.color
    : "bg-primary-500";

  return (
    <li>
      <div className="relative pb-8">
        {!isLast ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <div>
            <span
              className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-gray-100 ${eventColor}`}
            >
              {eventDisplay ? (
                <eventDisplay.icon
                  className="h-4 w-4 text-white"
                  aria-hidden="true"
                />
              ) : (
                <FaInfo className="h-4 w-4 text-white" aria-hidden="true" />
              )}
            </span>
          </div>
          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div>
              <p className="text-sm text-gray-900">
                {event.detail}
                {performer && (
                  <>
                    {" "}
                    by <span className="font-medium">{performer}</span>
                  </>
                )}
              </p>
              {event.comment && (
                <div className="text-sm text-gray-500">
                  <p>{event.comment}</p>
                </div>
              )}
            </div>
            <div className="text-right text-sm whitespace-nowrap text-gray-500">
              {formatLongDate({ date: event.performed })}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export { TimelineItem };
