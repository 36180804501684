import * as React from "react";
import type { ListProps } from "react-stately";
import { useListState, useOverlayTriggerState, ListState } from "react-stately";
import { Node } from "@react-types/shared";
import { Placement } from "@react-types/overlays";
import {
  useFilter,
  useButton,
  useOverlayPosition,
  useFocusManager,
  useOverlayTrigger,
  OverlayContainer,
} from "react-aria";
import { classNames } from "../../utilities/mergeStyles";
import { ListBox } from "../ListBox";
import { Popover } from "../Popover";
import { Spinner } from "../Spinner";
import { FaTimes } from "react-icons/fa";
import { AriaListBoxOptions } from "@react-aria/listbox";

type FilterBoxContentProps = {
  listBoxRef: React.RefObject<HTMLUListElement>;
  onFilterChange: (value: string) => void;
  filterState: string;
  state: ListState<unknown>;
  loading: boolean;
  clearable: boolean;
  onClear?: () => void;
  onClose: () => void;
} & AriaListBoxOptions<unknown>;

function FilterBoxContent({
  listBoxRef,
  onFilterChange,
  filterState,
  state,
  loading,
  clearable,
  onClear,
  onClose,
  ...rest
}: FilterBoxContentProps) {
  const focusManager = useFocusManager();

  const onKeyDown = (e: React.KeyboardEvent<unknown>) => {
    switch (e.key) {
      case "ArrowDown":
        focusManager.focusNext();
        break;
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
      onClose();
    }
  };

  return (
    <div className="flex w-60 flex-col overflow-hidden">
      <div className="p-2 flex flex-shrink-0">
        <div className="flex rounded-md shadow-sm flex-1">
          <input
            onKeyDownCapture={onKeyDown}
            onChange={(e) => onFilterChange(e.currentTarget.value)}
            value={filterState}
            placeholder="Type to filter"
            className={classNames(
              "py-1 px-2 block w-full focus:outline-none text-sm rounded-md border focus:ring-primary-400 focus:border-primary-400 border-gray-300"
            )}
          />
        </div>
      </div>
      {state.selectionManager.selectedKeys.size > 0 && clearable && (
        <button
          className="bg-white p-2 text-gray-600 text-xs font-medium border-t border-gray-200 flex hover:bg-primary-500 hover:text-white items-center focus:outline-none focus:bg-primary-500 focus:text-white"
          onKeyDown={onKeyDown}
          onClick={() => handleClear()}
        >
          <FaTimes className="w-3 h-3 mr-2" />
          Clear
        </button>
      )}
      {loading && (
        <div className="flex justify-center items-center flex-1 h-40 pb-4 pt-2">
          <Spinner size="lg" />
        </div>
      )}
      {!loading && (
        <>
          <div className="bg-gray-100 p-2 text-gray-900 text-xs font-medium border-t border-b border-gray-200">
            {rest.label}
          </div>
          <ListBox {...rest} listBoxRef={listBoxRef} state={state} />
        </>
      )}
    </div>
  );
}

type FilterFn = (textValue: string, inputValue: string) => boolean;

function filterNodes<T>(
  nodes: Iterable<Node<T>>,
  inputValue: string,
  filter: FilterFn
): Iterable<Node<T>> {
  const filteredNode = [];
  for (const node of nodes) {
    if (node.type === "section" && node.hasChildNodes) {
      const filtered = filterNodes(node.childNodes, inputValue, filter);
      if ([...filtered].length > 0) {
        filteredNode.push({ ...node, childNodes: filtered });
      }
    } else if (node.type !== "section" && filter(node.textValue, inputValue)) {
      filteredNode.push({ ...node });
    }
  }
  return filteredNode;
}

type FilterBoxProps<T> = {
  label?: string;
  selectedKey?: string | null;
  button?: JSX.Element;
  loading?: boolean;
  onChange?: (key: React.Key) => void;
  onMultipleChange?: (keys: React.Key[]) => void;
  onClear?: () => void;
  disabled?: boolean;
  clearable?: boolean;
  placement?: Placement;
} & ListProps<T>;

export function FilterBox<T extends Record<string, unknown>>({
  onChange,
  onMultipleChange,
  selectedKey,
  button,
  loading = false,
  disabled = false,
  clearable = false,
  placement = "bottom start",
  onClear,
  selectionMode = "single",
  selectedKeys,
  ...props
}: FilterBoxProps<T>) {
  const { contains } = useFilter({ sensitivity: "base" });
  const overlayTriggerState = useOverlayTriggerState({});
  const [filterState, setFilterState] = React.useState<string>("");
  const state = useListState({
    ...props,
    selectedKeys: selectedKeys
      ? selectedKeys
      : selectedKey
      ? [selectedKey]
      : [],
    selectionMode,
    onSelectionChange: (keys) => {
      const selectedKeys = [...keys];
      overlayTriggerState.close();
      if (onChange) {
        onChange([...selectedKeys][0]);
      }
      if (onMultipleChange) {
        onMultipleChange(selectedKeys);
      }
    },
    filter: (nodes) => {
      return filterNodes(nodes, filterState, contains);
    },
  });

  const buttonRef = React.useRef(null);
  const listBoxRef = React.useRef<HTMLUListElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);

  const { buttonProps } = useButton(
    {
      onPress: () => overlayTriggerState.open(),
      isDisabled: disabled,
    },
    buttonRef
  );

  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "listbox" },
    overlayTriggerState,
    buttonRef
  );

  // Get popover positioning props relative to the trigger
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: buttonRef,
    overlayRef: popoverRef,
    placement,
    offset: 5,
    isOpen: overlayTriggerState.isOpen,
  });

  React.useEffect(() => {
    if (!state.selectionManager.isFocused) {
      state.selectionManager.setFocusedKey(null as unknown as string);
    }
  }, [state]);

  React.useEffect(() => {
    if (!overlayTriggerState.isOpen) {
      setFilterState("");
    }
  }, [overlayTriggerState]);

  const onFilterChange = (value: string) => {
    setFilterState(value);
  };

  return (
    <div className="inline-flex flex-col relative">
      <label className="sr-only">{props.label}</label>
      {React.isValidElement(button) &&
        React.cloneElement(button, {
          ref: buttonRef,
          ...buttonProps,
          ...triggerProps,
        })}
      {overlayTriggerState.isOpen && (
        <OverlayContainer>
          <Popover
            {...overlayProps}
            {...positionProps}
            ref={popoverRef}
            isOpen={overlayTriggerState.isOpen}
            onClose={overlayTriggerState.close}
            className="max-h-80"
          >
            <FilterBoxContent
              {...props}
              listBoxRef={listBoxRef}
              filterState={filterState}
              onFilterChange={onFilterChange}
              state={state}
              loading={loading}
              label={props.label}
              clearable={clearable}
              onClear={onClear}
              onClose={overlayTriggerState.close}
            />
          </Popover>
        </OverlayContainer>
      )}
    </div>
  );
}
