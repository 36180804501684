import * as React from "react";

import { useToken } from "../../context/TokenContext";
import { useAssignableRoles } from "../../hooks/useAssignableRoles";
import { useServices } from "../../hooks/useServices";
import { useUserRoles } from "../../hooks/useUserRoles";
import { useUsers } from "../../hooks/useUsers";
import { makeUserUpdate, User } from "../../models/user.model";
import { Modal } from "../Modal";
import { Spinner } from "../Spinner";
import {
  UserDetailsForm,
  UserDetailsFormFields,
} from "../UserDetailsForm/UserDetailsForm";

type EditUserModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: User;
};

const EditUserModal = ({ isOpen, setIsOpen, user }: EditUserModalProps) => {
  const { decodedToken } = useToken();
  const { servicesQuery } = useServices();
  const { rolesQuery } = useAssignableRoles();
  const { data: servicesData, status: servicesStatus } = servicesQuery;
  const { data: rolesData, status: rolesStatus } = rolesQuery;
  const {
    userRolesQuery: { status: userRolesStatus, data: userRolesData },
  } = useUserRoles({ userId: user.authId, orgId: decodedToken.org_id });
  const {
    updateUserMutation: { status: mutationStatus, mutate },
  } = useUsers();

  React.useEffect(() => {
    if (mutationStatus === "success") {
      setIsOpen(false);
    }
  }, [mutationStatus, setIsOpen]);

  const onSubmit = (values: UserDetailsFormFields) => {
    return mutate({
      user: makeUserUpdate({
        email: values.email,
        prefix: values.prefix,
        familyName: values.familyName,
        givenName: values.givenName,
        services: values.services,
        isAssignable: values.isAssignable,
        isConsultant: values.isConsultant,
        id: user.id,
        authRoles: values.roles ?? [],
        // current org for now, but could be a system wide thing in the future
        orgId: decodedToken.org_id,
      }),
    });
  };

  return (
    <>
      {(servicesStatus === "loading" ||
        rolesStatus === "loading" ||
        userRolesStatus === "loading") && <Spinner></Spinner>}
      {servicesStatus === "success" &&
        rolesStatus === "success" &&
        userRolesStatus === "success" &&
        servicesData &&
        rolesData &&
        userRolesData && (
          <Modal
            title="Edit user"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="lg:w-6/12 flex min-h-0"
          >
            <UserDetailsForm
              services={servicesData}
              roles={rolesData}
              onSubmit={onSubmit}
              onCancel={() => setIsOpen(false)}
              userData={{ ...user, authRoles: userRolesData ?? [] }}
              actionButtonText="OK"
              isLoading={mutationStatus === "loading"}
            ></UserDetailsForm>
          </Modal>
        )}
    </>
  );
};

export { EditUserModal };
