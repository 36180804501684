import { useToken } from "../context/TokenContext";

export type Permissions =
  | "create:assessment"
  | "read:assessment"
  | "write:assessment"
  | "resend:assessment"
  | "delete:assessment"
  | "archive:assessment"
  | "list:assessments"
  | "write:submission"
  | "read:submission"
  | "read:user"
  | "write:user"
  | "list:users"
  | "read:service"
  | "write:service"
  | "list:services"
  | "list:patients"
  | "read:patient"
  | "write:patient"
  | "send:message"
  | "read:form"
  | "read:self"
  | "write:self"
  | "list:tags"
  | "read:tag"
  | "write:tag"
  | "read:tenant"
  | "list:invites"
  | "manage:users"
  | "write:comment"
  | "update:asa"
  | "update:priority"
  | "update:tag"
  | "update:tci"
  | "update:service"
  | "update:consultant"
  | "update:user";

const usePermissions = () => {
  const { decodedToken } = useToken();

  const hasPermissions = (permissions: Permissions[]) =>
    permissions.every((perm) => decodedToken.permissions.includes(perm));

  return { hasPermissions };
};

export { usePermissions };
