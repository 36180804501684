import { mergeStyles } from "../../utilities/mergeStyles";
import styles from "./Spinner.module.css";

type SpinnerProps = {
  size?: "sm" | "md" | "lg" | "xl";
  color?: "primary" | "red" | "white" | "gray" | "light-gray";
  className?: string;
};

function Spinner({
  size = "md",
  color = "primary",
  className,
}: SpinnerProps): JSX.Element {
  const sizeStyles = {
    sm: styles["size-sm"],
    md: styles["size-md"],
    lg: styles["size-lg"],
    xl: styles["size-xl"],
  };

  const colorStyles = {
    primary: styles["color-primary"],
    red: styles["color-red"],
    white: styles["color-white"],
    gray: styles["color-gray"],
    "light-gray": styles["color-light-gray"],
  };

  return (
    <div className={className}>
      <div
        className={mergeStyles([
          "animate-spin-fast rounded-full",
          styles.loader,
          sizeStyles[size],
          colorStyles[color],
        ])}
      >
        <span className="sr-only">Loading</span>
      </div>
    </div>
  );
}

export { Spinner };
