import { useMutation, useQueryClient } from "react-query";
import { useToasts } from "../context/ToastProvider";

import { Document } from "../models/document.model";
import { useApi } from "./useApi";

type UseDocumentExportProps = {
  assessmentId: string;
};

const useDocumentExport = ({ assessmentId }: UseDocumentExportProps) => {
  const { fetchWithToken } = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const documentsQueryKey = ["documents", assessmentId];

  const createDocument = async ({ assessmentId }: { assessmentId: string }) => {
    return fetchWithToken<Response>(`/assessments/${assessmentId}/documents`, {
      method: "POST",
      responseHandler: (res) => res,
    });
  };

  const exportDocumentMutation = useMutation(createDocument, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(documentsQueryKey);
      const newDocument = (await response.json()).data as Document;
      queryClient.setQueryData<Document[]>(documentsQueryKey, (old) =>
        old ? [...old, newDocument] : [newDocument]
      );
      queryClient.invalidateQueries(documentsQueryKey);
    },
    onError: () => {
      addToast({
        type: "error",
        content: "Failed to export document. Please try again",
      });
    },
  });

  return { exportDocumentMutation };
};

export { useDocumentExport };
