import React from "react";
import { classNames } from "../../utilities/mergeStyles";

type ChipProps = {
  color?:
    | "primary"
    | "green"
    | "red"
    | "yellow"
    | "default"
    | "gray"
    | "orange";
  size?: "sm" | "lg";
  disabled?: boolean;
  children: string;
} & React.HTMLAttributes<HTMLSpanElement | HTMLButtonElement>;

const Chip = React.forwardRef<HTMLElement, ChipProps>(
  (
    { color = "default", size = "sm", children, disabled = false, ...props },
    ref
  ) => {
    const Component = props.onClick ? "button" : "span";

    const colorStyles = {
      primary: "bg-primary-100 text-primary-800",
      green: "bg-green-100 text-green-800",
      red: "bg-red-100 text-red-800",
      yellow: "bg-yellow-100 text-yellow-800",
      default: "bg-gray-100 text-gray-800",
      gray: "bg-gray-200 text-gray-900",
      orange: "bg-yellow-400 text-yellow-900",
    };

    const sizeStyles = {
      sm: "text-xs px-2.5",
      lg: "text-sm px-3",
    };

    return (
      <Component
        ref={ref as React.LegacyRef<never>}
        className={classNames(
          "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 inline-flex items-center rounded-full font-medium py-0.5 max-w-full",
          colorStyles[color],
          sizeStyles[size],
          disabled && "cursor-default"
        )}
        title={children}
        {...props}
        disabled
      >
        <span className="truncate text">{children}</span>
      </Component>
    );
  }
);

export { Chip };
