import { useQuery } from "react-query";
import { Form } from "../models/form.model";
import { Response } from "../models/response.model";
import { FetchError, useApi } from "./useApi";

const useForm = (formId: string) => {
  const { fetchWithToken } = useApi();

  const getForm = (id: string) => {
    return fetchWithToken<Response<Form>>(`/forms/${id}`);
  };

  const formQuery = useQuery(["form", formId], () => getForm(formId), {
    staleTime: 1000 * 60 * 60, // These are big and change rarely so stale after 1 hour
    retry: (_, error) =>
      error instanceof FetchError && error.response.status >= 500,
  });

  return {
    formQuery,
  };
};

export { useForm };
