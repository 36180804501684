import * as React from "react";

import { DecodedToken } from "../models/decoded-token.model";

const TokenContext = React.createContext<{
  decodedToken: DecodedToken;
} | null>(null);

const useToken = () => {
  const context = React.useContext(TokenContext);
  if (!context) {
    throw new Error("useTokenProvider must be used inside of TokenContext");
  }

  return context;
};

export { TokenContext, useToken };
