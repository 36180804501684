import React from "react";
import { Characteristics, Allergy } from "../../models/submission.model";
import { allergySymptoms } from "./allergySymptoms";

interface AllergyListProps {
  characteristics: Characteristics;
}

const AllergyList: React.FC<AllergyListProps> = ({ characteristics }) => {
  const allergies = (
    characteristics["patients_allergy_list"]?.value as Allergy[] | undefined
  )?.filter((allergy) => Boolean(allergy));

  const getAllergyType = (allergy: Allergy) => {
    const symptomsText = allergySymptoms
      .filter((symptom) => allergy.symptoms.includes(symptom.id))
      .map((symptom) => symptom.text)
      .join(" ");

    return symptomsText.length > 0
      ? symptomsText
      : "Unknown reaction. Please confirm with patient.";
  };

  return (
    <>
      {allergies && allergies.length > 0 ? (
        <>
          <tr>
            <td colSpan={2} className="border-2 border-red-500">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-left">Allergen</th>
                    <th className="p-2 text-left">Type</th>
                    <th className="p-2 text-left">Reaction</th>
                  </tr>
                </thead>
                <tbody>
                  {allergies.map((allergy, index) => (
                    <tr key={index}>
                      <td className="p-2 capitalize text-red-500 font-medium">
                        {allergy.allergen}
                      </td>
                      <td className="p-2 capitalize">
                        {allergy.type?.text ??
                          allergy.otherTypeText ??
                          "Unknown type"}
                      </td>
                      <td className="p-2">{getAllergyType(allergy)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td className="p-2">No allergies recorded</td>
        </tr>
      )}
    </>
  );
};

export { AllergyList };
