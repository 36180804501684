import React from "react";
import {
  FaCheckCircle,
  FaCircle,
  FaInfoCircle,
  FaMinusCircle,
} from "react-icons/fa";
import { Task as TaskModel } from "../../models/task.model";
import { Disclosure, Transition } from "@headlessui/react";
import { RadioOption } from "../RadioOption";
import { useTasks } from "../../hooks/useTasks";
import { RadioGroup } from "../RadioGroup";
import { classNames } from "../../utilities/mergeStyles";
import { Button } from "../Button";

type TaskProps = {
  task: TaskModel;
  assessmentId: string;
};

type TaskStatus = "complete" | "unnecessary" | "incomplete";

function Task({ task, assessmentId }: TaskProps): JSX.Element {
  const { updateTaskMutation } = useTasks(assessmentId);

  const [noteState, setNoteState] = React.useState(task.note);

  const getTaskStatus = (task: TaskModel): TaskStatus => {
    if (task.complete) {
      return "complete";
    }
    if (task.unnecessary) {
      return "unnecessary";
    }
    return "incomplete";
  };

  const [taskStatus, setTaskStatus] = React.useState(getTaskStatus(task));

  React.useEffect(() => {
    setTaskStatus(getTaskStatus(task));
  }, [task]);

  const onStatusChange = (status: string) => {
    if (status === "complete") {
      updateTaskMutation.mutate({
        id: task.id,
        task: { complete: true, unnecessary: false },
      });
    }
    if (status === "incomplete") {
      updateTaskMutation.mutate({
        id: task.id,
        task: { complete: false, unnecessary: false },
      });
    }
    if (status === "unnecessary") {
      updateTaskMutation.mutate({
        id: task.id,
        task: { complete: false, unnecessary: true },
      });
    }
  };

  const updateNote = () => {
    updateTaskMutation.mutate({
      id: task.id,
      task: { note: noteState },
    });
  };

  const onNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteState(e.currentTarget.value);
  };

  return (
    <Disclosure>
      <li className="flex flex-1 flex-col transition-height duration-1000 ease-in-out flex-shrink-0">
        <Disclosure.Button className="flex justify-between flex-1 items-center p-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 rounded-md z-10">
          <span>{task.name}</span>
          {taskStatus === "incomplete" && (
            <FaCircle className="w-4 h-4 text-yellow-500"></FaCircle>
          )}
          {taskStatus === "unnecessary" && (
            <FaMinusCircle className="w-4 h-4 text-gray-500"></FaMinusCircle>
          )}
          {taskStatus === "complete" && (
            <FaCheckCircle className="w-4 h-4 text-green-500"></FaCheckCircle>
          )}
        </Disclosure.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <Disclosure.Panel className="px-2 pb-2">
            <div className="bg-primary-400 p-2 text-white rounded-lg flex items-center mb-2">
              <FaInfoCircle className="w-4 h-4 mr-2" />
              {task.description}
            </div>
            <RadioGroup
              value={taskStatus}
              onChange={onStatusChange}
              label="Status"
            >
              <RadioOption value="incomplete" label="Incomplete"></RadioOption>
              <RadioOption value="complete" label="Complete"></RadioOption>
              <RadioOption
                value="unnecessary"
                label="Unnecessary"
              ></RadioOption>
            </RadioGroup>
            <div className="flex flex-col mt-2">
              <div>
                <label
                  htmlFor={`${task.id}-note`}
                  className="block text-sm font-medium text-gray-700"
                >
                  Note
                </label>
              </div>
              <div>
                <input
                  type="text"
                  name={`${task.id}-comment`}
                  id={`${task.id}-comment`}
                  className={classNames(
                    "shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  )}
                  onChange={onNoteChange}
                  value={noteState}
                />
              </div>
            </div>
            <div className="flex justify-end mt-2">
              <Button
                as="button"
                color="primary"
                loading={updateTaskMutation.status === "loading"}
                onClick={() => updateNote()}
              >
                Save
              </Button>
            </div>
          </Disclosure.Panel>
        </Transition>
      </li>
    </Disclosure>
  );
}

export { Task };
