import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

/**
 * Formats a name according to CUI guideliens
 * @param forenames
 * @param surname
 * @param title
 * @returns
 */
const formatName = (
  forenames: string,
  surname: string,
  title?: string | null
): string => {
  title = title ? ` (${title})` : "";
  surname = surname.toUpperCase();
  return `${surname}, ${forenames}${title}`;
};

interface FormatDate {
  date: Date | string;
  format?: string;
  isUTC?: boolean;
  local?: boolean;
}

const getDate = ({ date, format, isUTC = true, local = true }: FormatDate) => {
  return isUTC
    ? local
      ? dayjs.utc(date, format).local()
      : dayjs.utc(date, format)
    : dayjs(date, format);
};

const formatShortDate = (params: FormatDate): string => {
  return getDate(params).format("DD-MMM-YYYY");
};

const formatLongDate = (params: FormatDate): string => {
  return getDate(params).format("DD-MMM-YYYY HH:mm");
};

const formatBorn = (date: Date): string => {
  const dateString = formatShortDate({ date, local: true, isUTC: true });
  const age = dayjs().diff(dayjs(date), "years");
  return `${dateString} (${age})`;
};

const formatDate = (date: Date, format?: string): string =>
  dayjs
    .utc(date)
    .local()
    .format(format ?? "DD-MMM-YYYY")
    .toString();

const parseDate = (input: string, format: string): Date | void => {
  const date = dayjs.utc(input, format ?? "DD-MMM-YYYY").local();
  if (date.isValid()) {
    return date.toDate();
  }
};

export {
  formatName,
  formatShortDate,
  formatLongDate,
  formatDate,
  formatBorn,
  parseDate,
};
