/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Dialog as ReactDialog, Transition } from "@headlessui/react";
import { FaExclamationCircle } from "react-icons/fa";
import { useAssessment } from "../../hooks/useAssessment";
import { Button } from "../Button";
import { ContactMethodRadio } from "../ContactMethodRadio";
import { PatientInviteContactMethod } from "../../models/patientInvite.model";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { Controller, useForm } from "react-hook-form";
import { usePatient } from "../../hooks/usePatient";

type AssessmentAmendDialogProps = {
  patientId: string;
  assessmentId: string;
  open?: boolean;
  onClose?: () => void;
};

const AssessmentAmendDialog = ({
  patientId,
  assessmentId,
  open = false,
  onClose,
}: AssessmentAmendDialogProps) => {
  const {
    patientQuery: { data },
  } = usePatient(patientId);

  const disabledContactMethods = [
    ...(!data?.mobile ? ["sms"] : []),
    ...(!data?.email ? ["email"] : []),
    ...(!data?.address ? ["letter"] : []),
  ];

  const {
    assessmentQuery: { data: assessment },
  } = useAssessment(assessmentId);

  const {
    createPatientInvite,
    createAssessmentInviteMutation: { status, reset },
  } = useAssessmentMutations(assessmentId);

  const cancelButtonRef = React.useRef(null);

  const onSubmit = async (values: {
    contactMethod: Exclude<PatientInviteContactMethod, "clinician">;
  }) => {
    createPatientInvite(values.contactMethod);
  };

  const handleClose = React.useCallback(() => {
    reset();
    if (onClose) {
      onClose();
    }
  }, [reset, onClose]);

  React.useEffect(() => {
    if (status === "error" || status === "success") {
      handleClose();
    }
  }, [status, handleClose]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      contactMethod: assessment?.patient?.mobile ? "sms" : "email",
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <ReactDialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => void {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ReactDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form id="amendAssessmentForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FaExclamationCircle
                      className="h-5 w-5 text-primary-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <ReactDialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Send back to patient
                    </ReactDialog.Title>
                    <div className="mt-2 space-y-2">
                      <div className="text-sm text-gray-500">
                        Send the form back to the patient to amend. This will
                        lock the assessment for you until the patient has
                        submitted their amended form.
                      </div>
                      <div>
                        <Controller
                          control={control}
                          name="contactMethod"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ContactMethodRadio
                                disabledOptions={disabledContactMethods}
                                value={value}
                                onChange={onChange}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    as="button"
                    type="submit"
                    className="ml-2"
                    color="primary"
                    loading={status === "loading"}
                  >
                    Send
                  </Button>
                  <Button
                    as="button"
                    type="button"
                    onClick={() => handleClose()}
                    variant="outlined"
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </ReactDialog>
    </Transition.Root>
  );
};

export { AssessmentAmendDialog };
