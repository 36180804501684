import React from "react";
import { Modal } from "../Modal";
import { ModalContent } from "../ModalContent";
import { ModalActions } from "../ModalActions";
import { Button } from "../Button";
import { PatientSearch } from "../PatientSearch";
import { Patient, PatientInput } from "../../models/patient.model";
import { PatientForm } from "../PatientForm";
import { AssessmentForm } from "../AssessmentForm";
import { useAssessments } from "../../hooks/useAssessments";
import { AssessmentInput } from "../../models/assessment.model";
import { usePatients } from "../../hooks/usePatients";
import { useTenant } from "../../context/TenantContext";

type AssessmentsInviteModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export type Steps = "search" | "addPatient" | "addAssessment" | "editPatient";

const AssessmentsInviteModal = ({
  isOpen,
  setIsOpen,
}: AssessmentsInviteModalProps): JSX.Element => {
  // State that contains the patient selected for the assessment
  const [selectedPatient, setSelectedPatient] = React.useState<
    Patient | undefined
  >();

  const {
    createAssessmentMutation: {
      mutate: createAssessmentMutate,
      status: createAssessmentStatus,
    },
    assessmentsQuery: { refetch },
  } = useAssessments();

  const {
    createPatientMutation: {
      mutate: createPatientMutate,
      status: createPatientStatus,
    },
    updatePatientMutation: {
      mutate: updatePatientMutate,
      status: updatePatientStatus,
    },
  } = usePatients();
  const { tenant } = useTenant();

  const [enableFormStatus, setEnableFormStatus] = React.useState<
    "idle" | "existing" | "none"
  >("idle");

  const [step, setStep] = React.useState<Steps>("search");

  const close = () => {
    setSelectedPatient(undefined);
    setStep("search");
    setEnableFormStatus("idle");
    setIsOpen(false);
  };

  const createAssessment = (values: AssessmentInput) => {
    createAssessmentMutate(
      { assessment: values },
      {
        onSuccess: () => {
          refetch();
          close();
        },
      }
    );
    return;
  };

  const createPatient = (values: PatientInput) => {
    createPatientMutate(
      { patient: values },
      {
        onSuccess: (value) => {
          setSelectedPatient(value.data);
          setStep("addAssessment");
        },
      }
    );
    return;
  };

  const updatePatient = (values: PatientInput) => {
    selectedPatient &&
      updatePatientMutate(
        { patient: values, patientId: selectedPatient.id },
        {
          onSuccess: (value) => {
            setSelectedPatient(value.data);
            setStep("addAssessment");
          },
        }
      );
    return;
  };

  return (
    <>
      {isOpen && (
        <Modal
          title="Add new assessment"
          isOpen={isOpen}
          isDismissable={false}
          onClose={() => setIsOpen(false)}
          fixedHeight
        >
          <ModalContent>
            {step === "search" && (
              <>
                <div className="flex justify-between items-center mt-2">
                  <span className="text-sm font-medium">
                    Search for a patient
                  </span>
                  <Button
                    as="button"
                    type="button"
                    variant="outlined"
                    size="sm"
                    color="primary"
                    onClick={() => setStep("addPatient")}
                  >
                    Add new patient
                  </Button>
                </div>
                <PatientSearch
                  onChange={(patient) => setSelectedPatient(patient)}
                ></PatientSearch>
              </>
            )}
            {step === "addAssessment" && selectedPatient && (
              <>
                <div className="flex flex-col flex-1 min-h-0">
                  <AssessmentForm
                    setStep={setStep}
                    enableFormStatus={enableFormStatus}
                    id="assessmentForm"
                    status={createAssessmentStatus}
                    onSubmit={createAssessment}
                    patient={selectedPatient}
                    setEnableFormStatus={setEnableFormStatus}
                  ></AssessmentForm>
                </div>
              </>
            )}
            {step === "addPatient" && (
              <>
                <div className="flex flex-col flex-1 min-h-0">
                  <PatientForm
                    onSubmit={createPatient}
                    status={createPatientStatus}
                    tenantIdentifierTypes={tenant?.identifierTypes ?? []}
                    id="createPatientForm"
                  />
                </div>
              </>
            )}
            {step === "editPatient" && selectedPatient && (
              <>
                <div className="flex flex-col flex-1 min-h-0">
                  <PatientForm
                    onSubmit={updatePatient}
                    status={updatePatientStatus}
                    patient={selectedPatient}
                    tenantIdentifierTypes={tenant?.identifierTypes ?? []}
                    id="updatePatientForm"
                  />
                </div>
              </>
            )}
          </ModalContent>
          <ModalActions>
            {step === "search" && (
              <>
                <Button
                  as="button"
                  type="button"
                  color="secondary"
                  variant="outlined"
                  className="shadow-none"
                  disabled={
                    createAssessmentStatus === "loading" ||
                    createPatientStatus === "loading"
                  }
                  onClick={() => close()}
                >
                  Cancel
                </Button>
                <Button
                  as="button"
                  type="button"
                  color="primary"
                  disabled={!selectedPatient}
                  onClick={() => setStep("addAssessment")}
                >
                  Select patient
                </Button>
              </>
            )}
            {(step === "addAssessment" || step === "addPatient") && (
              <Button
                as="button"
                type="button"
                color="secondary"
                variant="outlined"
                className="shadow-none"
                disabled={
                  createAssessmentStatus === "loading" ||
                  createPatientStatus === "loading"
                }
                onClick={() => setStep("search")}
              >
                Back
              </Button>
            )}
            {step === "addAssessment" && enableFormStatus === "none" && (
              <Button
                as="button"
                type="submit"
                color="primary"
                disabled={!selectedPatient}
                form="assessmentForm"
                loading={createAssessmentStatus === "loading"}
              >
                Create assessment
              </Button>
            )}
            {step === "addAssessment" && enableFormStatus === "existing" && (
              <Button
                as="button"
                type="button"
                color="primary"
                disabled={!selectedPatient}
                onClick={() => setEnableFormStatus("none")}
              >
                Ignore and continue
              </Button>
            )}
            {step === "addPatient" && (
              <Button
                as="button"
                type="submit"
                color="primary"
                form="createPatientForm"
                loading={createPatientStatus === "loading"}
              >
                Create new patient
              </Button>
            )}
            {step === "editPatient" && (
              <>
                <Button
                  as="button"
                  type="button"
                  color="secondary"
                  variant="outlined"
                  className="shadow-none"
                  disabled={updatePatientStatus === "loading"}
                  onClick={() => setStep("addAssessment")}
                >
                  Back
                </Button>
                <Button
                  as="button"
                  type="submit"
                  color="primary"
                  form="updatePatientForm"
                  loading={updatePatientStatus === "loading"}
                >
                  Save patient
                </Button>
              </>
            )}
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

export { AssessmentsInviteModal };
