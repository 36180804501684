import React from "react";
import { FaFilter } from "react-icons/fa";
import { FilterProps } from "react-table";
import { mergeStyles } from "../../utilities/mergeStyles";
import { useTags } from "../../hooks/useTags";
import { FilterBox } from "../FilterBox";
import { Item } from "@react-stately/collections";

const AssessmentsTableFilterTag: React.FC<
  FilterProps<Record<string, unknown>>
> = ({ column }) => {
  const {
    tagsQuery: { status: tagStatus, data: tagData },
  } = useTags();

  const onItemSelect = (keys: React.Key[]) => {
    column.setFilter(keys);
  };

  const selectedTags =
  tagData
    ?.map((tag) => tag.tagId)
    .filter((id) => column.filterValue?.includes(id)) ?? [];

  return (
    <FilterBox
    placement="bottom end"
    loading={tagStatus === "loading"}
    button={
      <button
        className={mergeStyles([
          "flex w-10 items-center justify-center pr-1 pt-2 pb-2 pl-1 outline-none focus-visible:ring-2 focus-visible:ring-primary-400 rounded-md mr-1 mt-1 mb-1 hover:text-gray-500",
          selectedTags?.length > 0 ? "text-gray-500" : "text-gray-300",
        ])}
        aria-label={`Filter by ${column.Header?.toString()}`}
      >
        <FaFilter></FaFilter>
      </button>
    }
    label="Tags"
    items={tagData?.map(tag => ({id: tag.tagId, value: tag.value})) ?? []}
    selectedKeys={selectedTags}
    onMultipleChange={(keys) => onItemSelect(keys)}
    selectionMode="multiple"
  >
    {(item) => <Item>{item.value}</Item>}
  </FilterBox>
  );
};

export { AssessmentsTableFilterTag };
