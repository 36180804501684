/**
 * @copyright 2021 Ultramed Ltd
 * @author Nicolaas Prinsloo <nick@ultramed.co>
 */

/**
 * View model for Users
 * Based on Practitioner entity but renamed as not all users are practitioners.
 * This better maps to the actual use of the concept in the application.
 * Users will be stored entirely in Auth0 in the future but we need to
 * support the old system for now.
 */
type User = Readonly<{
  id: string;
  givenName?: string;
  familyName?: string;
  prefix?: string;
  email: string;
  services: string[];
  isConsultant: boolean;
  isAssignable: boolean;
  /** Auth0 ID for the user */
  authId: string;
}>;

type UserInput = Omit<User, "id" | "authId" | "authRoles"> & {
  roles: string[];
  orgId: string;
};

type UserUpdate = Partial<
  Pick<
    User,
    | "email"
    | "givenName"
    | "familyName"
    | "prefix"
    | "services"
    | "isConsultant"
    | "isAssignable"
  >
> & {
  id: string;
  orgId: string;
  authRoles: string[];
};

const makeUser = (user: User): User => Object.freeze(user);
const makeUserInput = (user: UserInput) => Object.freeze(user);
const makeUserUpdate = (userUpdate: UserUpdate) => Object.freeze(userUpdate);

export type { User, UserInput, UserUpdate };
export { makeUser, makeUserInput, makeUserUpdate };
