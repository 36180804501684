import { PageContainer } from "../../components/PageContainer";
import { PageBody } from "../../components/PageBody";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useAssessment } from "../../hooks/useAssessment";
import { Spinner } from "../../components/Spinner";
import { Error } from "../../components/Error";
import { FetchError } from "../../hooks/useApi";
import { NotFound } from "../../components/NotFound";
import { Breadcrumb } from "../../components/Breadcrumb";
import { BreadcrumbItem } from "../../components/BreadcrumbItem";
import { formatName } from "../../utilities/cuiFormat";
import { ClinicalReport } from "../../components/ClinicalReport";
import { Documents } from "../../components/Documents";
import { Tabs } from "../../components/RoutableTabs";
import { AssessmentSummary } from "../../components/AssessmentSummary";
import { AssessmentHeader } from "../../components/AssessmentHeader";
import { usePermissions } from "../../hooks/usePermissions";
import { PermissionGuard } from "../../components/PermissionGuard";

function Assessment(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { hasPermissions } = usePermissions();
  const {
    assessmentQuery: { status, data: assessment, error },
  } = useAssessment(id);
  const { path, url } = useRouteMatch();

  return (
    <PageContainer>
      {status === "loading" && (
        <div className="flex justify-center items-center flex-1">
          <Spinner size="xl" />
        </div>
      )}
      {status === "error" &&
        error instanceof FetchError &&
        error.response.status === 404 && <NotFound />}
      {status === "error" &&
        error instanceof FetchError &&
        error.response.status !== 404 && <Error />}
      {assessment && !assessment.encounterId && !assessment.legacyExpected && (
        <Error>
          This assessment does not have a valid encounter. Please contact{" "}
          <a
            href="https://support.ultramed.co"
            target="_blank"
            rel="noreferrer"
            className="text-primary-500"
          >
            Ultramed support
          </a>
          .
        </Error>
      )}
      {status === "success" && assessment && assessment.patient && (
        <>
          <Breadcrumb>
            <BreadcrumbItem href="/assessments">Assessments</BreadcrumbItem>
            <BreadcrumbItem href={`/assessments/${id}`}>
              {formatName(
                assessment.patient.givenName ?? "",
                assessment.patient.familyName ?? "",
                assessment.patient.prefix
              )}
            </BreadcrumbItem>
          </Breadcrumb>
          <AssessmentHeader assessment={assessment}></AssessmentHeader>
          <PageBody>
            <>
              <Tabs
                patientId={assessment.patient.id}
                tabs={[
                  {
                    name: "Summary",
                    href: `${url}/summary`,
                    current: true,
                  },
                  {
                    name: "Assessment",
                    href: `${url}/assessment`,
                    current: false,
                    disabled:
                      assessment.status === "draft" ||
                      !assessment.encounterId ||
                      !hasPermissions(["read:form", "write:submission"]),
                  },
                  {
                    name: "Documents",
                    href: `${url}/documents`,
                    current: false,
                    disabled:
                      assessment.status === "draft" ||
                      !assessment.encounterId ||
                      !hasPermissions(["read:submission"]),
                  },
                ]}
                assessmentId={assessment.id}
                assessmentStatus={assessment.status}
                disableActions={
                  !assessment.encounterId || assessment.legacyExpected
                }
                archived={assessment.archived}
              ></Tabs>

              <div className="mt-4 flex flex-col min-h-0">
                <Switch>
                  <Route exact path={`${path}/summary`}>
                    <AssessmentSummary assessment={assessment} />
                  </Route>

                  <Route exact path={`${path}/assessment`}>
                    <PermissionGuard
                      requiredPermissions={["read:form", "write:submission"]}
                      fallback
                    >
                      {assessment.status !== "draft" &&
                        assessment.submissionId &&
                        assessment.encounterId && (
                          <ClinicalReport
                            assessmentId={assessment.id}
                            summaryFormId={
                              assessment.service.forms.summaryReportId
                            }
                            fullReportId={assessment.service.forms.fullReportId}
                            clinicalNotesId={
                              assessment.service.forms.clinicalNotesId
                            }
                            submissionId={assessment.submissionId}
                            encounterId={assessment.encounterId}
                          />
                        )}
                    </PermissionGuard>
                  </Route>
                  <Route exact path={`${path}/documents`}>
                    <PermissionGuard
                      requiredPermissions={["read:submission"]}
                      fallback
                    >
                      {assessment.status !== "draft" &&
                        assessment.submissionId &&
                        assessment.encounterId && (
                          <Documents assessmentId={id}></Documents>
                        )}
                    </PermissionGuard>
                  </Route>
                  <Redirect from="/" to={`${url}/summary`} />
                </Switch>
              </div>
            </>
          </PageBody>
        </>
      )}
    </PageContainer>
  );
}

export { Assessment };
