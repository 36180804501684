import { useQuery } from "react-query";
import { Assessment } from "../models/assessment.model";
import { Response } from "../models/response.model";
import { FetchError, useApi } from "./useApi";

const useAssessment = (assessmentId: string) => {
  const { fetchWithToken } = useApi();

  const assessmentKey = ["assessment", assessmentId];

  const getAssessment = (id: string, signal?: AbortSignal) => {
    return fetchWithToken<Response<Assessment>>(`/assessments/${id}`, {
      signal,
    });
  };

  const assessmentQuery = useQuery(
    assessmentKey,
    ({ signal }) => getAssessment(assessmentId, signal),
    {
      staleTime: 1000 * 60, // Stale after 1 minute
      select: (data) => data.data,
      retry: (_, error) =>
        error instanceof FetchError && error.response.status >= 500,
    }
  );

  return {
    assessmentQuery,
  };
};

export { useAssessment };
