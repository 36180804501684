import React from "react";
import { FlatSetting, Private } from "../../models/form.model";

interface TextParagraphProps {
  name: string;
  settings: FlatSetting;
  privateSettings: Private;
}

const TextParagraph: React.FC<TextParagraphProps> = ({
  settings,
  privateSettings,
}) => {
  return privateSettings.enableWhen &&
    Array.isArray(privateSettings.enableWhen) &&
    privateSettings.enableWhen.length > 1 ? null : (
    <p className="text-base text-center">{settings.text?.value}</p>
  );
};

export { TextParagraph };
