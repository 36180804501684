import dayjs from "dayjs";
import React from "react";
import { FaCalendarDay } from "react-icons/fa";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { formatShortDate } from "../../utilities/cuiFormat";
import { DatePicker } from "../DatePicker";
import { Pill } from "../Pill";

type TciDateProps = {
  tciDate: Date | undefined;
  assessmentId: string;
  disabled?: boolean;
};

function TciDate({
  tciDate,
  assessmentId,
  disabled = false,
}: TciDateProps): JSX.Element {
  const { updateTci } = useAssessmentMutations(assessmentId);

  const color = React.useMemo(() => {
    if (tciDate) {
      const daysInFuture = dayjs(tciDate).diff(dayjs(), "days");
      if (daysInFuture < 7) {
        return "red";
      }
      if (daysInFuture < 14) {
        return "yellow";
      }
      return "green";
    }
    return "green";
  }, [tciDate]);

  return (
    <DatePicker
      value={dayjs(tciDate).toDate()}
      showClear
      showToday={false}
      placement="bottom-start"
      disabledDays={{
        before: new Date(),
        after: dayjs().add(2, "year").toDate(),
      }}
      onChange={(value) => updateTci(value)}
      button={
        <Pill color={color} disabled={disabled}>
          <FaCalendarDay className="mr-2" />
          {tciDate && `TCI ${formatShortDate({ date: tciDate })}`}
          {!tciDate && !disabled && `Add TCI date`}
          {!tciDate && disabled && `No TCI date`}
        </Pill>
      }
    />
  );
}

export { TciDate };
