/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FetchError, useApi } from "./useApi";
import { useDebounce } from "./useDebounce";
import { Response } from "../models/response.model";
import { Patient, PatientInput } from "../models/patient.model";
import { useToasts } from "../context/ToastProvider";
import { IdentifierType } from "../models/identifier-type.model";

const usePatients = () => {
  const queryClient = useQueryClient();

  const { fetchWithToken } = useApi();
  const { addToast } = useToasts();

  // Search Patient
  const [patientSearchState, setPatientSearchState] = React.useState<
    string | undefined
  >();
  const debouncedSearchInput = useDebounce(patientSearchState, 400);

  /**
   * API call that queries a patient using a free text query and returns
   * an ordered array of patients ranked according to the quality of the match
   * @param params
   * @returns
   */
  const searchPatients = async (
    params?: { query: string },
    signal?: AbortSignal
  ) => {
    const result = await fetchWithToken<Response<Patient[]>>(
      "/patients/search",
      {
        query: {
          ...params,
        },
        signal,
      }
    );
    return result.data;
  };

  /**
   * react-query that manages the state for patient searches
   */
  const searchPatientsQuery = useQuery<Patient[], Error>(
    ["patients", debouncedSearchInput],
    ({ signal }) =>
      searchPatients({ query: debouncedSearchInput ?? "" }, signal),
    {
      staleTime: 1000 * 60, // Stale after 1 minute
      enabled: Boolean(debouncedSearchInput), // Don't automatically run
      placeholderData: [],
    }
  );

  // Create Patient

  /**
   * API call to create a patient
   */
  const createPatient = async ({ patient }: { patient: PatientInput }) => {
    return fetchWithToken<Response<Patient>>("/patients", {
      method: "POST",
      body: patient,
    });
  };

  /**
   * API call to update a patient
   */
  const updatePatient = async ({
    patient,
    patientId,
  }: {
    patient: PatientInput;
    patientId: string;
  }) => {
    return fetchWithToken<Response<Patient>>(`/patients/${patientId}`, {
      method: "PATCH",
      body: patient,
    });
  };

  /**
   * react-query mutation for creating patients
   */
  const createPatientMutation = useMutation(createPatient, {
    onError: async (err) => {
      const error =
        err instanceof FetchError && err.response.status === 409
          ? "Failed to create new patient. This patient already exists."
          : "Failed to create new patient. If this error persists, please contact Ultramed.";
      addToast({
        type: "error",
        content: error,
      });
    },
  });

  /**
   * react-query mutation for creating patients
   */
  const updatePatientMutation = useMutation(updatePatient, {
    onError: async (err) => {
      addToast({
        type: "error",
        content:
          "Failed to update patient. If this error persists, please contact Ultramed.",
      });
    },
    onSettled: (result) => {
      const patientKey = ["patient", result?.data.id];
      queryClient.invalidateQueries(patientKey);
    },
  });

  // Check if patient exists

  /**
   * API call that checks if a patient exists and returns true if they do,
   * and false if they don't.
   * This is used to validate IDs to prevent duplicate patients.
   * We don't use react-query as this is not something we need to cache
   * and is fed directly to yup.test() which takes a promise that resolves to a boolean
   * @see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
   * @param params
   * @returns
   */
  const patientExists = async (params: {
    id: string;
    idTypeId: IdentifierType["id"];
  }) => {
    try {
      const { id, ...rest } = params;
      await fetchWithToken<Response<Patient>>(`/patients/${id}`, {
        query: {
          ...rest,
        },
      });
      return true;
    } catch (error) {
      // If this is a 404, return true instead of an error. In all other cases
      // throw the error
      if (error instanceof FetchError && error.response.status === 404) {
        return false;
      }
      throw error;
    }
  };

  return {
    searchPatientsQuery,
    setPatientSearchState,
    patientSearchState,
    patientExists,
    createPatientMutation,
    updatePatientMutation,
    debouncedSearchInput,
  };
};

export { usePatients };
