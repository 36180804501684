import { useDocuments } from "../../hooks/useDocuments";
import { formatLongDate, formatName } from "../../utilities/cuiFormat";
import { classNames } from "../../utilities/mergeStyles";
import { DocumentAction } from "../DocumentAction";
import { DocumentExport } from "../DocumentExport";
import { Error } from "../Error";
import { Spinner } from "../Spinner";

type DocumentsProps = {
  assessmentId: string;
};

function Documents({ assessmentId }: DocumentsProps) {
  const {
    documentsQuery: { status, data },
  } = useDocuments(assessmentId);

  return (
    <>
      {status === "error" && <Error />}
      <div className="mb-4 flex justify-end">
        <DocumentExport assessmentId={assessmentId}></DocumentExport>
      </div>
      <>
        <div className="align-middle w-full overflow-x-auto shadow">
          <div className="sm:rounded-lg flex flex-col border-b min-h-0 border-gray-200">
            <table className="table-fixed min-w-full divide-y divide-gray-200 min-h-0">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="w-1/4 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    By
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {status === "loading" && (
                  <tr>
                    <td colSpan={4}>
                      <div className="flex justify-center items-center flex-col my-2">
                        <Spinner size="xl" />
                      </div>
                    </td>
                  </tr>
                )}

                {status === "success" && data?.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <div className="flex justify-center items-center flex-col my-2">
                        <p className="text-xl font-medium mt-2 text-gray-600">
                          No documents
                        </p>
                        <p className="text-sm text-gray-400 mt-1">
                          There aren't any documents for this assessment yet
                        </p>
                        <p className="text-sm text-gray-400">
                          Use the 'Export' button to export one
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
                {status === "success" &&
                  data &&
                  data?.length > 0 &&
                  data
                    .sort((a, b) => (a.created > b.created ? -1 : 1))
                    .map((doc) => (
                      <tr key={doc.id}>
                        <td
                          className={classNames(
                            "w-1/2 px-6 py-4 text-sm",
                            doc.status === "failed"
                              ? "text-gray-500 italic"
                              : "text-gray-900 font-medium"
                          )}
                        >
                          {doc.description}
                        </td>
                        <td
                          className={classNames(
                            "w-1/8 px-6 py-4 whitespace-nowrap text-sm",
                            doc.status === "failed"
                              ? "text-gray-400"
                              : "text-gray-600"
                          )}
                        >
                          {formatLongDate({ date: doc.created })}
                        </td>
                        <td
                          className={classNames(
                            "w-1/8 px-6 py-4 whitespace-nowrap text-sm",
                            doc.status === "failed"
                              ? "text-gray-400"
                              : "text-gray-600"
                          )}
                        >
                          {formatName(
                            doc.author?.givenName ?? "",
                            doc.author?.familyName ?? "",
                            doc.author?.prefix
                          )}
                        </td>
                        <td className="w-1/8 px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <DocumentAction
                            doc={doc}
                            assessmentId={assessmentId}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </>
  );
}

export { Documents };
