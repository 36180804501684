import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { FaEllipsisV, FaUserEdit, FaUser } from "react-icons/fa";

import { User } from "../../models/user.model";
import { EditUserModal } from "../EditUserModal";
import { MenuList } from "../MenuList";
import { MenuListItem } from "../MenuListItem";
import { ViewUserModal } from "../ViewUserModal";

type UserMenuProps = {
  user: User;
};

const UserActionsMenu = ({ user }: UserMenuProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [viewUserModalOpen, setViewUserModalOpen] = React.useState(false);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button>
          <FaEllipsisV></FaEllipsisV>
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-10">
            <MenuList>
              <Menu.Item>
                {({ active }) => (
                  <MenuListItem
                    focused={active}
                    icon={FaUser}
                    onClick={() => setViewUserModalOpen(true)}
                  >
                    View user
                  </MenuListItem>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <MenuListItem
                    focused={active}
                    icon={FaUserEdit}
                    onClick={() => setModalOpen(true)}
                  >
                    Edit user
                  </MenuListItem>
                )}
              </Menu.Item>
            </MenuList>
          </Menu.Items>
        </Transition>
      </Menu>

      {modalOpen && (
        <EditUserModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          user={user}
        ></EditUserModal>
      )}
      {viewUserModalOpen && (
        <ViewUserModal
          isOpen={viewUserModalOpen}
          setIsOpen={setViewUserModalOpen}
          user={user}
        ></ViewUserModal>
      )}
    </>
  );
};

export { UserActionsMenu };
