import { FaAngleLeft, FaBars, FaLaptopMedical, FaUsers } from "react-icons/fa";
import { classNames } from "../../utilities/mergeStyles";
import { Logo } from "../Logo";
import { NavLink } from "../NavLink";
import { PermissionGuard } from "../PermissionGuard";
import { UserMenu } from "../UserMenu";

type SideNavProps = {
  collapsed?: boolean;
  className?: string;
  toggleCollapsed?: (collapsed: boolean) => void;
};

function SideNav({
  className,
  collapsed = false,
  toggleCollapsed,
}: SideNavProps): JSX.Element {
  return (
    <div className={classNames("flex flex-auto flex-col", className)}>
      <div
        className={classNames("pt-6 h-12", collapsed && "flex justify-center")}
      >
        {collapsed ? (
          <button
            aria-hidden
            className="text-white rounded-sm text-2xl focus:ring-2 focus:ring-white focus:ring-opacity-75 focus:ring-offset-2 focus:ring-offset-primary-400 focus:outline-none"
            onClick={() => toggleCollapsed && toggleCollapsed(!collapsed)}
          >
            <span className="sr-only">Expand side menu</span>
            <FaBars />
          </button>
        ) : (
          <div className="flex justify-between px-6">
            <Logo className="text-white w-40 pt-0.5" />{" "}
            <button
              aria-hidden
              className="text-white text-2xl w-8 h-8 rounded-sm focus:ring-2 focus:ring-white focus:ring-opacity-75 focus:ring-offset-2 focus:ring-offset-primary-400 focus:outline-none flex justify-center items-center"
              onClick={() => toggleCollapsed && toggleCollapsed(!collapsed)}
            >
              <span className="sr-only">Collapse side menu</span>
              <FaAngleLeft />
            </button>
          </div>
        )}
      </div>
      <ul className="flex flex-auto flex-col mr-4 ml-4 mt-6 space-y-1">
        <NavLink
          label="Assessments"
          icon={FaLaptopMedical}
          to="/assessments"
          exact
          collapsed={collapsed}
        />
        <PermissionGuard requiredPermissions={["manage:users"]}>
          <NavLink
            label="Users"
            icon={FaUsers}
            to="/users"
            exact
            collapsed={collapsed}
          ></NavLink>
        </PermissionGuard>
      </ul>
      <div className="flex flex-col justify-center">
        <UserMenu collapsed={collapsed} />
      </div>
    </div>
  );
}

export { SideNav };
