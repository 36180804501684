import * as React from "react";
import { useTenant } from "../../context/TenantContext";

import { IdentifierType } from "../../models/identifier-type.model";
import { formatIdentifier } from "../../utilities/patient-identifier";

type PatientIdentifierProps = {
  identifierValue: string;
  identifierId: IdentifierType["id"];
  prefixClassName?: string;
  valueClassName?: string;
  prefixFormatter?: (prefix: string) => string;
} & React.HTMLAttributes<HTMLSpanElement>;

const PatientIdentifier = ({
  identifierValue,
  identifierId,
  prefixClassName,
  valueClassName,
  prefixFormatter,
  ...rest
}: PatientIdentifierProps) => {
  const { tenant } = useTenant();

  const matchingIdType = tenant.identifierTypes?.find(
    (idType) => idType.id === identifierId
  );

  return matchingIdType?.shortName && matchingIdType ? (
    <span {...rest}>
      <span className={prefixClassName}>
        {prefixFormatter
          ? prefixFormatter(matchingIdType.shortName)
          : matchingIdType.shortName}
      </span>{" "}
      <span className={valueClassName}>
        {formatIdentifier(identifierValue, matchingIdType)}
      </span>
    </span>
  ) : (
    <span {...rest}>{identifierValue}</span>
  );
};

export { PatientIdentifier };
