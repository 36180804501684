import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "../../utilities/mergeStyles";

type TabProps = {
  name: string;
  href: string;
};

const Tab = ({ name, href }: TabProps) => {
  const [isCurrent, setCurrent] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setCurrent(location.pathname === href);
  }, [location, href]);

  return (
    <Link
      key={name}
      to={href}
      className={classNames(
        isCurrent
          ? 'border-primary-500 text-primary-600'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
      )}
      aria-current={isCurrent ? 'page' : undefined}
    >
      {name}
    </Link>
  );
};

export { Tab };
