import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";
import { FlatSetting } from "../../models/form.model";
import { Characteristics } from "../../models/submission.model";
import { classNames } from "../../utilities/mergeStyles";

type TextFieldValue = {
  value: string | number | undefined;
  characteristics: Characteristics;
};
interface TextFieldProps {
  control: Control<FieldValues>;
  settings: FlatSetting;
  onChange?: (value: unknown) => void;
  name: string;
  editing?: boolean;
  characteristics: Characteristics;
  answerCharacteristics?: string[];
  value: TextFieldValue;
}

const ControlledTextField: React.FC<TextFieldProps> = ({
  name,
  settings,
  onChange,
  editing,
  characteristics,
  answerCharacteristics,
  value,
}) => {
  const generateCharacteristics = (
    value: string | number | undefined
  ): Characteristics =>
    answerCharacteristics?.reduce<Characteristics>(
      (acc, curr) => ({
        ...acc,
        ...(value ? { [curr]: { value } } : {}),
      }),
      {}
    ) ?? {};

  const defaultValue = React.useMemo(
    () =>
      answerCharacteristics?.reduce<string | number>((acc, curr) => {
        const charValue = characteristics[curr];
        const result =
          charValue && charValue.value && typeof charValue.value !== "object"
            ? charValue.value
            : acc;

        return typeof result === "number" ? result : result.toString();
      }, ""),
    [answerCharacteristics, characteristics]
  );

  const [fieldState, setFieldState] = React.useState<TextFieldValue>(
    () =>
      value ?? {
        value: defaultValue,
        characteristics: generateCharacteristics(defaultValue),
      }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldState({
      value: e.currentTarget.value,
      characteristics: generateCharacteristics(e.currentTarget.value),
    });
  };

  // Clear the field value if the field is unmounted.
  React.useEffect(() => {
    return () => {
      if (onChange) {
        onChange("");
      }
    };
  }, [onChange]);

  React.useEffect(() => {
    if (onChange) {
      onChange(fieldState);
    }
  }, [fieldState, onChange]);

  return (
    <div className="flex flex-col mb-2">
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {settings.label?.value}
        </label>
      </div>
      <div>
        <input
          type="text"
          disabled={!editing}
          id={name}
          className={classNames(
            "shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md",
            !editing && "bg-gray-100"
          )}
          onChange={handleChange}
          value={fieldState.value}
        />
      </div>
    </div>
  );
};

const TextField: React.FC<TextFieldProps> = (props) => {
  const { name, control } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ControlledTextField {...props} value={value} onChange={onChange} />
      )}
    />
  );
};
export { TextField };
