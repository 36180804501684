import { classNames } from "../../utilities/mergeStyles";

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> & {
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
};

function Heading({ as = "h1", className, ...rest }: HeadingProps): JSX.Element {
  const Component = as;
  return (
    <Component
      className={classNames(
        "text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate",
        className
      )}
      {...rest}
    />
  );
}

export { Heading };
