import React, { Fragment } from "react";
import { Listbox } from "@headlessui/react";
import { usePopper } from "react-popper";
import { FaFilter, FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { FilterProps } from "react-table";
import { mergeStyles } from "../../utilities/mergeStyles";
import { useUsers } from "../../hooks/useUsers";
import { formatName } from "../../utilities/cuiFormat";
import { Spinner } from "../Spinner";
import { User } from "../../models/user.model";

const AssessmentsTableFilterConsultant: React.FC<
  FilterProps<Record<string, unknown>>
> = ({ column }) => {
  const {
    consultantsQuery: { status: consultantStatus, data: consultantData },
  } = useUsers();

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLUListElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const onItemSelect = (item: User) => {
    if (column.filterValue === item.id) {
      column.setFilter(undefined);
    } else {
      column.setFilter(item.id);
    }
  };

  const selectedConsultant = consultantData?.find(
    (consultant) => consultant.id === column.filterValue
  );

  return (
    <Listbox value={selectedConsultant} onChange={onItemSelect}>
      <div className="relative">
        <Listbox.Label className="sr-only">Filter by consultant</Listbox.Label>
        <Listbox.Button
          ref={setReferenceElement}
          className={mergeStyles([
            "flex w-10 items-center justify-center pr-1 pt-2 pb-2 pl-1 outline-none focus-visible:ring-2 focus-visible:ring-primary-400 rounded-md mr-1 mt-1 mb-1 hover:text-gray-500",
            column.filterValue !== undefined
              ? "text-gray-500"
              : "text-gray-300",
          ])}
          aria-label={`Filter by ${column.Header?.toString()}`}
        >
          <FaFilter></FaFilter>
        </Listbox.Button>
        <Listbox.Options
          ref={setPopperElement}
          style={styles.popper}
          className="z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none w-56"
          {...attributes.popper}
        >
          {consultantStatus === "success" &&
            consultantData &&
            consultantData.length > 0 &&
            consultantData.map((consultant) => (
              <Listbox.Option
                key={consultant.id}
                className={({ active }) =>
                  mergeStyles([
                    active ? "text-white bg-primary-400" : "text-gray-500",
                    "cursor-default select-none flex py-2 pl-4 pr-4 justify-between",
                  ])
                }
                value={consultant}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      aria-hidden
                      className={mergeStyles([
                        selected ? "font-semibold" : "font-normal",
                        "block truncate",
                      ])}
                    >
                      {formatName(
                        consultant.givenName ?? "",
                        consultant.familyName ?? "",
                        consultant.prefix
                      )}
                    </span>
                    <span className="sr-only">{`${consultant.prefix} ${consultant.givenName} ${consultant.familyName}`}</span>

                    {selected ? (
                      <span
                        className={mergeStyles([
                          active ? "text-white" : "text-primary-400",
                          "inset-y-0 left-0 flex items-center pl-1.5",
                        ])}
                      >
                        <FaCheck className="h-3 w-3" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          {consultantStatus === "success" &&
            consultantData &&
            consultantData.length === 0 && (
              <li className="text-gray-500 cursor-default select-none flex py-2 pl-4 pr-4 justify-between">
                <span aria-hidden className="block truncate font-normal">
                  No consultants found
                </span>
              </li>
            )}
          {consultantStatus === "error" && (
            <li className="cursor-default select-none flex py-2 pl-4 pr-4 justify-between items-center">
              <FaExclamationTriangle className="text-red-500 "></FaExclamationTriangle>
              <span aria-hidden className="block truncate font-normal">
                Error fetching consultants
              </span>
            </li>
          )}
          {consultantStatus === "loading" && (
            <div className="w-full h-20 flex justify-center items-center">
              <Spinner />
            </div>
          )}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export { AssessmentsTableFilterConsultant };
