import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DayPicker, { Modifier, Modifiers } from "react-day-picker";
import classes from "./DatePicker.module.css";
import { classNames } from "../../utilities/mergeStyles";
import { usePopper } from "react-popper";
import { ClickAwayListener } from "../ClickAwayListener";
import { Placement } from "@popperjs/core";
import { Button } from "../Button";

dayjs.extend(utc);

type DatePickerProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
> & {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  button?: React.ReactNode;
  modifiers?: Partial<Modifiers>;
  disabledDays?: Modifier | Modifier[];
  placement?: Placement;
  showClear?: boolean;
  showToday?: boolean;
};

const DatePicker = ({
  value,
  onChange,
  button,
  modifiers,
  disabledDays,
  placement = "auto-end",
  showClear = false,
  showToday = true,
}: DatePickerProps): JSX.Element => {
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-4, 6],
        },
      },
    ],
  });

  const handleDayClick = (value?: Date) => {
    onChange(value);
    setPickerOpen(false);
  };

  React.useEffect(() => {
    if (!pickerOpen) {
      referenceElement?.focus();
    }
  }, [pickerOpen, referenceElement]);

  return (
    <>
      {!button && (
        <button
          aria-hidden
          type="button"
          ref={setReferenceElement}
          onClick={() => setPickerOpen(!pickerOpen)}
          className="w-8 h-8 flex justify-center items-center group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 rounded-sm"
        >
          <span>
            <FaCalendarAlt className="text-gray-600 group-hover:text-primary-400" />
          </span>
        </button>
      )}
      {button &&
        React.isValidElement(button) &&
        React.cloneElement(button, {
          onClick: () => setPickerOpen(!pickerOpen),
          ref: setReferenceElement,
        })}
      {pickerOpen && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-20"
        >
          <ClickAwayListener
            onClickAway={(e) => {
              setPickerOpen(false);
            }}
          >
            <div className="bg-white overflow-hidden shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 flex flex-col p-2">
              <DayPicker
                todayButton={showToday ? "Today" : undefined}
                selectedDays={value}
                modifiers={modifiers}
                disabledDays={disabledDays}
                onDayClick={handleDayClick}
                classNames={{
                  container: classes.DayPicker,
                  wrapper: classes["DayPicker-wrapper"],
                  interactionDisabled:
                    classes["DayPicker--interactionDisabled"],
                  months: classes["DayPicker-Months"],
                  month: classes["DayPicker-Month"],
                  navBar: classes["DayPicker-NavBar"],
                  navButtonPrev: classNames(
                    classes["DayPicker-NavButton"],
                    classes["DayPicker-NavButton--prev"]
                  ),
                  navButtonNext: classNames(
                    classes["DayPicker-NavButton"],
                    classes["DayPicker-NavButton--next"]
                  ),
                  navButtonInteractionDisabled:
                    classes["DayPicker-NavButton--interactionDisabled"],
                  caption: classes["DayPicker-Caption"],
                  weekdays: classes["DayPicker-Weekdays"],
                  weekdaysRow: classes["DayPicker-WeekdaysRow"],
                  weekday: classes["DayPicker-Weekday"],
                  body: classes["DayPicker-Body"],
                  week: classes["DayPicker-Week"],
                  weekNumber: classes["DayPicker-WeekNumber"],
                  day: classes["DayPicker-Day"],
                  footer: classes["DayPicker-Footer"],
                  todayButton: classes["DayPicker-TodayButton"],
                  today: classes["today"],
                  selected: classes["selected"],
                  disabled: classes["disabled"],
                  outside: classes["outside"],
                }}
              ></DayPicker>
              {showClear && (
                <div className="flex justify-end">
                  <Button
                    as="button"
                    size="sm"
                    variant="text"
                    color="primary"
                    className="shadow-none"
                    onClick={() => handleDayClick()}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};

export { DatePicker };
