import React, { Fragment } from "react";
import { Listbox } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import { classNames } from "../../utilities/mergeStyles";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";

type SelectProps = {
  value?: string | number;
  currentName?: string;
  placeholder?: string;
  onChange: (value: string | number) => void;
  label: string;
  showLabel?: boolean;
  children: React.ReactNode;
  loading?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  button?: React.ReactNode;
  placement?: Placement;
};

const Select = ({
  onChange,
  value,
  currentName,
  placeholder = "Select an option",
  label,
  showLabel = true,
  children,
  loading = false,
  autoFocus = false,
  error = false,
  button,
  placement = "bottom-start",
}: SelectProps): JSX.Element => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-1, -4],
        },
      },
    ],
  });

  React.useEffect(() => {
    if (autoFocus) {
      referenceElement?.focus();
    }
  }, [autoFocus, referenceElement]);

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label
            className={classNames(
              "block text-sm font-medium text-gray-700",
              !showLabel && "sr-only"
            )}
          >
            {label}
          </Listbox.Label>{" "}
          {!button && (
            <div className="mt-1" ref={setReferenceElement}>
              <Listbox.Button
                className={classNames(
                  "bg-white relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm",
                  error
                    ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                    : "focus:ring-primary-400 focus:border-primary-400 border-gray-300"
                )}
              >
                {currentName && (
                  <span className="block truncate">{currentName}</span>
                )}
                {!currentName && placeholder && (
                  <span className="block truncate">{placeholder}</span>
                )}
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <FaChevronDown
                    className="h-3 w-3 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            </div>
          )}
          {button && React.isValidElement(button) && (
            <Listbox.Button as="div" ref={setReferenceElement}>
              {button}
            </Listbox.Button>
          )}
          {open && (
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-20"
            >
              <div className="p-2 -ml-1.5">
                <Listbox.Options
                  static
                  className="text-base focus:outline-none sm:text-sm shadow-lg ring-1 ring-black ring-opacity-5 bg-white rounded-md max-h-60  overflow-auto"
                >
                  {loading && (
                    <li className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                      <span className="font-normal block truncate">
                        Loading
                      </span>
                    </li>
                  )}
                  {!loading && children}
                </Listbox.Options>
              </div>
            </div>
          )}
        </>
      )}
    </Listbox>
  );
};

export { Select };
