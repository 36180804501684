import { RadioGroup as ReactRadioGroup } from "@headlessui/react";
import React from "react";
import { classNames } from "../../utilities/mergeStyles";

type RadioOptionProps = {
  value: string;
  label: string;
  disabled?: boolean;
  description?: string;
};

const RadioOption = ({
  value,
  label,
  disabled = false,
  description,
}: RadioOptionProps) => {
  return (
    <ReactRadioGroup.Option
      value={value}
      className="focus:outline-none"
      disabled={disabled}
    >
      {({ active, checked, disabled }) => (
        <div
          className={classNames(
            "flex items-center",
            disabled ? "cursor-default" : "cursor-pointer"
          )}
        >
          <span
            className={classNames(
              checked && !disabled && "bg-primary-400 border-transparent",
              checked && disabled && "bg-gray-500",
              disabled && "text-gray-500 border-gray-200",
              !disabled && !checked && "bg-white border-gray-300",
              active ? "ring-2 ring-offset-2 ring-primary-400" : "",
              "h-4 w-4 rounded-full border flex items-center justify-center"
            )}
            aria-hidden="true"
          >
            <span className="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <div className="ml-2 flex flex-col justify-center items-start">
            <ReactRadioGroup.Label
              as="span"
              className={classNames(
                checked && !disabled && "text-primary-600",
                checked && disabled && "text-gray-900",
                disabled && "text-gray-500",
                !disabled && !checked && "text-gray-900",
                "text-sm font-medium flex justify-center items-center"
              )}
            >
              {label}
            </ReactRadioGroup.Label>
            <ReactRadioGroup.Description
              as="span"
              className={classNames(
                checked && !disabled && "text-primary-600",
                checked && disabled && "text-gray-900",
                disabled && "text-gray-500",
                !disabled && !checked && "text-gray-900",
                "block text-sm"
              )}
            >
              {description}
            </ReactRadioGroup.Description>
          </div>
        </div>
      )}
    </ReactRadioGroup.Option>
  );
};

export { RadioOption };
