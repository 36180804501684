import { useQuery } from "react-query";
import { Role } from "../models/role.model";
import { useApi } from "./useApi";

const useAssignableRoles = () => {
  const { fetchWithToken } = useApi();

  const getRoles = () => {
    return fetchWithToken<Role[]>("/auth/roles");
  };

  const rolesQuery = useQuery("roles", getRoles, {
    staleTime: 1000 * 60 * 10, // Roles are unlikely to change so stale after 10 mins
  });

  return { rolesQuery };
};

export { useAssignableRoles };
