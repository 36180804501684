import React from "react";
import { classNames } from "../../utilities/mergeStyles";

type MenuListProps = {
  focused?: boolean;
  selected?: boolean;
  disabled?: boolean;
} & Omit<React.LiHTMLAttributes<HTMLUListElement>, "disabled">;

const MenuList = React.forwardRef<HTMLUListElement, MenuListProps>(
  ({ focused, selected, disabled, className, ...props }, ref) => {
    return (
      <ul
        {...props}
        ref={ref}
        className={classNames(
          "overflow-y-scroll overflow-x-hidden outline-none flex flex-col flex-1",
          className
        )}
      />
    );
  }
);

export { MenuList };
