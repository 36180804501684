/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Dialog as ReactDialog, Transition } from "@headlessui/react";
import { Button } from "../Button";
import { useAssessmentMutations } from "../../hooks/useAssessmentMutations";
import { InputLabel } from "../InputLabel";
import { TextArea } from "../TextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

type ConfirmDeleteDialogProps = {
  assessmentId: string;
  open?: boolean;
  onClose?: () => void;
};

const schema = yup.object({
  comment: yup.string().max(260).required(),
});

const ConfirmDeleteDialog = ({
  assessmentId,
  open = false,
  onClose,
}: ConfirmDeleteDialogProps) => {
  const {
    deleteAssessmentMutation: { mutate, status, reset },
  } = useAssessmentMutations(assessmentId);

  const formRef = React.useRef<HTMLFormElement>(null);

  const {
    register,
    handleSubmit,
    reset: resetForm,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { comment: "" },
    resolver: yupResolver(schema),
  });

  const watchComment = watch("comment");

  const cancelButtonRef = React.useRef(null);

  const onSubmit = (form: { comment: string }) => {
    mutate({
      id: assessmentId,
      assessmentDeleteBody: {
        comment: form.comment,
      },
    });
  };

  const localHandleSubmit = (form: { comment: string }) => {
    onSubmit && onSubmit(form);
  };

  const handleClose = React.useCallback(() => {
    reset();
    resetForm();
    if (onClose) {
      onClose();
    }
  }, [reset, onClose, resetForm]);

  React.useEffect(() => {
    if (status === "error" || status === "success") {
      handleClose();
    }
  }, [status, handleClose]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <ReactDialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => handleClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ReactDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-col w-full">
                  <ReactDialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Move assessment to bin
                  </ReactDialog.Title>
                  <form
                    id="deleteAssessmentForm"
                    onSubmit={handleSubmit(localHandleSubmit)}
                    ref={formRef}
                  >
                    <div className="mt-2 space-y-2 flex flex-col">
                      <div className="text-sm text-gray-500">
                        Are you sure you want to move this assessment to the
                        bin?
                      </div>
                      <div className="w-full">
                        <InputLabel htmlFor="comment">
                          Please provide a reason
                        </InputLabel>
                        <TextArea
                          {...register("comment")}
                          id="comment"
                          cols={20}
                          className="resize-none"
                          maxLength={260}
                          currentLength={watchComment?.length ?? 0}
                          placeholder="Please provide a reason for deleting this assessment"
                          error={errors.comment !== undefined}
                          errorMessage={errors.comment && "Reason is required"}
                          disabled={status === "loading"}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  as="button"
                  type="submit"
                  form="deleteAssessmentForm"
                  className="ml-2"
                  color="secondary"
                  loading={status === "loading"}
                >
                  Move to bin
                </Button>
                <Button
                  as="button"
                  type="button"
                  onClick={() => handleClose()}
                  variant="outlined"
                  ref={cancelButtonRef}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </ReactDialog>
    </Transition.Root>
  );
};

export { ConfirmDeleteDialog };
