import React from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { config } from "../config";

const AuthProvider: React.FC = ({ children }) => {
  const redirectUri = window.location.origin;
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    if (appState.returnTo) {
      history.push(appState?.returnTo);
    }
  };

  return (
    <Auth0Provider
      audience={config.auth0.audience}
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
