import { FaTimesCircle } from "react-icons/fa";
import { Document } from "../../models/document.model";
import { Button } from "../Button";
import { DownloadButton } from "../DownloadButton";
import { PollingButton } from "../PollingButton";

type DocumentActionProps = {
  doc: Document;
  assessmentId: string;
};

const DocumentAction = ({ doc, assessmentId }: DocumentActionProps) => (
  <>
    {!doc.status ||
      (doc.status === "failed" && (
        <Button
          as="button"
          disabled
          className="w-24 cursor-default "
          title="Unable to download document"
          variant="text"
          flat
        >
          <span className="text-red-400 flex flex-row justify-center items-center">
            <FaTimesCircle className="mr-2"></FaTimesCircle>
            Failed
          </span>
        </Button>
      ))}
    {doc.status === "success" && <DownloadButton id={doc.id} />}
    {doc.status === "pending" && (
      <PollingButton assessmentId={assessmentId} documentId={doc.id} />
    )}
  </>
);

export { DocumentAction };
