// The weight factor is (11 - the digit position) and therefore (10 - the index) when starting at 0.
const multiplyByWeightFactor = (digit: number, index: number) =>
  digit * (10 - index);

// Add two numbers together
const sum = (previousValue: number, currentValue: number) =>
  previousValue + currentValue;

/**
 * Validates NHS number check digit according to Modulus 11 algorithm detailed at:
 * https://www.datadictionary.nhs.uk/data_dictionary/attributes/n/nhs/nhs_number_de.asp
 * @param nhsNumber
 */
const isValidCheckDigit = (nhsNumber: string) => {
  // Convert to an array of numbers
  const nhsNumberAsArray = Array.from(nhsNumber, Number);

  // Get last digit
  const providedCheckDigit = nhsNumberAsArray[9];

  // Get first nine digits of array
  const firstNineDigits = nhsNumberAsArray.splice(0, 9);

  // Get remainder by mapping over digits and multiplying by weight factor
  // then using reduce to sum all digits
  const remainder =
    firstNineDigits.map(multiplyByWeightFactor).reduce(sum, 0) % 11;

  // Subtract remainder from 11
  let checkDigit = 11 - remainder;

  // If the remainder is 11, change it to 0
  if (checkDigit === 11) {
    checkDigit = 0;
  }

  // Check digit of 10 is always invalid
  if (checkDigit === 10) {
    return false;
  }

  return providedCheckDigit === checkDigit;
};

/**
 * Validates NHS number by checking check digit
 * @param nhsNumber
 * @returns {boolean}
 */
const validateNhsNumber = (nhsNumber: string | number): boolean => {
  const nhsNumberAsString = nhsNumber.toString().replace(/\s+/g, "");
  // Check the basics
  if (
    nhsNumberAsString === undefined ||
    nhsNumberAsString === null ||
    isNaN(Number(nhsNumberAsString)) ||
    nhsNumberAsString.toString().length !== 10
  ) {
    return false;
  }

  // Validate according to nhsNumber rules
  return isValidCheckDigit(nhsNumberAsString);
};

export { validateNhsNumber };
