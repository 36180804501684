import React from "react";
import { classNames } from "../../utilities/mergeStyles";
import { FaExclamationCircle } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { Spinner } from "../Spinner";

type TextAreaProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  "value"
> & {
  error?: boolean;
  errorMessage?: string;
  showErrorIcon?: boolean;
  loading?: boolean;
  iconAfter?: IconType;
  value?: string;
  currentLength?: number;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      error,
      errorMessage,
      loading,
      showErrorIcon = true,
      maxLength,
      currentLength,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <div className="mt-1 relative rounded-md shadow-sm space-y-1 mb-2">
          <textarea
            ref={ref}
            className={classNames(
              "block w-full focus:outline-none sm:text-sm rounded-md border",
              error
                ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                : "focus:ring-primary-400 focus:border-primary-400 border-gray-300",
              className
            )}
            maxLength={maxLength}
            {...props}
          />
          {error && showErrorIcon && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <FaExclamationCircle
                className="h-4 w-4 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
          {(loading || props.iconAfter) && (
            <div className="absolute inset-y-0 right-0 inline-flex pr-3 space-x-2">
              {loading && (
                <div className="flex items-center pointer-events-none">
                  <Spinner className="h-4 w-4" aria-hidden="true" />
                </div>
              )}
              {props.iconAfter && (
                <div className="flex items-center pointer-events-none">
                  <props.iconAfter className="h-4 w-4" aria-hidden="true" />
                </div>
              )}
            </div>
          )}
        </div>
        {maxLength !== undefined && currentLength !== undefined && (
          <p
            className={classNames(
              "text-xs",
              currentLength > maxLength ? "text-red-600" : "text-gray-500"
            )}
            id={`${props.id}-error`}
          >
            {`${currentLength} / ${maxLength} characters`}
          </p>
        )}
        {errorMessage && (
          <p className="text-sm text-red-600" id={`${props.id}-error`}>
            {errorMessage}
          </p>
        )}
      </>
    );
  }
);

export { TextArea };
