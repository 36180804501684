import React from "react";
import { classNames } from "../../utilities/mergeStyles";
import { FaExclamationCircle } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { Spinner } from "../Spinner";

type InputProps = {
  name: string;
  error?: boolean;
  errorMessage?: string;
  showErrorIcon?: boolean;
  loading?: boolean;
  iconAfter?: IconType;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      iconAfter,
      error,
      showErrorIcon,
      loading,
      errorMessage,
      id,
      name,
      type,
      ...rest
    },
    ref
  ) => {
    const IconAfter = iconAfter;
    return (
      <>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            id={id}
            name={name}
            type={type ?? "text"}
            ref={ref}
            className={classNames(
              "block w-full focus:outline-none sm:text-sm rounded-md border",
              error
                ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                : "focus:ring-primary-400 focus:border-primary-400 border-gray-300"
            )}
            {...rest}
          />
          {error && showErrorIcon && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <FaExclamationCircle
                className="h-4 w-4 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
          {(loading || IconAfter) && (
            <div className="absolute inset-y-0 right-0 inline-flex pr-3 space-x-2">
              {loading && (
                <div className="flex items-center pointer-events-none">
                  <Spinner className="h-4 w-4" aria-hidden="true" />
                </div>
              )}
              {IconAfter && (
                <div className="flex items-center pointer-events-none">
                  <IconAfter className="h-4 w-4" aria-hidden="true" />
                </div>
              )}
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {errorMessage}
          </p>
        )}
      </>
    );
  }
);

export { Input };
