import { RadioGroup as ReactRadioGroup } from "@headlessui/react";
import React from "react";

type RadioGroupProps = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  description?: string;
  children?: React.ReactNode;
};

const RadioGroup = ({
  value,
  onChange,
  label,
  description,
  children,
}: RadioGroupProps) => {
  return (
    <ReactRadioGroup as="fieldset" value={value} onChange={onChange}>
      <ReactRadioGroup.Label as="legend" role={undefined}>
        <p className="text-sm font-medium text-gray-700">{label}</p>
        {description && <p className="text-sm text-gray-500">{description}</p>}
      </ReactRadioGroup.Label>
      <div className="space-y-2 mt-2">{children}</div>
    </ReactRadioGroup>
  );
};

export { RadioGroup };
