import { AssessmentStatus } from "../../models/assessment.model";
import { ActionsDropdown } from "../ActionsDropdown";
import { PermissionGuard } from "../PermissionGuard";
import { Tab } from "./Tab";

type TabData = {
  name: string;
  href: string;
  current: boolean;
  disabled?: boolean;
};

type TabsProps = {
  tabs: TabData[];
  assessmentId: string;
  archived: boolean;
  assessmentStatus: AssessmentStatus;
  patientId: string;
  disableActions: boolean;
};

function Tabs({
  tabs,
  assessmentId,
  archived,
  assessmentStatus,
  patientId,
  disableActions,
}: TabsProps): JSX.Element {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name} disabled={tab.disabled}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex">
        <div className="border-b border-gray-200 flex justify-between flex-1">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map(
              (tab, index) =>
                !tab.disabled && (
                  <Tab key={index} name={tab.name} href={tab.href}></Tab>
                )
            )}
          </nav>
          <div className="flex items-center">
            <PermissionGuard requiredPermissions={["write:assessment"]}>
              <ActionsDropdown
                patientId={patientId}
                assessmentId={assessmentId}
                archived={archived}
                assessmentStatus={assessmentStatus}
                disabled={disableActions}
              />
            </PermissionGuard>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Tabs };
