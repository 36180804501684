import { classNames } from "../../utilities/mergeStyles";

const ModalActions: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        "inline-flex justify-end mt-2 flex-shrink-0 space-x-2",
        className
      )}
    >
      {children}
    </div>
  );
};

export { ModalActions };
