import React from "react";
import type {
  Operator,
  FlatSetting,
  EnableWhen,
  Public,
} from "../../models/form.model";
import type { Characteristics } from "../../models/submission.model";
import { replacePlaceholder } from "../../utilities/formTemplateUtilities";
import { logicTest, isEnabled } from "../../utilities/formRenderer";

interface ReportTableDynamicRowProps {
  name: string;
  settings: FlatSetting;
  characteristics: Characteristics;
  public: Public;
  enableBehavior?: "all" | "any";
  enableWhen?: [
    {
      characteristicId: string;
      operator: Operator;
      type: string;
      answer: boolean;
    }
  ];
}

function getComponent(type: string) {
  switch (type) {
    case "span":
      return "span";
    case "paragraph":
      return "p";
    default:
      return "div";
  }
}

const ReportTableDynamicRow: React.FC<ReportTableDynamicRowProps> = ({
  settings,
  characteristics,
  public: publicSettings, // aliased as can't use public
  children,
}) => {
  const [noCharsHighlightColor, setNoCharsHighlightColor] = React.useState<
    string | undefined
  >(undefined);

  const [highlightColor, setHighlightColor] = React.useState<
    string | undefined
  >(undefined);

  const characteristicTextValueArray = React.useMemo(
    () =>
      Array.isArray(settings.characteristicTextValueArray?.value)
        ? settings.characteristicTextValueArray?.value ?? []
        : [],
    [settings]
  );

  const conditionalHighlight = React.useMemo(() => {
    const conditionallyHighlight =
      settings?.conditionallyHighlight?.value &&
      typeof settings?.conditionallyHighlight?.value === "object" &&
      "enableWhen" in settings?.conditionallyHighlight?.value
        ? settings?.conditionallyHighlight?.value
        : undefined;

    const highlightColor = Array.isArray(conditionallyHighlight?.settings)
      ? conditionallyHighlight?.settings.find(
          (setting) => setting.carepennyAttr === "highlightColor"
        )?.value
      : undefined;

    if (conditionallyHighlight) {
      // Check if the item passes its logic tests so that we can show or hide it
      const isHighlighted =
        (conditionallyHighlight.enableWhen &&
          conditionallyHighlight.enableWhen.length > 0 &&
          conditionallyHighlight.enableWhen?.reduce(
            (acc: undefined | boolean, curr: EnableWhen) => {
              const test = logicTest(
                curr.operator,
                curr.type === "characteristic" && curr.characteristicId
                  ? characteristics[curr.characteristicId]?.value
                  : "",
                curr.answer ?? ""
              );

              if (conditionallyHighlight.enableBehavior === "all") {
                return acc !== undefined ? acc && test : test;
              }

              if (conditionallyHighlight.enableBehavior === "any") {
                return acc !== undefined ? acc || test : test;
              }

              return false;
            },
            undefined
          )) ??
        false;
      return isHighlighted ? highlightColor : undefined;
    }
    return undefined;
  }, [settings, characteristics]);

  const rowText = React.useMemo(
    () =>
      characteristicTextValueArray
        // Remove items that don't exist
        .filter(
          (val) =>
            typeof val !== "string" &&
            val.characteristicId &&
            characteristics[val.characteristicId]?.value
        )
        // Remove disabled items
        .filter((value) =>
          isEnabled(characteristics, value.enableWhen, value.enableBehavior)
        )
        .map((val, index, parent) => {
          const Component = getComponent(val.type);
          const text = replacePlaceholder(val.text, characteristics);

          if (val.highlightWhenTrue === true && val.highlightWhenTrueColor) {
            setHighlightColor(val.highlightWhenTrueColor);
          }

          return (
            <Component key={index}>
              {text}
              {index < parent.length - 1 && val.delimiter}{" "}
            </Component>
          );
        }),
    [characteristicTextValueArray, characteristics]
  );

  const hideIfNoCharacteristics =
    settings.hideOnNoCharacteristics?.value === true && rowText?.length === 0;

  React.useEffect(() => {
    if (
      rowText?.length === 0 &&
      settings.noCharsHighlight?.value === true &&
      typeof settings.noCharsHighlightColor?.value === "string"
    ) {
      setNoCharsHighlightColor(settings.noCharsHighlightColor.value);
    }
  }, [rowText, settings]);

  return (
    <>
      {hideIfNoCharacteristics ? null : (
        <tr
          className="align-top border border-gray-300"
          style={{
            backgroundColor: highlightColor
              ? highlightColor
              : noCharsHighlightColor
              ? noCharsHighlightColor
              : conditionalHighlight
              ? conditionalHighlight
              : "white",
          }}
        >
          <td className="font-medium w-1/5 p-2">{settings.labeltext?.value}</td>
          <td className="p-2">
            {rowText}
            {children}
          </td>
        </tr>
      )}
    </>
  );
};

export { ReportTableDynamicRow };
