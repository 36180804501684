import { useFormikContext } from "formik";
import * as React from "react";

import { CheckboxItem } from "../CheckboxItem";
import { UserDetailsFormFields } from "./UserDetailsForm";

type DynamicCheckboxFormControlProps = {
  label: string;
  id: string;
  name: string;
  formValueName: keyof UserDetailsFormFields;
  dataId: string;
  disabled?: boolean;
};

const UserDetailsDynamicCheckboxFormControl = ({
  label,
  id,
  name,
  formValueName,
  dataId,
  disabled,
}: DynamicCheckboxFormControlProps) => {
  /*
    since we're doing something a bit non standard by setting an array form field based off the result of a checkbox,
    we need to manage the check state of the checkbox item ourselves
  */
  const [checked, setChecked] = React.useState(false);

  const { setFieldValue, values } = useFormikContext<UserDetailsFormFields>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const existing = values[formValueName] as unknown as string[];

    let newData: string[] = [];
    if (isChecked) {
      newData = [...(existing ?? []), dataId];
    } else {
      const index = existing?.findIndex((e) => e === dataId) ?? -1;
      if (index > -1) {
        existing?.splice(index, 1);
        newData = existing ?? [];
      }
    }

    setFieldValue(formValueName, newData);
    setChecked(e.target.checked);
  };

  React.useEffect(() => {
    const shouldCheck = (values[formValueName] as unknown as string[]).includes(
      dataId
    );
    setChecked(shouldCheck);
  }, [values, formValueName, dataId, setChecked]);

  return (
    <CheckboxItem
      label={label}
      id={id}
      name={name}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    ></CheckboxItem>
  );
};

export { UserDetailsDynamicCheckboxFormControl };
