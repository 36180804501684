import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";
import { useFormRenderer } from "../../context/FormRendererProvider";
import { AnswerOption, FlatSetting } from "../../models/form.model";
import { Characteristics } from "../../models/submission.model";
import { RadioGroup } from "../RadioGroup";
import { RadioOption } from "../RadioOption";

type RadioValue = {
  value: string | undefined;
  characteristics: Characteristics;
};

interface RadioProps {
  control: Control<FieldValues>;
  name: string;
  value: RadioValue;
  settings: FlatSetting;
  characteristics: Characteristics;
  options: AnswerOption[];
  editing: boolean;
  onChange?: (value: unknown) => void;
}

const ControlledRadio: React.FC<RadioProps> = ({
  value,
  settings,
  options,
  characteristics,
  editing = false,
  onChange,
}) => {
  const { updateCharacteristics } = useFormRenderer();

  const getValueFromCharacteristics = React.useCallback(
    (options: AnswerOption[], characteristics: Characteristics) => {
      return options.find((option) =>
        option.private.characteristics.some(
          (characteristic) => characteristics[characteristic]?.value === true
        )
      )?.value;
    },
    []
  );

  const getCharacteristicsFromValue = React.useCallback(
    (options: AnswerOption[], value: string): Characteristics => {
      return options.reduce<Characteristics>((acc, curr) => {
        const newChars = curr.private.characteristics
          .map((char) => ({ [char]: { value: curr.value === value } }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {});
        return { ...acc, ...newChars };
      }, {});
    },
    []
  );

  const [radioValue, setRadioValue] = React.useState<RadioValue>(
    () =>
      value ?? {
        value: getValueFromCharacteristics(options, characteristics),
        characteristics: getCharacteristicsFromValue(
          options,
          getValueFromCharacteristics(options, characteristics) ?? ""
        ),
      }
  );

  const handleChange = (value: string) => {
    const newCharacteristics = getCharacteristicsFromValue(options, value);
    setRadioValue({
      value,
      characteristics: newCharacteristics,
    });

    updateCharacteristics(newCharacteristics);
  };

  React.useEffect(() => {
    if (onChange) {
      onChange(radioValue);
    }
  }, [radioValue, onChange]);

  return (
    <RadioGroup
      value={radioValue.value ?? ""}
      label={(settings.label?.value as string) ?? ""}
      onChange={handleChange}
    >
      {options.map((option) => (
        <RadioOption
          key={option.value}
          label={option.value}
          value={option.value}
          disabled={!editing}
        />
      ))}
    </RadioGroup>
  );
};

const Radio: React.FC<RadioProps> = (props) => {
  const { name, control } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ControlledRadio {...props} value={value} onChange={onChange} />
      )}
    />
  );
};

export { Radio };
