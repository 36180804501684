import { FaFileExport } from "react-icons/fa";

import { useDocumentExport } from "../../hooks/useDocumentExport";
import { Button } from "../Button";
import { PermissionGuard } from "../PermissionGuard";

type DocumentExportProps = {
  assessmentId: string;
};

const DocumentExport = ({ assessmentId }: DocumentExportProps) => {
  const { exportDocumentMutation } = useDocumentExport({ assessmentId });
  const { mutate: exportDocument, isLoading } = exportDocumentMutation;

  return (
    <PermissionGuard requiredPermissions={["write:assessment"]}>
      <Button
        as="button"
        color="primary"
        className="ml-auto w-24"
        loading={isLoading}
        onClick={() => exportDocument({ assessmentId })}
      >
        <FaFileExport className="mr-1"></FaFileExport>Export
      </Button>
    </PermissionGuard>
  );
};

export { DocumentExport };
