import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";

import { Error as ErrorComponent } from "../components/Error";
import { FullscreenSpinner } from "../components/FullscreenSpinner";
import { useTenantQuery } from "../hooks/useTenantQuery";
import { Tenant } from "../models/tenant.model";

const TenantContext = React.createContext<{ tenant: Tenant } | null>(null);

const TenantProvider: React.FC = ({ children }) => {
  const { logout } = useAuth0();
  const { getTenantQuery } = useTenantQuery();
  const { data: tenant, status } = getTenantQuery;

  return (
    <>
      {status === "error" && (
        <div className="h-screen w-screen flex">
          <ErrorComponent>
            <p>
              We were unable to log you in successfully. Please{" "}
              <button
                className="text-primary-500 underlines"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                logout
              </button>{" "}
              and try again. If the problem persists, please contact Ultramed
              via{" "}
              <a
                href="https://support.ultramed.co"
                className="text-primary-500 underlines"
                target="_blank"
                rel="noreferrer"
              >
                support
              </a>
              .
            </p>
            <p className="mt-4 p-2 border-gray-300 border bg-gray-100 rounded-md text-xs">
              Error details: {"Unable to fetch tenant"}
            </p>
          </ErrorComponent>
        </div>
      )}
      {status === "loading" && <FullscreenSpinner />}
      {status === "success" && tenant && (
        <TenantContext.Provider
          value={{
            tenant,
          }}
        >
          {children}
        </TenantContext.Provider>
      )}
    </>
  );
};

const useTenant = () => {
  const context = React.useContext(TenantContext);
  if (!context) {
    throw new Error("useTenant must be used within a TenantProvider");
  }

  return context;
};

export { useTenant, TenantContext, TenantProvider };
