import { Route, RouteProps } from "react-router-dom";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import jwtDecode from "jwt-decode";
import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { FullscreenSpinner } from "../FullscreenSpinner";
import React from "react";
import { useOrganization } from "../../hooks/useOrganization";
import { Error as ErrorComponent } from "../Error";
import { DecodedToken } from "../../models/decoded-token.model";
import { TokenContext } from "../../context/TokenContext";
import { TenantProvider } from "../../context/TenantContext";

type ProtectedRouteProps = RouteProps & {
  component: React.ComponentType<unknown>;
};

function ProtectedRoute({
  component,
  ...args
}: ProtectedRouteProps): JSX.Element {
  const [decodedToken, setDecodedToken] = React.useState<DecodedToken | null>(
    null
  );

  const {
    error: auth0Error,
    logout,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const { data, error: orgError } = useOrganization();

  React.useEffect(() => {
    const login = async () => {
      if (!isAuthenticated && !isLoading && data) {
        const loginWithRedirectOptions: RedirectLoginOptions = {
          organization: data.id,
          organizationName: data.name,
          redirectUri: window.location.origin,
          appState: {
            returnTo: window.location.pathname,
          },
        };
        await loginWithRedirect(loginWithRedirectOptions);
      }
    };
    login();
  }, [isAuthenticated, data, loginWithRedirect, isLoading]);

  React.useEffect(() => {
    const getDecodedToken = async () => {
      if (!isLoading && isAuthenticated) {
        const rawToken = await getAccessTokenSilently();
        const decodedToken = jwtDecode<DecodedToken>(rawToken);

        setDecodedToken(decodedToken);
      }
    };

    getDecodedToken();
  }, [isAuthenticated, getAccessTokenSilently, isLoading]);

  React.useEffect(() => {
    Sentry.setUser({
      id: decodedToken?.sub,
      email: decodedToken?.["https://ultramed.tech/practitioner"]?.email,
    });
    posthog.identify(
      decodedToken?.["https://ultramed.tech/practitioner"]?.email,
      {
        email: decodedToken?.["https://ultramed.tech/practitioner"]?.email,
      }
    );
  }, [decodedToken]);

  return (
    <>
      {(auth0Error || orgError) && (
        <div className="h-screen w-screen flex">
          <ErrorComponent>
            <p>
              We were unable to log you in successfully. Please{" "}
              <button
                className="text-primary-500 underlines"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                logout
              </button>{" "}
              and try again. If the problem persists, please contact Ultramed
              via{" "}
              <a
                href="https://support.ultramed.co"
                className="text-primary-500 underlines"
                target="_blank"
                rel="noreferrer"
              >
                support
              </a>
              .
            </p>
            <p className="mt-4 p-2 border-gray-300 border bg-gray-100 rounded-md text-xs">
              Error details:{" "}
              {auth0Error?.message ?? orgError?.message ?? "Unknown error."}
            </p>
          </ErrorComponent>
        </div>
      )}
      {(!isAuthenticated || !decodedToken) && <FullscreenSpinner />}
      {isAuthenticated && decodedToken && (
        <>
          <TokenContext.Provider value={{ decodedToken }}>
            <TenantProvider>
              <Route component={component} {...args} />
            </TenantProvider>
          </TokenContext.Provider>
        </>
      )}
    </>
  );
}

export { ProtectedRoute };
