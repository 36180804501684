const allergySymptoms = [
  {
    id: "GuEyPtZ2hrdfPGqveDgg",
    priority: "1",
    text: "Worsening of eczema symptoms",
    value: "worsening_eczema",
  },
  {
    id: "HFyv6Pz633NrGFkbQ28i",
    priority: 1,
    text: "Red, itchy rash",
    value: "rash",
  },
  {
    id: "O4tbDf91IvBx8cXmS8Zd",
    priority: "1",
    text: "Red, itchy, or watery eyes",
    value: "red_itchy_watery_eyes",
  },
  {
    id: "VTH63ZaLaQX9NPAKQ4Xt",
    priority: "2",
    text: "Anaphylaxis or anaphylactic shock",
    value: "anaphylaxis",
  },
  {
    id: "kiEJ77oY9G64c6ijvhID",
    priority: "1",
    text: "Swollen lips or Tongue",
    value: "swollen_lips_or_tongue",
  },
  {
    id: "lDpb4psZIVzuJfT3tvFp",
    priority: "1",
    text: "Sneezing",
    value: "sneezing",
  },
  {
    id: "pr1HmJbl7eajpoBhxlLQ",
    priority: "1",
    text: "Wheezing or coughing",
    value: "wheezing_or_coughing",
  },
  {
    id: "vHqUiGcthuLKoHaXzvbm",
    priority: "1",
    text: "Runny or blocked nose",
    value: "runny_or_blocked_nose",
  },
  {
    id: "vgpXzdIyvwkcb7I1HVzb",
    priority: "1",
    text: "Worsening of asthma symptoms",
    value: "worsening_asthma",
  },
];

export { allergySymptoms };
