/**
 * @copyright 2021 Ultramed Ltd
 * @author Nicolaas Prinsloo <nick@ultramed.co>
 */
type Allergy = Readonly<{
  allergen: string;
  allergenType: string;
  otherTypeText: string;
  symptoms: string[];
  otherSymptoms: string;
  notes: string;
  type?: {
    text: string;
    value: string;
  };
}>;

type Operation = Readonly<{
  userText?: string;
  year?: string;
}>;

type IcuAdmission = Readonly<{
  name?: string;
  reason?: string;
  year?: string;
}>;

type DoseUnits = {
  value: string;
  text: string;
};

type Meta = {
  source: string;
};

type PeriodUnit = {
  value: string;
  text: string;
  textly: string;
};

type Route = {
  value: string;
  text: string;
  term?: string;
  friendly?: boolean;
};

type TimeOfDay = {
  hr: string | null;
  min: string | null;
  period: string | null;
};

type Medication = Readonly<{
  name: string;
  dose: string;
  route: Route;
  otherRoute: string;
  doseUnits: DoseUnits;
  otherDoseUnit: string;
  frequency: string;
  period: string | null;
  periodUnit: PeriodUnit;
  additionalInstruction: string;
  asNeeded: boolean;
  dayOfWeek: unknown[];
  timeOfDay: TimeOfDay;
  scheduleDetail: string | null;
  meta: Meta;
  createdTimestamp: string;
}>;

type Bmi = Readonly<{
  height?: number;
  weight?: number;
  bmi?: number;
}>;

type AnswerValue = {
  value:
    | string
    | number
    | boolean
    | Medication
    | Allergy
    | Bmi
    | Operation
    | IcuAdmission[];
}[];

type Answers = Readonly<Record<string, AnswerValue>>;

type CharacteristicValue =
  | string
  | number
  | boolean
  | null
  | Medication[]
  | Allergy[]
  | Operation[]
  | IcuAdmission[]
  | Bmi;

type Characteristics = Readonly<
  Record<
    string,
    {
      value: CharacteristicValue;
    }
  >
>;

/**
 * This represents a recommendation as used in Firestore.
 * This is usually embedded in a QuestionnaireResponse.
 */
type Recommendation = Readonly<{
  id: string;
  meta: {
    adminNotes: string;
    createdTimestamp: string;
    initTimestamp: string;
    updatedTimestamp: string;
    type: unknown;
    version: number;
    payload: {
      commonName: string;
      description: string;
      id: string;
      type: unknown;
      version: number;
    };
  };
}>;

/**
 * View model for Submissions
 * Based on QuestionnaireResponse but rationalised
 */
type Submission = Readonly<{
  id: string;
  tenantId: string;
  created: Date;
  updated: Date;
  assessmentId: string;
  formId: string;
  status: "entered-in-error" | "completed" | "in-progress" | "superseded";
  answers: Answers;
  characteristics: Characteristics;
  recommendations: Recommendation[];
}>;

type SubmissionInput = Omit<
  Submission,
  "id" | "created" | "updated" | "status" | "tenantId"
>;

type SubmissionUpdate = Omit<
  Submission,
  | "id"
  | "created"
  | "updated"
  | "status"
  | "tenantId"
  | "assessmentId"
  | "formId"
  | "recommendations"
>;

const makeSubmission = (submission: Submission): Submission =>
  Object.freeze(submission);

const makeSubmissionInput = (
  submissionInput: SubmissionInput
): SubmissionInput => Object.freeze(submissionInput);

const makeSubmissionUpdate = (
  submissionUpdate: SubmissionUpdate
): SubmissionUpdate => Object.freeze(submissionUpdate);

export type {
  Bmi,
  Allergy,
  IcuAdmission,
  Operation,
  Medication,
  Answers,
  Submission,
  SubmissionInput,
  SubmissionUpdate,
  Characteristics,
  CharacteristicValue,
};
export { makeSubmission, makeSubmissionInput, makeSubmissionUpdate };
