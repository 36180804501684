import { useQuery, useMutation, useQueryClient } from "react-query";
import { Task, TaskUpdate } from "../models/task.model";
import { Response } from "../models/response.model";
import { FetchError, useApi } from "./useApi";
import { useToasts } from "../context/ToastProvider";

const useTasks = (assessmentId: string) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { fetchWithToken } = useApi();

  const getTasks = async (assessmentId: string, signal?: AbortSignal) => {
    const res = await fetchWithToken<Response<Task[]>>(
      `/assessments/${assessmentId}/tasks`,
      {
        signal,
      }
    );
    return res.data;
  };

  const updateTask = async ({ id, task }: { id: string; task: TaskUpdate }) => {
    const result = await fetchWithToken<Response<Task[]>>(`/tasks/${id}`, {
      method: "PATCH",
      body: task,
    });
    return result.data;
  };

  const tasksQuery = useQuery(
    ["tasks", assessmentId],
    ({ signal }) => getTasks(assessmentId, signal),
    {
      staleTime: 1000 * 60, // Stale after 1 minute
      retry: (_, error) =>
        error instanceof FetchError && error.response.status >= 500,
    }
  );

  const updateTaskMutation = useMutation<
    Task[],
    FetchError,
    { id: string; task: TaskUpdate },
    Task[]
  >(updateTask, {
    // When mutate is called:
    onMutate: async (updatedTask) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["tasks", assessmentId]);

      // Snapshot the previous value
      const previousTasks =
        queryClient.getQueryData<Task[]>(["tasks", assessmentId]) ?? [];

      // Optimistically update to the new value
      queryClient.setQueryData<Task[]>(
        ["tasks", assessmentId],
        (old) =>
          old?.map((task) =>
            task.id === updatedTask.id ? { ...task, ...updatedTask.task } : task
          ) ?? []
      );

      // Return a context with the previous tasks
      return previousTasks;
    },
    // If the mutation fails, use the context we returned above
    onError: (err, updatedTask, context) => {
      addToast({
        type: "error",
        content: "Error updating task. Please try again",
      });
      queryClient.setQueryData(["tasks", assessmentId], context);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(["tasks", assessmentId]);
    },
    onSuccess: () => {
      addToast({ type: "message", content: "Task updated" });
    },
  });

  return {
    tasksQuery,
    updateTaskMutation,
  };
};

export { useTasks };
