import React, { Fragment } from "react";
import { Listbox } from "@headlessui/react";
import { FaCheck } from "react-icons/fa";
import { classNames } from "../../utilities/mergeStyles";

type SelectOptionProps = {
  value: string | number;
  children: string;
};

const SelectOption = ({ value, children }: SelectOptionProps): JSX.Element => {
  return (
    <Listbox.Option
      key={value}
      className={({ active }) =>
        classNames(
          active ? "text-white bg-primary-400" : "text-gray-900",
          "cursor-default select-none relative py-2 pl-3 pr-9"
        )
      }
      value={value}
    >
      {({ selected, active }) => (
        <>
          <span
            className={classNames(
              selected ? "font-semibold" : "font-normal",
              "block truncate"
            )}
          >
            {children}
          </span>

          {selected ? (
            <span
              className={classNames(
                active ? "text-white" : "text-primary-400",
                "absolute inset-y-0 right-0 flex items-center pr-4"
              )}
            >
              <FaCheck className="h-3 w-3" aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};

export { SelectOption };
