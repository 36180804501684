import * as React from "react";

import { useUsers } from "../../hooks/useUsers";
import { formatName } from "../../utilities/cuiFormat";
import { AddUserModal } from "../AddUserModal";
import { Button } from "../Button";
import { Error } from "../Error";
import { Heading } from "../Heading";
import { PageBody } from "../PageBody";
import { PageContainer } from "../PageContainer";
import { PageHeader } from "../PageHeader";
import { Spinner } from "../Spinner";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "../Table";
import { UserActionsMenu } from "../UserActionsMenu";

const Users = () => {
  const [addUsersModalOpen, setAddUsersModalOpen] = React.useState(false);
  const { usersQuery } = useUsers();
  const { data, status } = usersQuery;

  return (
    <PageContainer>
      <PageHeader>
        <Heading>Users</Heading>
      </PageHeader>
      <PageBody>
        {status === "error" && <Error />}
        {status === "loading" && <Spinner></Spinner>}
        {status === "success" && data && (
          <div className="flex min-h-0 flex-col">
            <div className="flex flex-row justify-end my-2">
              <Button
                as="button"
                color="primary"
                onClick={() => setAddUsersModalOpen(true)}
              >
                Add
              </Button>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((user) => (
                  <TableRow key={user.id}>
                    <TableData>
                      {formatName(
                        user.givenName ?? "",
                        user.familyName ?? "",
                        user.prefix
                      )}
                    </TableData>
                    <TableData>{user.email}</TableData>
                    <TableData>
                      <UserActionsMenu user={user}></UserActionsMenu>
                    </TableData>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </PageBody>
      <AddUserModal
        isOpen={addUsersModalOpen}
        setIsOpen={setAddUsersModalOpen}
      ></AddUserModal>
    </PageContainer>
  );
};

export { Users };
