import React from "react";
import type { Node } from "@react-types/shared";
import type { ListState } from "react-stately";
import { useListBoxSection, useOption, useHover, useListBox } from "react-aria";
import { MenuListItem } from "../MenuListItem";
import { MenuList } from "../MenuList";
import { AriaListBoxOptions } from "@react-aria/listbox";

type ListBoxProps = {
  listBoxRef: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
} & React.HTMLAttributes<HTMLElement> &
  AriaListBoxOptions<unknown>;

interface SectionProps {
  section: Node<unknown>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

function ListBox(props: ListBoxProps) {
  const { listBoxRef, state, ...rest } = props;

  const { listBoxProps, labelProps } = useListBox(rest, state, listBoxRef);

  const { hoverProps } = useHover({
    onHoverStart: (e) => {
      const key = e.target.getAttribute("data-key");
      if (key) {
        state.selectionManager.setFocused(true);
        state.selectionManager.setFocusedKey(key);
      }
    },
    onHoverEnd: (e) => {
      const key = e.target.getAttribute("data-key");
      const currentFocusedKey = state.selectionManager.focusedKey;
      if (key === currentFocusedKey) {
        state.selectionManager.setFocusedKey(null as unknown as string);
      }
    },
  });

  return (
    <>
      <label {...labelProps} className="sr-only">
        {props.label}
      </label>
      <MenuList {...listBoxProps} ref={listBoxRef}>
        {[...state.collection].map((item) =>
          item.type === "section" ? (
            <ListBoxSection
              key={item.key}
              section={item}
              state={state}
              {...hoverProps}
            />
          ) : (
            <Option key={item.key} item={item} state={state} {...hoverProps} />
          )
        )}
      </MenuList>
    </>
  );
}

function ListBoxSection({ section, state, ...rest }: SectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });

  return (
    <>
      <li {...itemProps}>
        {section.rendered && (
          <span
            {...headingProps}
            className="text-xs font-bold uppercase text-gray-500 px-2 py-1 flex items-center sticky top-0 border-b border-t border-gray-200 bg-gray-100"
          >
            {section.rendered}
          </span>
        )}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} {...rest} />
          ))}
        </ul>
      </li>
    </>
  );
}

function Option({ item, state, ...rest }: OptionProps) {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isDisabled, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref
  );

  return (
    <MenuListItem
      {...optionProps}
      {...rest}
      ref={ref}
      selected={isSelected}
      focused={isFocused}
      disabled={isDisabled}
    >
      {item.rendered}
    </MenuListItem>
  );
}

export { ListBox };
