import * as Yup from "yup";
import { Spinner } from "../Spinner";
import { ConsultantSelect } from "../ConsultantSelect";
import { ServiceSelect } from "../ServiceSelect";
import { PriorityRadio } from "../PriorityRadio";
import { Patient } from "../../models/patient.model";
import {
  formatDate,
  formatName,
  formatShortDate,
} from "../../utilities/cuiFormat";
import { DateField } from "../DateField";
import { ContactMethodRadio } from "../ContactMethodRadio";
import { AssessmentInput } from "../../models/assessment.model";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePatient } from "../../hooks/usePatient";
import { AssignTag } from "../AssignTag";
import { Alert } from "../Alert";
import { Steps } from "../AssessmentsInviteModal";
import { Button } from "../Button";
import { PatientIdentifier } from "../PatientIdentifier";

type AssessmentFormProps = {
  id: string;
  onSubmit: (formData: AssessmentInput) => void;
  status: "loading" | "idle" | "success" | "error";
  patient: Patient;
  enableFormStatus: "idle" | "existing" | "none";
  setEnableFormStatus: (status: "idle" | "existing" | "none") => void;
  setStep: (step: Steps) => void;
};

const AssessmentForm = React.forwardRef<HTMLFormElement, AssessmentFormProps>(
  (
    {
      id,
      patient,
      status,
      onSubmit,
      enableFormStatus,
      setEnableFormStatus,
      setStep,
    },
    ref
  ) => {
    const {
      patientAssessmentsQuery: { data: assessments, status: assessmentsStatus },
    } = usePatient(patient.id);

    React.useEffect(() => {
      if (assessments && assessments.length === 0) {
        setEnableFormStatus("none");
      }
      if (assessments && assessments.length > 0) {
        setEnableFormStatus("existing");
      }
    }, [assessments, setEnableFormStatus]);

    const defaultContactMethod = patient.mobile
      ? "sms"
      : patient.email
      ? "email"
      : "clinician";

    const assessmentFormSchema = Yup.object({
      patientId: Yup.string().required(),
      consultantId: Yup.string(),
      serviceId: Yup.string().required("Service is required"),
      priority: Yup.number()
        .min(1, "Priority must be at least 1")
        .max(6, "Priority must be at most 6")
        .required("Priority is required"),
      tciDate: Yup.date().min(new Date(), "TCI date cannot be in the past"),
      contactMethod: Yup.string()
        .oneOf(["sms", "email", "letter", "clinician"])
        .required("Contact method is required"),
    });

    const { control, handleSubmit } = useForm<AssessmentFormFields>({
      resolver: yupResolver(assessmentFormSchema),
      defaultValues: {
        patientId: patient.id,
        serviceId: undefined,
        consultantId: undefined,
        priority: 4,
        tciDate: undefined,
        contactMethod: defaultContactMethod,
      },
    });

    type AssessmentFormFields = Yup.Asserts<typeof assessmentFormSchema>;

    const disabledContactMethods = [
      ...(!patient.mobile ? ["sms"] : []),
      ...(!patient.email ? ["email"] : []),
      ...(!patient.address ? ["letter"] : []),
    ];

    return (
      <>
        <div className="flex flex-col flex-1 min-h-0 overflow-auto p-1 relative">
          {(status === "loading" || assessmentsStatus === "loading") && (
            <div className="absolute inset-0 h-full w-full flex flex-col justify-center items-center bg-white opacity-90 z-10">
              <Spinner size="lg"></Spinner>
            </div>
          )}
          <div className="flex self-stretch justify-between p-4 box-border ring-2 ring-primary-400 rounded-md sticky top-0 bg-white z-20">
            <div className="flex flex-col flex-1">
              <p className="font-medium">
                {formatName(
                  patient.givenName ?? "",
                  patient.familyName ?? "",
                  patient.prefix
                )}
              </p>
              <div className="flex space-x-2">
                <p className="text-sm">
                  <span className="font-medium">Born:</span>{" "}
                  {formatShortDate({ date: patient.born })}
                </p>
                {Object.entries(patient.identifier)
                  .filter(([, value]) => Boolean(value))
                  .map(([key, value]) => {
                    return (
                      <p className="text-sm">
                        <PatientIdentifier
                          identifierValue={value}
                          identifierId={key}
                          prefixClassName="font-medium"
                        ></PatientIdentifier>
                      </p>
                    );
                  })}
              </div>
              <div className="flex space-x-2">
                {patient.telephone && (
                  <p className="text-sm">
                    <span className="font-medium">Tel:</span>{" "}
                    {patient.telephone}
                  </p>
                )}
                {patient.mobile && (
                  <p className="text-sm">
                    <span className="font-medium">Mob:</span> {patient.mobile}
                  </p>
                )}
                {patient.email && (
                  <p className="text-sm">
                    <span className="font-medium">Email:</span> {patient.email}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <Button
                type="button"
                as="button"
                variant="text"
                className="shadow-none"
                onClick={() => setStep("editPatient")}
              >
                Edit
              </Button>
            </div>
          </div>
          {enableFormStatus === "none" && (
            <div className="flex flex-1 flex-col">
              <form id={id} onSubmit={handleSubmit(onSubmit)} ref={ref}>
                <div className="mt-3 grid gap-y-3 gap-x-4 grid-cols-1 sm:grid-cols-6">
                  <div className="col-span-6">
                    <Controller
                      control={control}
                      name="serviceId"
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <ServiceSelect
                          onChange={onChange}
                          value={value}
                          error={error}
                          id={name}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-6">
                    <Controller
                      control={control}
                      name="consultantId"
                      render={({ field: { onChange, value } }) => (
                        <ConsultantSelect onChange={onChange} value={value} />
                      )}
                    />
                  </div>
                  <div className="col-span-6">
                    <Controller
                      control={control}
                      name="priority"
                      render={({ field: { onChange, value } }) => (
                        <PriorityRadio onChange={onChange} value={value} />
                      )}
                    />
                  </div>
                  <div className="col-span-6">
                    <Controller
                      control={control}
                      name="tciDate"
                      render={({
                        field: { onChange, value },
                        fieldState: { isTouched, error },
                      }) => {
                        return (
                          <DateField
                            touched={isTouched}
                            error={error}
                            value={value}
                            onChange={onChange}
                            label="TCI Date"
                            showPicker
                          ></DateField>
                        );
                      }}
                    />
                  </div>
                  <div className="col-span-6">
                    <Controller
                      control={control}
                      name="contactMethod"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <ContactMethodRadio
                            disabledOptions={disabledContactMethods}
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
          {enableFormStatus === "existing" && assessments && (
            <div className="py-4 flex flex-col min-h-0">
              <Alert
                title="This patient has already been invited for an assessment"
                type="warn"
              />
              <ul className="flex flex-col space-y-2 py-2">
                {assessments.map((assessment) => (
                  <li className="rounded-md border">
                    <div className="px-4 py-4 flex items-center justify-between">
                      <div className="flex flex-col space-y-2 flex-1">
                        <div className="flex text-sm">
                          <p className="font-medium text-gray-900 truncate">
                            Assessment in {assessment.service.name}
                          </p>
                        </div>
                        <div className="flex flex-col text-xs">
                          <span>Updated {formatDate(assessment.updated)}</span>
                          <span>Created {formatDate(assessment.created)}</span>
                        </div>
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 space-y-2 flex-1">
                        <div className="flex justify-end space-x-2">
                          <AssignTag
                            assessmentId={assessment.id}
                            assessmentStatus={assessment.status}
                            tag={assessment.tag}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }
);

export { AssessmentForm };
