import { Characteristics } from "../models/submission.model";
import { formatShortDate } from "./cuiFormat";

type FnMap = {
  [key: string]: (value: string) => string;
};

const fnMap: FnMap = {
  date: (value: string) => {
    return formatShortDate({ date: value, isUTC: true, local: true });
  },
};

function removeCharacteristicsPrefix(text: string) {
  return text.replace("characteristics:", "");
}

function removeWhitespace(text?: string | null) {
  return text?.replace(/\s/g, "") ?? "";
}

function handlePipe(
  text: string
): [string, Record<string, string | undefined>] {
  const fnRegex = /(?<fnName>\w+)\((?<fnParams>.*)\)/;
  const splitPipe = text.split("|");
  const characteristicResult = splitPipe[0];
  const pipeResult = splitPipe[1];
  const fnResult = pipeResult?.match(fnRegex)?.groups ?? {};
  return [characteristicResult, fnResult];
}

function replacePlaceholder(text: string, characteristics: Characteristics) {
  // This will match all occurrences of {{ string }}
  const regex2 = /{{(.*?)}}+/g;

  return text.replace(regex2, (_, group) => {
    const noWhitespace = removeWhitespace(group);
    const noCharacteristics = removeCharacteristicsPrefix(noWhitespace);
    const [characteristicName, { fnName }] = handlePipe(noCharacteristics);
    const value = characteristics[characteristicName]?.value?.toString();
    if (fnName && value) {
      const fn = fnMap[fnName];
      const modifiedValue = fn(value);
      return modifiedValue;
    }
    return value ?? "";
  });
}

export { replacePlaceholder };
