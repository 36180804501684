import React from "react";
import { Input } from "../Input";
import { InputLabel } from "../InputLabel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "../DatePicker";
import { FieldError } from "react-hook-form";

dayjs.extend(utc);

type DateFieldProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
> & {
  label: string;
  error?: FieldError;
  touched: boolean;
  showPicker?: boolean;
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
};

const DateField = ({
  error,
  touched,
  value,
  onChange,
  label,
  showPicker = false,
  name,
  id,
}: DateFieldProps): JSX.Element => {
  const yearRef = React.useRef<HTMLInputElement | null>(null);

  const initialDate = value ? dayjs(value) : undefined;

  const [dateFormState, setDateFormState] = React.useState({
    [`${name}-day`]: initialDate?.isValid() ? initialDate?.format("D") : "",
    [`${name}-month`]: initialDate?.isValid() ? initialDate?.format("M") : "",
    [`${name}-year`]: initialDate?.isValid() ? initialDate?.format("YYYY") : "",
  });

  const [dateState, setDateState] = React.useState<Date | undefined>();

  React.useEffect(() => {
    const day = Number.parseInt(dateFormState[`${name}-day`]).toString();
    const month = Number.parseInt(dateFormState[`${name}-month`]).toString();
    const year = dateFormState[`${name}-year`];
    const dateString = `${day}/${month}/${year}`;
    const date = dayjs.utc(dateString, "D/M/YYYY");
    setDateState(date.isValid() ? date.toDate() : undefined);
  }, [dateFormState, setDateState, name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDateFormState({
      ...dateFormState,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const updateValue = React.useCallback((value: Date | undefined) => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    updateValue(dateState);
  }, [dateState, updateValue]);

  const updateFieldsFromDate = (value: Date | undefined) => {
    const date = dayjs(value);
    setDateFormState({
      [`${name}-day`]: date?.isValid() ? date?.format("D") : "",
      [`${name}-month`]: date?.isValid() ? date?.format("M") : "",
      [`${name}-year`]: date?.isValid() ? date?.format("YYYY") : "",
    });
  };

  return (
    <div>
      <fieldset
        aria-describedby={`${id}-hint${touched && error ? ` ${id}-error` : ""}`}
        role="group"
        id={id}
        name={name}
      >
        <legend className="text-sm font-medium text-gray-700">{label}</legend>
        <div className="text-sm text-gray-500" id={`${id}-hint`}>
          For example, 15 2 1979
        </div>
        <div className="flex space-x-4 mt-2">
          <div className="w-12 flex flex-col">
            <InputLabel htmlFor={`${id}-day`}>Day</InputLabel>
            <Input
              id={`${id}-day`}
              name={`${name}-day`}
              value={dateFormState[`${name}-day`]}
              onChange={handleChange}
              type="text"
              error={touched && !!error}
              showErrorIcon={false}
              maxLength={2}
            />
          </div>
          <div className="w-12">
            <InputLabel htmlFor={`${id}-month`}>Month</InputLabel>
            <Input
              id={`${id}-month`}
              name={`${name}-month`}
              value={dateFormState[`${name}-month`]}
              onChange={handleChange}
              type="text"
              error={touched && !!error}
              showErrorIcon={false}
              maxLength={2}
            />
          </div>
          <div className="w-16">
            <InputLabel htmlFor={`${id}-year`}>Year</InputLabel>
            <Input
              ref={yearRef}
              id={`${id}-year`}
              name={`${name}-year`}
              value={dateFormState[`${name}-year`]}
              onChange={handleChange}
              type="text"
              error={touched && !!error}
              showErrorIcon={false}
              maxLength={4}
            />
          </div>
          {showPicker && (
            <div className="flex flex-col justify-end mb-1">
              <DatePicker
                value={value}
                onChange={(value) => {
                  updateFieldsFromDate(value);
                  yearRef.current?.focus();
                }}
              />
            </div>
          )}
        </div>
        {error && (
          <div className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            <span className="sr-only">Error: </span>
            {error.message}
          </div>
        )}
      </fieldset>
    </div>
  );
};

export { DateField };
