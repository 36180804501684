import React from "react";
import { useQueryClient, useQuery } from "react-query";

import { Document } from "../models/document.model";
import { Response as ResponseModel } from "../models/response.model";
import { FetchError, useApi } from "./useApi";

type UseDocumentPollProps = {
  assessmentId: string;
  documentId: string;
};

const useDocumentPoll = ({
  documentId,
  assessmentId,
}: UseDocumentPollProps) => {
  const { fetchWithToken } = useApi();
  const [isPolling, setIsPolling] = React.useState(true);
  const queryClient = useQueryClient();
  const documentsQueryKey = React.useMemo(
    () => ["documents", assessmentId],
    [assessmentId]
  );

  const checkDocumentStatus = async (documentId: string) => {
    const response = await fetchWithToken<ResponseModel<Document>>(
      `/documents/${documentId}`
    );
    return response.data;
  };

  const pollingQuery = useQuery(
    ["document", documentId],
    () => checkDocumentStatus(documentId),
    {
      onSuccess: (data) => {
        if (data.status === "success") {
          setIsPolling(false);
          queryClient.invalidateQueries(documentsQueryKey);
        }
      },
      onError: () => {
        setIsPolling(false);
        queryClient.invalidateQueries(documentsQueryKey);
      },
      refetchInterval: 5000,
      enabled: isPolling,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      retry: (_, error) =>
        error instanceof FetchError && error.response.status >= 500,
    }
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPolling(false);
      queryClient.invalidateQueries(documentsQueryKey);
    }, 30000);
    return () => {
      clearTimeout(timeout);
    };
  }, [queryClient, documentsQueryKey]);

  return { pollingQuery };
};

export { useDocumentPoll };
