import React from "react";
import { FlatSetting } from "../../models/form.model";
import { Characteristics, IcuAdmission } from "../../models/submission.model";

interface IcuListProps {
  settings: FlatSetting;
  characteristics: Characteristics;
}

const IcuList: React.FC<IcuListProps> = ({ characteristics }) => {
  const admissions = characteristics["patients_icu_list"]?.value as
    | IcuAdmission[]
    | undefined;

  return (
    <>
      {admissions && admissions.length > 0 ? (
        <>
          <tr>
            <td>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-left">Admitted to</th>
                    <th className="p-2 text-left">Reason</th>
                    <th className="p-2 text-left">Year</th>
                  </tr>
                </thead>
                <tbody>
                  {admissions.map((admission, index) => (
                    <tr key={index}>
                      <td className="p-2">{admission.name}</td>
                      <td className="p-2">{admission.reason}</td>
                      <td className="p-2">{admission.year}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td className="p-2">No ICU admissions recorded</td>
        </tr>
      )}
    </>
  );
};

export { IcuList };
