import { useQuery } from "react-query";
import { Submission } from "../models/submission.model";
import { Response } from "../models/response.model";
import { FetchError, useApi } from "./useApi";

/**
 * Custom hook that returns an react query hook
 * Fetches a submission by ID and caches it using the
 * default cache settings
 * @see https://react-query.tanstack.com/reference/useQuery
 */
const useSubmission = (submissionId: string) => {
  const { fetchWithToken } = useApi();

  const getSubmission = (id: string, signal?: AbortSignal) => {
    return fetchWithToken<Response<Submission>>(`/submissions/${id}`);
  };

  const submissionQuery = useQuery(
    ["submission", submissionId],
    ({ signal }) => getSubmission(submissionId, signal),
    {
      staleTime: 1000 * 60 * 5, // Stale after 1 minute
      retry: (_, error) =>
        error instanceof FetchError && error.response.status >= 500,
    }
  );

  return {
    submissionQuery,
  };
};

export { useSubmission };
