import { Menu } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "../Avatar";
import { classNames } from "../../utilities/mergeStyles";
import { usePopper } from "react-popper";
import React from "react";

type UserMenuProps = {
  collapsed?: boolean;
};

function UserMenu({ collapsed = false }: UserMenuProps): JSX.Element {
  const { logout, user } = useAuth0<{ name: string; email: string }>();
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Menu>
      <Menu.Button
        ref={setReferenceElement}
        className={classNames(
          "flex items-center focus:outline-none group ml-4 mr-4 mb-4"
        )}
        aria-label="User menu"
      >
        <Avatar
          text={user?.name}
          className="bg-blue-700 text-white group-focus:ring-2 group-focus:ring-white group-focus:ring-opacity-75 group-focus:ring-offset-2 group-focus:ring-offset-primary-400 flex-shrink-0"
          aria-hidden="true"
        />
        <div
          className={classNames(
            "flex flex-col items-start ml-3",
            collapsed && "hidden"
          )}
          aria-hidden="true"
        >
          <span className="text-sm font-medium text-white">{user?.name}</span>
          <span className="text-xs font-medium text-primary-100">
            {user?.email}
          </span>
        </div>
      </Menu.Button>
      <Menu.Items
        ref={setPopperElement}
        style={styles.popper}
        className=" z-20 w-48 flex flex-col item-start origin-bottom-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-1"
        {...attributes.popper}
      >
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${active || "text-red-500"} ${
                active && "bg-primary-600 text-white"
              } p-2 text-left rounded-md text-sm`}
              onClick={() => handleLogout()}
            >
              Logout
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}

export { UserMenu };
