import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  makeSubmission,
  Submission,
  SubmissionUpdate,
} from "../models/submission.model";
import { Response } from "../models/response.model";
import { FetchError, useApi } from "./useApi";
import { useToasts } from "../context/ToastProvider";

/**
 * Custom hook that returns an react query hook
 * Fetches a submission by the assessment ID and caches it using the
 * default cache settings
 * @see https://react-query.tanstack.com/reference/useQuery
 */
const useClinicalSubmission = (assessmentId: string) => {
  const { fetchWithToken } = useApi();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const submissionKey = ["submission", assessmentId];

  const getSubmission = async (id: string, signal?: AbortSignal) => {
    const res = await fetchWithToken<Response<Submission>>(
      `/assessments/${id}/submissions/clinical`,
      {
        signal,
      }
    );
    return res.data;
  };

  const updateSubmission = async ({
    id,
    submission,
  }: {
    id: string;
    submission: SubmissionUpdate;
  }) => {
    await fetchWithToken(`/submissions/${id}`, {
      method: "PATCH",
      body: submission,
    });
  };

  const clinicalSubmissionQuery = useQuery(
    submissionKey,
    ({ signal }) => getSubmission(assessmentId, signal),
    {
      staleTime: 1000 * 60 * 5, // Stale after 5 mins
      retry: (_, error) =>
        error instanceof FetchError && error.response.status >= 500,
    }
  );

  const updateClinicalSubmissionMutation = useMutation<
    void,
    FetchError,
    { id: string; submission: SubmissionUpdate },
    Submission | undefined
  >(updateSubmission, {
    // When mutate is called:
    onMutate: async (updatedSubmission) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(submissionKey);

      // Snapshot the previous value
      const oldSubmission = queryClient.getQueryData<Submission>(submissionKey);

      // Optimistically update to the new value
      queryClient.setQueryData<Submission | undefined>(submissionKey, (old) =>
        old
          ? makeSubmission({
              ...old,
              characteristics: updatedSubmission.submission.characteristics,
              answers: updatedSubmission.submission.answers,
            })
          : undefined
      );

      // Return a context with the previous tasks
      return oldSubmission;
    },
    // If the mutation fails, use the context we returned above
    onError: (err, updatedSubmission, context) => {
      addToast({
        type: "error",
        content: "Error updating clinical notes. Please try again",
      });
      queryClient.setQueryData(submissionKey, context);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(submissionKey);
    },
    onSuccess: () => {
      addToast({ type: "message", content: "Clinical notes updated" });
    },
  });

  return {
    clinicalSubmissionQuery,
    updateClinicalSubmissionMutation,
  };
};

export { useClinicalSubmission };
