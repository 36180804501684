import React from "react";
import {
  Column,
  useTable,
  usePagination,
  useSortBy,
  useFlexLayout,
  useFilters,
} from "react-table";
import { FaPlus, FaMapSigns } from "react-icons/fa";
import { Assessment } from "../../models/assessment.model";
import { AssessmentsTableFooter } from "../AssessmentsTableFooter";
import { AssessmentsTableCell } from "../AssessmentsTableCell";
import { Spinner } from "../Spinner";
import { AssessmentsTableHeader } from "../AssessmentsTableHeader";
import { mergeStyles } from "../../utilities/mergeStyles";
import { Filter } from "../../models/apiquery.model";
import { Button } from "../Button";
import { AssessmentsTableFilterStatus } from "../AssessmentsTableFilterStatus";
import { AssessmentsInviteModal } from "../AssessmentsInviteModal";
import { useAssessments } from "../../hooks/useAssessments";

type AssessmentsTableProps = {
  data: Assessment[];
  columns: Column[];
  pageCount: number;
  total: number;
  updateTable?: ({
    pageIndex,
    limit,
  }: {
    pageIndex: number;
    limit: number;
  }) => void;
  resetPagination?: (pagination: { pageIndex: number; limit: number }) => void;
  sort?: (params?: {
    sortBy: keyof Assessment;
    sortDirection: "asc" | "desc";
  }) => void;
  filter?: (params?: Filter<Assessment>) => void;
  loading: boolean;
};

function AssessmentsTable({
  columns,
  data,
  pageCount,
  total,
  updateTable,
  resetPagination,
  sort,
  filter,
  loading,
}: AssessmentsTableProps): JSX.Element {
  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const { filters: globalFilters, pagination } = useAssessments();

  // Set the initial filters
  // We want to start in "Received" which is called
  // "on-hold" in Firebase
  const initialFilters = React.useMemo(
    () => [
      globalFilters
        ?.filter((filter) => filter.filterBy === "status")
        .map((filter) => ({ id: filter.filterBy, value: filter.value }))[0] ?? {
        id: "status",
        value: "on-hold",
      },
    ],
    [globalFilters]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: ["status", "created", "id"],
        sortBy: [{ id: "updated", desc: true }],
        filters: initialFilters,
      },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      disableMultiSort: true,
      manualFilters: true,
    },
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    setFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    state: { pageIndex, pageSize, sortBy, filters },
  } = tableInstance;

  React.useEffect(() => {
    gotoPage(pagination.pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (updateTable) {
      updateTable({ pageIndex, limit: pageSize });
    }
  }, [updateTable, pageIndex, pageSize]);

  React.useEffect(() => {
    if (sort) {
      sort(
        sortBy.length > 0
          ? {
              sortBy: sortBy[0].id as keyof Assessment,
              sortDirection: sortBy[0].desc ? "desc" : "asc",
            }
          : undefined
      );
    }
  }, [sort, sortBy]);

  React.useEffect(() => {
    if (filters.length > 0) {
      const mappedFilters = filters.map((filter) => ({
        filterBy: filter.id,
        value: filter.value,
      })) as Filter<Assessment>;
      filter && filter(mappedFilters);
    } else {
      filter && filter(undefined);
    }
  }, [filters, filter, updateTable]);

  React.useEffect(() => {
    if (resetPagination) {
      resetPagination({ pageIndex: 0, limit: pageSize });
    }
  }, [filters, resetPagination, pageSize]);

  return (
    <>
      <div className="flex mb-2 justify-between items-center">
        <AssessmentsTableFilterStatus
          setFilter={setFilter}
          filters={filters}
        ></AssessmentsTableFilterStatus>
        <Button
          as="button"
          color="primary"
          size="sm"
          startIcon={FaPlus}
          onClick={() => setInviteModalOpen(true)}
        >
          New assessment
        </Button>
      </div>
      <div className="flex flex-col flex-1 min-h-0 min-w-0 shadow overflow-hidden border-b border-gray-200">
        <div
          className="flex flex-col min-h-0 flex-1 overflow-auto"
          {...getTableProps()}
        >
          {headerGroups.map((headerGroup) => (
            <div
              className="bg-gray-50 sticky top-0 border-b border-gray-200 flex-grow-0 min-w-max z-10"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <div
                  {...column.getHeaderProps()}
                  className={mergeStyles([
                    index === 0
                      ? "sticky left-0 bg-gray-50 border-gray-100"
                      : undefined,
                    "flex text-xs text-gray-500 justify-start",
                  ])}
                  aria-sort={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "descending"
                        : "ascending"
                      : undefined
                  }
                >
                  <AssessmentsTableHeader
                    canSort={column.canSort}
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc}
                    toggleSortBy={column.toggleSortBy}
                  >
                    {column.render("Header")}
                  </AssessmentsTableHeader>
                  {column.canFilter && column.render("Filter")}
                </div>
              ))}
            </div>
          ))}
          {loading && (
            <div
              role="row"
              className="bg-white divide-y divide-gray-200 flex flex-col flex-1"
            >
              <AssessmentsTableCell
                role="cell"
                className="justify-center items-center"
              >
                <Spinner size="lg" color="primary" />
              </AssessmentsTableCell>
            </div>
          )}
          {!loading && rows.length === 0 && (
            <div
              role="row"
              className="bg-white divide-y divide-gray-200 flex flex-col flex-1"
            >
              <AssessmentsTableCell
                role="cell"
                className="justify-center items-center flex flex-col"
              >
                <div className="flex justify-center items-center flex-1 flex-col">
                  <FaMapSigns className="h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No results
                  </h3>
                </div>
              </AssessmentsTableCell>
            </div>
          )}
          {!loading && rows.length > 0 && (
            <div
              className="bg-white min-w-max divide-y divide-gray-200 border-b border-gray-200"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <div
                    {...row.getRowProps()}
                    className="hover:bg-gray-50 group"
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          className={
                            index === 0
                              ? "sticky left-0 bg-white group-hover:bg-gray-50 border-r border-gray-100"
                              : undefined
                          }
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <AssessmentsTableFooter
          total={total}
          limit={pageSize}
          offset={pageIndex * pageSize}
          onNextPageClick={nextPage}
          onPreviousPageClick={previousPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
        ></AssessmentsTableFooter>
        <AssessmentsInviteModal
          isOpen={inviteModalOpen}
          setIsOpen={setInviteModalOpen}
        ></AssessmentsInviteModal>
      </div>
    </>
  );
}

export { AssessmentsTable };
