import React, { CSSProperties, RefObject } from "react";
import { useOverlay, useModal, DismissButton, FocusScope } from "react-aria";
import { mergeProps } from "@react-aria/utils";
import { classNames } from "../../utilities/mergeStyles";
interface PopoverProps {
  className?: string;
  popoverRef?: React.RefObject<HTMLDivElement>;
  style?: CSSProperties;
  children: React.ReactNode;
  isOpen?: boolean;
  onClose: () => void;
}

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  ({ children, onClose, isOpen, style, className, ...props }, ref) => {
    const { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref as RefObject<HTMLElement>
    );

    // Hide content outside the modal from screen readers.
    const { modalProps } = useModal();

    return (
      <FocusScope restoreFocus autoFocus>
        <div
          {...mergeProps(overlayProps, props, modalProps)}
          ref={ref}
          className={classNames(
            "absolute shadow-lg border border-gray-300 bg-white rounded-md overflow-hidden flex flex-col",
            className
          )}
          style={style}
        >
          {children}
          <DismissButton onDismiss={onClose} />
        </div>
      </FocusScope>
    );
  }
);

export { Popover };
