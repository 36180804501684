import React from "react";

const InputLabel = React.forwardRef<
  HTMLLabelElement,
  React.LabelHTMLAttributes<HTMLLabelElement>
>((props, ref) => {
  return (
    <label
      ref={ref}
      className="block text-sm font-medium text-gray-700"
      {...props}
    />
  );
});

export { InputLabel };
