import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaTimesCircle,
} from "react-icons/fa";

type AlertProps = {
  type?: "warn" | "error" | "success" | "primary";
  text?: string;
  title: string;
};

const alertMap = {
  warn: {
    bgColor: "bg-yellow-50",
    borderColor: "border-yellow-200",
    iconColor: "text-yellow-400",
    titleColor: "text-yellow-800",
    textColor: "text-yellow-700",
    icon: FaExclamationTriangle,
  },
  error: {
    bgColor: "bg-red-50",
    borderColor: "border-red-200",
    iconColor: "text-red-400",
    titleColor: "text-red-800",
    textColor: "text-red-700",
    icon: FaTimesCircle,
  },
  success: {
    bgColor: "bg-green-50",
    borderColor: "border-green-200",
    iconColor: "text-green-400",
    titleColor: "text-green-800",
    textColor: "text-green-700",
    icon: FaCheckCircle,
  },
  primary: {
    bgColor: "bg-primary-50",
    borderColor: "border-primary-200",
    iconColor: "text-primary-400",
    titleColor: "text-primary-800",
    textColor: "text-primary-700",
    icon: FaInfoCircle,
  },
};

const Alert: React.FC<AlertProps> = ({ type = "primary", title, text }) => {
  const alertDetails = alertMap[type];
  return (
    <div
      className={`rounded-md ${alertDetails.bgColor} p-4 flex ${alertDetails.borderColor} border`}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <alertDetails.icon
            className={`h-4 w-4 ${alertDetails.iconColor}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${alertDetails.titleColor}`}>
            {title}
          </h3>
          {text && (
            <div className={`mt-2 text-sm ${alertDetails.textColor}`}>
              <p>{text}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Alert };
